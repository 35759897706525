import React, { useCallback, useEffect, useRef } from 'react';
import { AnimPopup, TEXT_COLOR } from '../../constants';
import Button from '../UI/Buttons/Button';
import { WrappedDialogNotice } from './popupStyle';
import gsap from 'gsap';

interface IProps {
  mainText: string;
  secondaryText?: string;
  btnText: string;
  handleClick: () => void;
}

const DialogNotice: React.FC<IProps> = ({mainText, secondaryText, btnText, handleClick}) => {
  const myRef = useRef(null);
  const hotKeys = useCallback((e) => {
    //console.log(e.key)
    if (e.key === 'Enter') {
      handleClick();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    window.addEventListener('keydown', hotKeys);
    return () => {
      window.removeEventListener('keydown', hotKeys);
    }
  }, [hotKeys])

  useEffect(() => {
    gsap.to(myRef.current, AnimPopup)
  }, [])
  
  return (
    <WrappedDialogNotice ref={myRef} onClick={(e) => e.stopPropagation()}>
      <p className='text main_text'>{mainText}</p>
      {
        secondaryText &&
        <p className='text secondaryText'>{secondaryText}</p>
      }
      <div className="buttons">
        <Button
          background={TEXT_COLOR}
          color={'white'}
          handleClick={handleClick}
        >
          {btnText}
        </Button>
      </div>
    </WrappedDialogNotice>
  );
}

export default DialogNotice;