import React from 'react';
import {CalcInputRange, CalcRangeNumbers, CalcRangeScale} from "../style";
import InputCounter from "../../../components/UI/Input/InputCounter";
import store from '../../../store/rootStore';
import {observer} from "mobx-react-lite";

const UnitsRange = observer(() => {
  const { calculatorKitsCount, setCalculatorKitsCount } = store.app;

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);
    setCalculatorKitsCount(value);
  }

  const onChangeCounter = (val: number) => {
    setCalculatorKitsCount(val);
  }

  return (
    <div>
      <CalcInputRange
        value={calculatorKitsCount}
        onChange={onChange}
        type='range'
        min={1}
        max={100}
        step={1}
      />

      <CalcRangeScale>
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </CalcRangeScale>

      <CalcRangeNumbers>
        <p>0</p>
        <p>10</p>
        <p>20</p>
        <p>30</p>
        <p>40</p>
        <p>50</p>
        <p>60</p>
        <p>70</p>
        <p>80</p>
        <p>90</p>
        <p>100</p>
      </CalcRangeNumbers>

      <InputCounter
        current={calculatorKitsCount}
        changeValue={onChangeCounter}
      />
    </div>
  );
});

export default UnitsRange;
