import React from 'react';
import { WrappedStepPanelContent } from './collectionStyle';
import { observer } from 'mobx-react-lite';
import store from '../../../store/rootStore';
import CardModel from '../../../components/Cards/CardModel';
import {getModelPath} from '../../../api/modelsApi';
import {useTranslation} from "react-i18next";
import {getCatalog, getPrice} from "../../../api/catalogApi";

const StepCollectionPanelContent: React.FC = observer(() => {
  const {currentGarment, stepCollection, setModelId, stepSportId} = store.app;

  const { t } = useTranslation();

  const handleSetModel = (modelId: string) => {
    setModelId(modelId, currentGarment);
  }

  const getPreviewImagePath = (modelId: string) => {
    return `${getModelPath(modelId)}designs/default/preview.png`;
  }

  return (
    <WrappedStepPanelContent>
      {
        stepCollection.modelsList.map((item, index) => {
          let price: number = 0;
          let modelName = '';

          const catalog = getCatalog(stepSportId, item.garment, item.collectionId);
          if (catalog) {
            price = getPrice(stepSportId, item.garment, 1);
            modelName = t(`info.${catalog.infoId}.modelName`);
          }

          return <CardModel
            isSelected={item.id === stepCollection[currentGarment]}
            key={index}
            imgPath={getPreviewImagePath(item.id)}
            header={modelName}
            price={price}
            handleClick={() => handleSetModel(item.id)}
          />
        })
      }
    </WrappedStepPanelContent>
  );
})

export default StepCollectionPanelContent;
