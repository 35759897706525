import { ICollection } from "./types";

export const collections: ICollection[] = [
  {
    id: '0',
    name: 'Renaissance',
  },
  {
    id: '1',
    name: 'Fire',
  },
  {
    id: '2',
    name: 'Fire Woman',
  },
  {
    id: '3',
    name: 'Socks',
  },
]