import React from 'react';
import SwitchButtons, { IButton } from '../../../components/UI/SwitchButtons/SwitchButtons';
import {WrappedStepDecorationPanelHead} from './stepDecorationStyle';
import { EConfigGarment } from '../../../enums';
import { observer } from 'mobx-react-lite';
import store from '../../../store/rootStore';
import Button from '../../../components/UI/Buttons/Button';
import arrowLeft from '../../../assets/UI/arrow_left.svg';
import { useTranslation } from 'react-i18next';

interface IProps {
  decorationProps?: {
    chapters: IButton[];
    handleChapterClick: (option: string) => void;
  }
}

const StepDecorationPanelHead: React.FC<IProps> = observer(({decorationProps}) => {
  const {stepDecoration, currentGarment, setIsColorPicker} = store.app;
  const { t } = useTranslation();

  const handleBack = () => {
    setIsColorPicker(false);
  }

  return (
    <WrappedStepDecorationPanelHead>
      {
        stepDecoration.isColorPicker ?
        <Button
          handleClick={handleBack}
        >
          <img src={arrowLeft} alt='arrow'/>
          <p>{t('ui.back')}</p>
        </Button> :
        <SwitchButtons
          buttons={decorationProps?.chapters}
          currentSelected={currentGarment === EConfigGarment.JERSEY ? stepDecoration.currentJerseyChapter : stepDecoration.currentShortsChapter}
          handleClick={decorationProps?.handleChapterClick}
        />
      }
    </WrappedStepDecorationPanelHead>
  );
})

export default StepDecorationPanelHead;
