import { makeAutoObservable } from "mobx";

class OrderStore {
  firstName: string = '';
  lastName: string = '';
  email: string = '';
  phone: string = '';
  userMessage: string = '';

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setFirstName = (val: string) => {
    this.firstName = val;
  }

  setLastName = (val: string) => {
    this.lastName = val;
  }

  setEmail = (val: string) => {
    this.email = val;
  }

  setPhone = (val: string) => {
    this.phone = val;
  }

  setUserMessage = (val: string) => {
    this.userMessage = val;
  }

  restoreDefaults = () => {
    this.firstName = '';
    this.lastName = '';
    this.email = '';
    this.phone = '';
    this.userMessage = '';
  }
}

export default new OrderStore();
