import React, { useMemo } from 'react';
import InputCounter from '../../../components/UI/Input/InputCounter';
import InputText from '../../../components/UI/Input/InputText';
import MySelect from '../../../components/UI/Select/MySelect';
import { WrappedTableRowTeam } from './orderStyle';
import imgRemoveRed from '../../../assets/UI/remove_red.svg';
import imgRemoveWhite from '../../../assets/UI/remove_white.svg';
import { sizes } from '../../../constants';
import { EConfigGarment } from '../../../enums';
import PillSwitch from "../../../components/UI/SwitchButtons/PillSwitch";
import {TGender} from "../../../db/types";
import { useTranslation } from 'react-i18next';
import {IReturnPrice} from "./TableLayout";
import TotalPriceDisplayRow from "../../../components/TotalPriceDisplay/TotalPriceDisplayRow";

interface IProps {
  lastName: string;
  number: string;
  sizeShirt: string;
  sizeShorts: string;
  sizeSocks: string;
  gender: TGender;
  quantityKits: number;
  sumKit: IReturnPrice;
  handleRowClick: () => void;
  isSelected: boolean;
  handleLastName: (value: string) => void;
  handleNumber: (value: string) => void;
  handleSize: (value: string, garment: EConfigGarment) => void;
  handleQuantityKits: (value: number) => void;
  handleQuantityGarment: (value: number, garment: EConfigGarment) => void;
  handleRemovePlayer: () => void;
  isGeneralQuantity: boolean;
  quantityShirts: string;
  quantityShorts: string;
  quantitySocks: string;
  handleGender: (value: string) => void;
  isWomanOnly: boolean;
  hideSocks: boolean;
  includeJersey?: boolean;
  includeShorts?: boolean;
  includeSocks?: boolean;
}

const TableRowTeam: React.FC<IProps> = ({
  lastName,
  number,
  sizeShirt,
  sizeShorts,
  sizeSocks,
  gender,
  quantityKits,
  sumKit,
  handleRowClick,
  isSelected,
  handleLastName,
  handleNumber,
  handleSize,
  handleQuantityKits,
  handleRemovePlayer,
  isGeneralQuantity,
  quantityShirts,
  quantityShorts,
  quantitySocks,
  handleQuantityGarment,
  handleGender,
  isWomanOnly,
  hideSocks,
  includeSocks,
  includeShorts,
  includeJersey,
}) => {
  const { t } = useTranslation();

  const handleSizeClick = (value: string, garment: EConfigGarment) => {
    handleSize(value, garment);
  }

  const getSizeOptions = useMemo(() => {
    return [
      {
        heading: t('order_formation.size.adult'),
        data: sizes.adult,
      },
      {
        heading: t('order_formation.size.children'),
        data: sizes.children,
      },
    ]
  }, [t])

  return (
    <WrappedTableRowTeam
      onClick={handleRowClick}
      isSelected={isSelected}
      isGeneralQuantity={isGeneralQuantity}
    >
      <div>
        <InputText
          value={lastName}
          handleChange={handleLastName}
          style={{fontWeight: 'normal'}}
          maxLength={30}
        />
      </div>
      <div>
        <InputText
          isNumberOnly
          value={number}
          handleChange={handleNumber}
          maxLength={3}
        />
      </div>
      <div className='garment_cell'>
        <MySelect
          optionsColumns={getSizeOptions}
          currentProp={sizeShirt}
          height='40px'
          minWidth='72px'
          minOptionsWidth='233px'
          dontStopPropagation
          onOptionClick={(value) => handleSizeClick(value, EConfigGarment.JERSEY)}
        />
        {
          !isGeneralQuantity &&
          <InputText
            isNumberOnly
            setToZero
            value={includeJersey ? quantityShirts : '0'}
            width='48px'
            maxLength={2}
            handleChange={(val) => handleQuantityGarment(Number(val), EConfigGarment.JERSEY)}
            isDisabled={!includeJersey}
          />
        }
      </div>
      <div className='garment_cell'>
        <div className='garment_cell--desktop'>
          <MySelect
            optionsColumns={getSizeOptions}
            currentProp={sizeShorts}
            height='40px'
            minWidth='72px'
            minOptionsWidth='233px'
            dontStopPropagation
            onOptionClick={(value) => handleSizeClick(value, EConfigGarment.SHORTS)}
          />
        </div>
        <div className='garment_cell--mobile'>
          <MySelect
            optionsColumns={getSizeOptions}
            currentProp={sizeShorts}
            height='40px'
            minWidth='72px'
            minOptionsWidth='233px'
            dontStopPropagation
            onOptionClick={(value) => handleSizeClick(value, EConfigGarment.SHORTS)}
            isRollsUp={!isGeneralQuantity}
          />
        </div>
        {
          !isGeneralQuantity &&
          <InputText
            isNumberOnly
            setToZero
            value={includeShorts ? quantityShorts : '0'}
            width='48px'
            maxLength={2}
            handleChange={(val) => handleQuantityGarment(Number(val), EConfigGarment.SHORTS)}
            isDisabled={!includeShorts}
          />
        }
      </div>

      <div className='garment_cell'>
        <div className='garment_cell--desktop'>
          <MySelect
            options={sizes.socks}
            currentProp={sizeSocks}
            height='40px'
            minWidth='72px'
            dontStopPropagation
            onOptionClick={(value) => handleSizeClick(value, EConfigGarment.SOCKS)}
            style={{ visibility: hideSocks ? 'hidden' : 'visible'}}
          />
        </div>
        <div className='garment_cell--mobile'>
          <MySelect
            options={sizes.socks}
            currentProp={sizeSocks}
            height='40px'
            minWidth='72px'
            dontStopPropagation
            onOptionClick={(value) => handleSizeClick(value, EConfigGarment.SOCKS)}
            isRollsUp
            style={{ visibility: hideSocks ? 'hidden' : 'visible'}}
          />
        </div>
        {
          !isGeneralQuantity &&
          <InputText
            isNumberOnly
            setToZero
            value={includeSocks ? quantitySocks : '0'}
            width='48px'
            maxLength={2}
            handleChange={(val) => handleQuantityGarment(Number(val), EConfigGarment.SOCKS)}
            style={{ visibility: hideSocks ? 'hidden' : 'visible'}}
            isDisabled={!includeSocks}
          />
        }
      </div>

      {
        isGeneralQuantity &&
          <>
            <PillSwitch
              option1={{value: 'm', label: t('ui.male_letter'), isDisabled: isWomanOnly}}
              option2={{value: 'w', label: t('ui.female_letter')}}
              current={gender}
              handleOptionClick={handleGender}
            />
            <div>
              <InputCounter
                current={quantityKits}
                changeValue={handleQuantityKits}
              />
            </div>
          </>
      }
      <TotalPriceDisplayRow
        total={sumKit.discount || sumKit.total}
        isSelected={isSelected}
        isForTeam
      />
      <div
        onClick={handleRemovePlayer}
        className='remove'
      >
        <img
          src={isSelected ? imgRemoveWhite : imgRemoveRed}
          alt='remove'
        />
      </div>
    </WrappedTableRowTeam>
  );
}

export default TableRowTeam;
