import { observer } from 'mobx-react-lite';
import React from 'react';
import { WrappedOrderFormationPanelHead } from './orderStyle';
import store from '../../../store/rootStore';
import { useTranslation } from 'react-i18next';
import {convertPrice} from "../../../utils";

const OrderFormationPanelHead: React.FC = observer(() => {
  const { t } = useTranslation();
  const {getAllKitsDiscountSum} = store.app;
  const sum = getAllKitsDiscountSum();

  return (
    <WrappedOrderFormationPanelHead isDiscount={false}>
      <p className="total_text">{t('order_formation.total_price')}
        <span className='crossed'>{convertPrice(sum)}</span>
      </p>
    </WrappedOrderFormationPanelHead>
  );
})

export default OrderFormationPanelHead;
