import React from 'react';
import { WrappedTextArea } from './style';

interface IProps {
  value: string;
  handleChange?: (value: string) => void;
  maxLength?: number;
  style?: React.CSSProperties;
  isDisabled?: boolean;
}

const InputTextArea: React.FC<IProps> = ({value = '', handleChange, maxLength, style, isDisabled}) => {

  const onChangeInput = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (handleChange) {
      handleChange(e.target.value);
    }
  }
  
  return (
    <WrappedTextArea
      value={value}
      onChange={onChangeInput}
      maxLength={maxLength}
      disabled={isDisabled}
      style={{...style}}
    />
  );
}

export default InputTextArea;