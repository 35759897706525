import { ISport } from "./types";

export const sports: ISport[] = [
  {
    id: '0',
    name: 'football',
  },
  {
    id: '1',
    name: 'basketball',
  },
  {
    id: '2',
    name: 'handball',
  },
  {
    id: '3',
    name: 'racket',
  },
  {
    id: '4',
    name: 'rugby',
  },
  {
    id: '5',
    name: 'volleyball',
  },
]