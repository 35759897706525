import { IButton } from '../components/UI/SwitchButtons/SwitchButtons';
import {EConfigDesigns, EConfigGarment} from '../enums';
import {getDesignsList} from './modelsApi';

interface IColorSchemeOptions {
  modelId: string;
  design: EConfigDesigns;
  garment: EConfigGarment;
  schemesArr: IButton[];
}

export const getColorScheme = (options: IColorSchemeOptions): IButton[] => {
  const {modelId, design, garment, schemesArr} = options;
  const designList = getDesignsList(modelId);
  const found = designList.find(item => item.name === design);
  let colors: number = 1;
  if (garment !== EConfigGarment.SOCKS) {
    // socks always have 2 layers, but in UI displays only Base layer
    if (found) {
      colors = found.totalColors;
    } else {
      colors = designList[0].totalColors;
    }
  }
  let arr = [...schemesArr];
  if (colors < 3) {
    arr[1].name = 'logo';
    arr[1].value = schemesArr[2].value;
  }
  arr.length = colors;
  return arr;
}