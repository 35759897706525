import React, {useCallback, useEffect, useState} from 'react';
import {WrappedViewport} from './viewportStyle';
import store from '../../store/rootStore';
import {observer} from 'mobx-react-lite';
import {getCollectionName} from '../../api/collectionsApi';
import {getSportName} from '../../api/sportsApi';
import {getAllModelsResources} from '../../api/canvasApi';
import {EConfigDesigns, EConfigGarment} from '../../enums';
import imgSpinner from '../../assets/UI/spinner.gif';
import {debounce} from 'lodash';
import {loadFonts} from '../../api/fontsApi';
import {debounceTime} from '../../constants';
import {IStyles} from '../../store/types';
import {stepColorDefaults} from '../../store/defaults';
import {IColorScheme} from "../../db/types";
import useLocalStorage from "../../hooks/useLocalStorage";

export const ColorSquare: React.FC<{color: string}> = ({color}) => {
  return <div style={{ width: '1em', height: '1em', background: color, display: 'inline-block'}}/>
}

const Viewport: React.FC = observer(() => {
  const {
    isDevelopMode,
    stepSportId,
    stepCollection,
    currentGarment,
    currentStep,
    stepDesignName,
    stepColor,
    setNeedModelRefresh,
    stepDecoration,
    putDataFromStateToResult,
    putScreenshotToResult,
    getUnmountActualValues,
    isModelLoading,
    isNeedModelRefresh,
    setModelLoading,
    touchUI,
    collectData,
    getStyles,
    isSkipNumber,
    includeJersey,
    includeShorts,
    includeSocks,
    setModelScreenShot,
  } = store.app;

  const {
    putCanvasToApp,
    loadMesh,
    loadMaterials,
    refresh,
    setModelsPath,
    setBaseTexturesPath,
    cleanMemory,
    loadDesign,
    loadColors,
    setDesignTexturesPath,
    makeScreenshot,
    init,
    overview,
    setViewFull,
    setViewSingle,
    resetOverview,
    setMaxCameraDistance,
    setDefaultCameraPosition,
    loadSingleDesign,
    loadSingleMesh,
    loadSingleColor,
    getActualModelParams,
    prepareCameraForScreenshot,
    loadSingleMaterial,
    resizeViewport,
    loadDecoration,
    getReportScreenshot,
  } = store.canvas;

  const { onWrite } = useLocalStorage();

  const [renderHeight, setRenderHeight] = useState<number>(0);
  const [isSingleCloth, setIsSingleCloth] = useState<boolean>(true);

  // refresh viewport on resize

  const resizeEffect = useCallback(() => {
    const width = document.querySelector('.viewport')?.clientWidth;
    const height = document.documentElement.clientHeight - 138;
    setRenderHeight(height);
    resizeViewport(width, height);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    window.onresize = resizeEffect;
    return () => {
      window.onresize = null;
    }
  }, [resizeEffect])

  // init, if model need refresh

  useEffect(() => {
    if (isNeedModelRefresh) {
      const width = document.querySelector('.viewport')?.clientWidth;
      const height = document.documentElement.clientHeight - 138;
      setRenderHeight(height);
      init(width, height);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [init])

  // overview

  useEffect(() => {
    if (currentStep > 0 && currentStep < 5) {
      if (overview) {
        setViewFull({
          jersey: includeJersey,
          shorts: includeShorts,
          socks: includeSocks,
        });
      } else {
        setViewSingle(currentGarment);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [overview])

  useEffect(() => {
    const includeGarments: EConfigGarment[] = [];
    includeJersey && includeGarments.push(EConfigGarment.JERSEY);
    includeShorts && includeGarments.push(EConfigGarment.SHORTS);
    includeSocks && includeGarments.push(EConfigGarment.SOCKS);
    setIsSingleCloth(includeGarments.length < 2);
  }, [includeJersey, includeShorts, includeSocks]);

  useEffect(() => {
    /*
    Логика возможности менять overview в зависимости от кол-ва выбранной одежды

    Событие: Изменилось кол-во одежды.

    Результат а): Стало 1.

    Если overview === true
    - сбросить положение камеры (увеличение)
    - уменьшить макс.дистанцию
    - overview = false

    Если overview === false
    - уменьшить макс.дистанцию

    Результат б): Стало больше 1.

    Здесь overview всегда false
    - восстановить макс дистанцию по дефолту
     */

    if (isSingleCloth) {
      if (overview) {
        resetOverview();
      } else {
        setMaxCameraDistance(149);
      }
    } else {
      setMaxCameraDistance(170);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSingleCloth]);

  useEffect(() => {
    if (currentStep > 0 && currentStep < 5) {
      setViewSingle(currentGarment);
      setDefaultCameraPosition();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentGarment])

  const confirmCollection = useCallback((isNeedModelRefresh: boolean, isDevelopMode: boolean) => {
    setModelLoading(true);
    const res = getAllModelsResources(stepCollection.jersey, stepCollection.shorts, stepCollection.socks);
    if (typeof res === 'string') {
      //console.log('model not found');
      setModelLoading(false);
    } else {
      setModelsPath(res);
      setDesignTexturesPath(res, EConfigDesigns.DEFAULT);
      setBaseTexturesPath(res);
      loadDesign(isDevelopMode)
      .then(() => {
        return loadMaterials();
      })
      .then(() => {
        return loadMesh();
      })
      .then(() => {
        loadColors(stepColorDefaults, EConfigDesigns.DEFAULT);
        if (isNeedModelRefresh) {
          refresh();
          putCanvasToApp();
          setNeedModelRefresh(false);
        }
      })
      .catch((e) => {
        throw new Error(e.message);
      }).finally(() => {
        setModelLoading(false);
        setNeedModelRefresh(false);
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const startDebounceCollection = useCallback(debounce(confirmCollection, debounceTime), []);

  useEffect(() => {
    if (currentStep !== 1) {
      return;
    }
    // if user went to step 1
    confirmCollection(isNeedModelRefresh, isDevelopMode);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep])

  useEffect(() => {
    if (currentStep !== 1 || isNeedModelRefresh) {
      return;
    }
    // if user changed model
    //console.log('DEBOUNCE');
    startDebounceCollection(isNeedModelRefresh, isDevelopMode);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    stepCollection.jersey,
    stepCollection.shorts,
    stepCollection.socks,
  ])

  // Step design effect

  const confirmDesign = useCallback((stepDesignName: EConfigDesigns, isDevelopMode: boolean, isNeedModelRefresh: boolean) => {
    setModelLoading(true);
    const res = getAllModelsResources(stepCollection.jersey, stepCollection.shorts, stepCollection.socks);
    if (typeof res === 'string') {
      //console.log('model not found');
      setModelLoading(false);
    } else {
      setModelsPath(res);
      setDesignTexturesPath(res, stepDesignName);
      setBaseTexturesPath(res);
      loadDesign(isDevelopMode)
      .then(() => {
        return loadMaterials();
      })
      .then(() => {
        return loadMesh();
      })
      .then(() => {
        loadColors(stepColor, stepDesignName);
        if (isNeedModelRefresh) {
          refresh();
          putCanvasToApp();
          setNeedModelRefresh(false);
        }
      })
      .catch((e) => {
        throw new Error(e.message);
      }).finally(() => {
        setModelLoading(false);
        setNeedModelRefresh(false);
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const startDebounceDesign = useCallback(debounce(confirmDesign, debounceTime), []);

  useEffect(() => {
    if (currentStep !== 2) {
      return;
    }
    // if user went to step 2
    if (!stepDesignName) {
      //console.log('No effect due to null design')
      return;
    }
    confirmDesign(stepDesignName, isDevelopMode, isNeedModelRefresh);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep])

  useEffect(() => {
    if (currentStep !== 2 || isNeedModelRefresh) {
      return;
    }
    // if user changed design of jersey
    if (!stepDesignName) {
      //console.log('No effect due to null design')
      return;
    }
    //console.log('DEBOUNCE');
    startDebounceDesign(stepDesignName, isDevelopMode, isNeedModelRefresh);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isDevelopMode,
    stepCollection,
    stepColor,
    stepDesignName,
  ])

  // Step color effect
  // !!! эффект перенесен в файл StepColorPanelContent

  // useEffect(() => {
  //   if (currentStep !== 3) {
  //     return;
  //   }
  //   // if user changed color of any scheme
  //   loadColors(stepColor, stepDesignName);
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [
  //   loadColors,
  //   stepColor,
  //   stepColor.jersey.base,
  //   stepColor.jersey.details,
  //   stepColor.jersey.logo,
  //   stepColor.jersey.design1,
  //   stepColor.jersey.design2,
  //   stepColor.jersey.design3,
  //   stepColor.shorts.base,
  //   stepColor.shorts.details,
  //   stepColor.shorts.logo,
  //   stepColor.shorts.design1,
  //   stepColor.shorts.design2,
  //   stepColor.shorts.design3,
  //   stepColor.socks.base,
  //   stepColor.socks.details,
  //   stepColor.socks.logo,
  //   stepColor.socks.design1,
  //   stepColor.socks.design2,
  //   stepColor.socks.design3,
  //   stepDesignName,
  // ])

  const confirmColor = useCallback((stepDesignName: EConfigDesigns, isDevelopMode: boolean, isNeedModelRefresh: boolean) => {
    setModelLoading(true);
    const res = getAllModelsResources(stepCollection.jersey, stepCollection.shorts, stepCollection.socks);
    if (typeof res === 'string') {
      //console.log('model not found');
      setModelLoading(false);
    } else {
      setModelsPath(res);
      setBaseTexturesPath(res);
      setDesignTexturesPath(res, stepDesignName);
      loadDesign(isDevelopMode)
      .then(() => {
        return loadMaterials();
      })
      .then(() => {
        return loadMesh();
      })
      .then(() => {
        loadColors(stepColor, stepDesignName);
        if (isNeedModelRefresh) {
          refresh();
          putCanvasToApp();
          setNeedModelRefresh(false);
        }
      })
      .catch((e) => {
        throw new Error(e.message);
      }).finally(() => {
        setModelLoading(false);
        setNeedModelRefresh(false);
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // if user went to step 3: FULL REFRESH
    if (currentStep !== 3) {
      return;
    }
    confirmColor(stepDesignName, isDevelopMode, isNeedModelRefresh);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isDevelopMode,
    stepCollection,
    stepColor,
    stepDesignName,
    currentStep
  ])

  // Step decoration effect

  const confirmDecoration = useCallback((options: {
    isNeedChangeModel: boolean,
    isDevelopMode: boolean,
    isNeedModelRefresh: boolean,
    stepDesignName: EConfigDesigns
  }) => {
    setModelLoading(true);
    const res = getAllModelsResources(stepCollection.jersey, stepCollection.shorts, stepCollection.socks);
    const styles: IStyles = getStyles();
    const skipNumber = isSkipNumber();

    if (options.isNeedChangeModel) {
      if (typeof res === 'string') {
        //console.log('model not found');
        setModelLoading(false);
        return;
      } else {
        setModelsPath(res);
        setBaseTexturesPath(res);
        setDesignTexturesPath(res, options.stepDesignName);
      }
    }

    loadFonts()
    .catch(() => {
      //console.log('Failed to load font');
    })
    .finally(() => {
      loadDesign(options.isDevelopMode, styles, skipNumber)
      .then(() => {
        return loadMaterials();
      })
      .then(() => {
        return loadMesh(!options.isNeedChangeModel);
      })
      .then(() => {
        loadColors(stepColor, options.stepDesignName);
        if (options.isNeedModelRefresh) {
          refresh();
          putCanvasToApp();
          setNeedModelRefresh(false);
        }
      })
      .catch((e) => {
        throw Error(e.message);
      })
      .finally(() => {
        setModelLoading(false);
      })
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const startDebounceDecoration = useCallback(debounce(confirmDecoration, debounceTime), []);

  useEffect(() => {
    // if user went to step 4
    if (currentStep !== 4) {
      return;
    }
    confirmDecoration({
      isNeedChangeModel: true,
      isDevelopMode,
      isNeedModelRefresh,
      stepDesignName,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDevelopMode, currentStep])

  useEffect(() => {
    // if user changed anything else
    if (currentStep !== 4 || isNeedModelRefresh) {
      return;
    }
    // console.log('DEBOUNCE');
    startDebounceDecoration({
      isNeedChangeModel: false,
      isDevelopMode,
      isNeedModelRefresh,
      stepDesignName,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [touchUI])

  // Viewport unmount effect

  useEffect(() => {
    return () => {
      const reportScreenshots = getReportScreenshot();
      setModelScreenShot(reportScreenshots);
      //console.log('UNMOUNT VIEWPORT');
      setModelLoading(true);
      putDataFromStateToResult();
      const actual = getUnmountActualValues();
      if (actual.currentStep !== 5) {
        finish();
        return;
      }
      const myKitId = actual.currentKitId;
      const styles = getStyles();
      const skipNumber = isSkipNumber();
      const res = getAllModelsResources(actual.jerseyModelId, actual.shortsModelId, actual.socksModelId);
      if (typeof res === 'string') {
        //console.log('model not found');
        setModelLoading(false);
        return;
      } else {
        setModelsPath(res);
        setBaseTexturesPath(res);
        setDesignTexturesPath(res, actual.stepDesignName);
      }
      const modelParams = getActualModelParams();

      loadFonts()
      .catch(() => {
        //console.log('Failed to load font');
      })
      .finally(() => {
        getScreenshotURL(EConfigGarment.JERSEY, !actual.includeJersey)
        .then((result) => {
          if (result) {
            putScreenshotToResult(myKitId, EConfigGarment.JERSEY, result);
          }
          return getScreenshotURL(EConfigGarment.SHORTS, !actual.includeShorts);
        })
        .then((result) => {
          if (result) {
            putScreenshotToResult(myKitId, EConfigGarment.SHORTS, result);
          }
          return getScreenshotURL(EConfigGarment.SOCKS, !actual.includeSocks);
        })
        .then((result) => {
          if (result) {
            putScreenshotToResult(myKitId, EConfigGarment.SOCKS, result);
          }
        })
        .catch((e) => {
          throw new Error(e.message);
        }).finally(() => {
          finish();
        });
      })

      function getScreenshotURL(garment: EConfigGarment, skip: boolean): Promise<string | null> {
        return new Promise<string | null>(async(resolve) => {
          if (skip) {
            resolve(null);
          }
          prepareCameraForScreenshot(garment);
          const myTextures = modelParams.textures[garment];

          //console.log('loadSingleDesign');
          return loadSingleDesign(garment, isDevelopMode, true)
          .then(async() => {
            if (garment !== EConfigGarment.SOCKS) {
              loadDecoration(garment, styles, skipNumber);
            }
            //console.log('loadSingleMaterial');
            return loadSingleMaterial({
              garment,
              base: myTextures.base,
              normal: myTextures.normal,
              roughness: myTextures.roughness,
              ao: myTextures.ao,
              design: modelParams.design[garment],
            })
            .then(() => {
              //console.log('loadSingleMesh');
              return loadSingleMesh(modelParams[garment], garment);
            })
            .then(() => {
              //console.log('loadSingleColor');
              let scheme: unknown;
              if (garment === EConfigGarment.JERSEY) {
                const index = stepColor.jersey.findIndex((k) => k.design === actual.stepDesignName);
                scheme = stepColor.jersey[index].scheme;
              } else {
                scheme = stepColor[garment];
              }

              loadSingleColor(garment, scheme as IColorScheme);
              const result = makeScreenshot();
              //console.log('SCREENSHOT for ' + garment);
              resolve(result);
            })
          })
        })
      }

      function finish() {
        cleanMemory();
        setNeedModelRefresh(true);
        setModelLoading(false);
        onWrite('configurator', collectData());
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // tools

  // const getColorScheme = (scheme: EConfigColorSchemes): ColorHex => {
  //   const obj = stepColor[currentGarment];
  //   return obj[scheme];
  // }

  const handleDesignTexturesPath = () => {
    const res = getAllModelsResources(stepCollection.jersey, stepCollection.shorts, stepCollection.socks);
    if (typeof res === 'string') {
      //console.log('model not found');
    } else {
      setDesignTexturesPath(res, stepDesignName);
    }
  }

  const displayTexture = () => {
    document.querySelectorAll<HTMLElement>('.test_canvas').forEach((item) => {
      if (item.style.display === 'none') {
        item.style.display = 'block';
      } else {
        item.style.display = 'none';
      }
    });
  }

  return (
    <WrappedViewport
      isDevelopMode={isDevelopMode}
      className='viewport'
      isModelLoading={isModelLoading}
      renderHeight={renderHeight}
    >
      {
        isDevelopMode &&
        <>
          <div className="data">
            <div>
              <p>MODEL CONFIG:</p><br/>
              <p>Sport: <span>{getSportName(stepSportId || '0')}</span></p>
              <p>Collection: <span>{getCollectionName(stepCollection.collectionId)}</span></p>
              <p>Garment: <span>{currentGarment}</span></p>
              <p>Model id: <span>{stepCollection[currentGarment]}</span></p>
              <p>Model gender: <span>{stepCollection.gender[currentGarment]}</span></p>
              <p>Design: <span>{stepDesignName}</span></p><br/>
              <p><strong>Color scheme</strong></p>
              {
                // Object.values(EConfigColorSchemes).map((item, index) =>
                //   <div className='colors' key={index}>
                //     <p>{item}:</p>
                //     {
                //       <ColorSquare color={getColorScheme(item)}/>
                //     }
                //   </div>)
              }
              <div className='small_text'>
                <br/><p><span>Jersey Number:</span></p>
                <p>position: {stepDecoration.jersey.number.position}</p>
                <p>text: {stepDecoration.jersey.number.text}</p>
                <p>fontFamily: {stepDecoration.jersey.number.fontFamily}</p>
                <p>fontSize: {stepDecoration.jersey.number.fontSize}</p>
                <p>textColor: {<ColorSquare color={stepDecoration.jersey.number.textColor}/>}</p>
                <p>strokeThickness: {stepDecoration.jersey.number.strokeThickness}</p>
                <p>strokeColor: {<ColorSquare color={stepDecoration.jersey.number.strokeColor}/>}</p>
                <br/>
                <p><span>Jersey Last name:</span></p>
                <p>position: {stepDecoration.jersey.lastName.position}</p>
                <p>text: {stepDecoration.jersey.lastName.text}</p>
                <p>fontFamily: {stepDecoration.jersey.lastName.fontFamily}</p>
                <p>fontSize: {stepDecoration.jersey.lastName.fontSize}</p>
                <p>textColor: {<ColorSquare color={stepDecoration.jersey.lastName.textColor}/>}</p>
                <p>strokeThickness: {stepDecoration.jersey.lastName.strokeThickness}</p>
                <p>strokeColor: {<ColorSquare color={stepDecoration.jersey.lastName.strokeColor}/>}</p>
              </div>
            </div>
            <div className='small_text'>
              <p><span>Jersey Team logo:</span></p>
              <p>position: {stepDecoration.jersey.teamLogo.position}</p>
              <p>file: {stepDecoration.jersey.teamLogo.imgFileName}</p>
              <br/>
              <p><span>Jersey Sponsor 1:</span></p>
              <p>position: {stepDecoration.jersey.sponsorLogo1.position}</p>
              <p>file: {stepDecoration.jersey.sponsorLogo1.imgFileName}</p>
              <br/>
              <p><span>Jersey Sponsor 2:</span></p>
              <p>position: {stepDecoration.jersey.sponsorLogo2.position}</p>
              <p>file: {stepDecoration.jersey.sponsorLogo2.imgFileName}</p>
              <br/>
              <p><span>Jersey Sponsor 3:</span></p>
              <p>position: {stepDecoration.jersey.sponsorLogo3.position}</p>
              <p>file: {stepDecoration.jersey.sponsorLogo3.imgFileName}</p>
              <br/>
              <p><span>Shorts Number:</span></p>
              <p>position: {stepDecoration.shorts.number.position}</p>
              <p>fontSize: {stepDecoration.shorts.number.fontSize}</p>
              <p>textColor: {<ColorSquare color={stepDecoration.shorts.number.textColor}/>}</p>
              <p>strokeThickness: {stepDecoration.shorts.number.strokeThickness}</p>
              <p>strokeColor: {<ColorSquare color={stepDecoration.shorts.number.strokeColor}/>}</p>
              <br/>
              <p><span>Shorts Team logo:</span></p>
              <p>position: {stepDecoration.shorts.teamLogo.position}</p>
              <br/>
              <p><span>Shorts Sponsor 1:</span></p>
              <p>position: {stepDecoration.shorts.sponsorLogo1.position}</p>
              <p>file: {stepDecoration.shorts.sponsorLogo1.imgFileName}</p>
              <br/>
              <p><span>Shorts Sponsor 2:</span></p>
              <p>position: {stepDecoration.shorts.sponsorLogo2.position}</p>
              <p>file: {stepDecoration.shorts.sponsorLogo2.imgFileName}</p>
              <br/>
              <p><span>Shorts Sponsor 3:</span></p>
              <p>position: {stepDecoration.shorts.sponsorLogo3.position}</p>
              <p>file: {stepDecoration.shorts.sponsorLogo3.imgFileName}</p>
            </div>
          </div>

          <div className='dev_buttons'>
            <button onClick={() => putCanvasToApp()}>putCanvasToApp</button>
            <button onClick={() => loadMesh()}>loadMesh</button>
            <button onClick={() => loadMaterials()}>loadMaterials</button>
            <button onClick={() => loadDesign(isDevelopMode)}>loadDesign</button>
            <button onClick={() => loadColors(stepColor, stepDesignName)}>loadColors</button>
            <button onClick={() => refresh()}>refresh</button>
          </div>
          <div className='dev_buttons'>
            <button onClick={() => cleanMemory()}>cleanMemory</button>
            {/* <button onClick={() => setModelsPath()}>setModelPath</button> */}
            {/* <button onClick={() => setBaseTexturesPath()}>setBaseTexturesPath</button> */}
            <button onClick={() => handleDesignTexturesPath()}>setDesignTexturesPath</button>
            {/*<button className='btn_overview' onClick={setViewFull()}>setViewFull</button>*/}
            <button className='btn_overview' onClick={() => setViewSingle(EConfigGarment.JERSEY)}>setViewSingle</button>
            <button className='btn_texture' onClick={displayTexture}>displayTexture</button>
          </div>
        </>
      }

      <div id='render'/>
      <img
        src={imgSpinner}
        alt='3d view is loading, please wait...'
        className='spinner'
      />
    </WrappedViewport>
  );
})

export default Viewport;
