import React from 'react';
import MenuSelector from '../../../components/UI/MenuSelector/MenuSelector';
import { WrappedHeader } from './headerStyle';
import logo from '../../../assets/icons/logo.svg';
import Languages from './Languages';
import { noltSiteLink } from '../../../constants';
import store from '../../../store/rootStore';
import { observer } from 'mobx-react-lite';
import Calculator from '../../Caclulator';

const Header: React.FC = observer(() => {
  const {currentStep} = store.app;

  return (
    <WrappedHeader isStepOrder={currentStep === 5}>
      <div className="container">
        <a className="logo logo__desktop" href={noltSiteLink}>
          <img
            src={logo}
            alt='logo'
          />
        </a>

        <a className='logo logo__mobile' href={noltSiteLink}>
          <img
            src={logo}
            alt='NOLT'
          />
        </a>
        <Languages/>
        <div className='burger'>
          <Calculator />
          <MenuSelector />
        </div>
      </div>
    </WrappedHeader>
  );
})

export default Header;
