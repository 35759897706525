import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import {WrappedLanguages} from './headerStyle';
import store from '../../../store/rootStore';
import { useTranslation } from 'react-i18next';
import { TLanguages } from '../../../store/types';
import useLocalStorage from "../../../hooks/useLocalStorage";

const Languages: React.FC = observer(() => {
  const { language, setLanguage } = store.app;
  const { i18n } = useTranslation();
  const { onRead, onWrite } = useLocalStorage();

  useEffect(() => {
    const save = onRead('i18nextLng');
    if (save === 'en' || save === 'fr') {
      setLanguage(save as TLanguages);
    }
  }, [onRead, setLanguage])

  const handleClickEn = () => {
    i18n.changeLanguage('en');
    setLanguage('en');
    onWrite('i18nextLng', 'en');
  }

  const handleClickFr = () => {
    i18n.changeLanguage('fr');
    setLanguage('fr');
    onWrite('i18nextLng', 'fr');
  }

  return (
    <WrappedLanguages language={language}>
      <button
        className="en"
        onClick={handleClickEn}
      >EN</button>
      <button
        className="fr"
        onClick={handleClickFr}
      >FR</button>
    </WrappedLanguages>
  );
})

export default Languages;
