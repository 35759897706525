import React, {useEffect, useState} from 'react';
import {EConfigGarment} from "../../enums";
import store from "../../store/rootStore";
import {TextInfiniteMesh, TextNotFound} from "../../constants";
import Info from "../../components/Popups/Info";
import {useTranslation} from "react-i18next";
import {getCatalog} from "../../api/catalogApi";
import {observer} from "mobx-react-lite";

const parseBoldHTML = (str: string, boldText: string): (null | JSX.Element)[] => {
  const arr = str.split(boldText);
  const result = [];
  for (let i = 0; i < arr.length; i++) {
    result.push(arr[i]);
    if (i < arr.length - 1) {
      result.push(<b>{boldText}</b>);
    }
  }
  return result.map((s, i) => s ? <span key={i}>{s}</span> : null);
};

interface InfoState {
  id: string | null;
  modelName: string | null;
}

const InfoPanel = observer(() => {
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [info, setInfo] = useState<InfoState>({
    id: null,
    modelName: null,
  });

  const { t } = useTranslation();

  const {
    currentStep,
    currentGarment,
    stepCollection,
    stepDesignName,
    stepSportId,
  } = store.app;

  useEffect(() => {
    if (currentStep > 0 && currentStep < 5) {
      const catalog = getCatalog(stepSportId, currentGarment, stepCollection.collectionId);
      if (catalog) {
        const modelName = t(`info.${catalog.infoId}.modelName`);
        setInfo({
          id: catalog.infoId,
          modelName,
        });
      }
    }
  }, [
    currentGarment,
    currentStep,
    stepCollection,
    stepCollection.collectionId,
    stepCollection.jersey,
    stepCollection.shorts,
    stepCollection.socks,
    stepDesignName,
    stepSportId,
    t,
  ]);

  if (currentStep === 0 || currentStep > 4 || currentGarment === EConfigGarment.SOCKS) {
    return null;
  }

  const renderText = (id: string | null): JSX.Element[] => {
    if (!id) {
      return [];
    }
    const res: string[] = t(`info.${id}.text`, {returnObjects: true, tm: TextInfiniteMesh});

    return res?.map((item, index) => {
      if (item.indexOf(TextInfiniteMesh) !== -1) {
        const html = parseBoldHTML(item, TextInfiniteMesh);
        return <p key={index}>{html}</p>
      }
      return <p key={index}>{item}</p>
    }) || [];
  }

  return (
    <Info
      modelName={info.modelName || TextNotFound}
      heading={info.id ? t(`info.${info.id}.head`) : TextNotFound}
      text={renderText(info.id)}
      isOpen={isOpen}
      handleOpen={setIsOpen}
    />
  );
});

export default InfoPanel;
