import { TextNotFound } from "../constants";
import { sports } from "../db/sports";

export const getSportId = (name: string): string => {
  return sports.find(item => item.name === name)?.id || TextNotFound;
}

export const getSportName = (id: string): string => {
  return sports.find(item => item.id === id)?.name || TextNotFound;
}
