import React from 'react';
import { WrappedInputRange } from './style';

interface IProps {
  value?: number;
  handleChange: (value: string) => void;
  width?: string;
  min?: number;
  max?: number;
  step?: number;
  hideValue?: boolean;
}

const InputRange: React.FC<IProps> = ({
  value = 0,
  handleChange,
  width,
  min= 0,
  max = 10,
  step = 1,
  hideValue,
}) => {

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(e.target.value);
  }
  
  return (
    <WrappedInputRange width={width}>
      <input
        type='range'
        min={min}
        max={max}
        step={step}
        value={value}
        onChange={onChangeInput}
      />
      {
        !hideValue && <p>{value}</p>
      }
    </WrappedInputRange>
    
  );
}

export default InputRange;