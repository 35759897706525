export interface ICalculateYSizeOptions {
  size: number;
  minX: number;
  maxX: number;
  minY: number;
  maxY: number;
}

const calculateYSize = (options: ICalculateYSizeOptions): number => {
  const { size, maxY, maxX, minY, minX } = options;
  /*
  current size: 7
  x - 6-12
  y - 5-7
   */
  const range = maxX - minX; // 6
  const percent = (size - minX) / range; // 0.33
  const rangeY = maxY - minY; // 1
  const add = Number((rangeY * percent).toFixed(1)); // 0.3
  return minY + add;
}

export default calculateYSize;
