import axios from 'axios';
import { TFunction } from 'react-i18next';

import formOrderData from './formOrderData';
import { IOrderFormation, IOrderInfo } from '../../../store/types';

const orderService = {
  sendOrderToEmail: async (userData: IOrderInfo, orderData: IOrderFormation[], t: TFunction<"translation", undefined>) => {
    const response = await axios.post(
      'mail/sendOrderDetails',
      formOrderData(userData, orderData, t),
      { headers: { 'Content-Type': 'multipart/form-data' } }
    );

    return response;
  },
};

export default orderService;