import React from 'react';
import { WrappedPaginator } from './menuPanelStyle';
import store from '../../store/rootStore';
import arrowLeft from '../../assets/UI/arrow_left.svg';
import arrowRight from '../../assets/UI/arrow_right.svg';

interface IProps {
  name: string;
  page: number;
  isModelLoading: boolean;
  totalPages: number;
}

const Paginator: React.FC<IProps> = ({name, page, isModelLoading, totalPages}) => {
  const {
    setPreviousStep,
    setNextStep,
    currentStep,
    result,
    currentKitId,
    putDataFromResultToState
  } = store.app;

  const handlePrevious = () => {
    if (isModelLoading) {
      return;
    }
    if (currentStep === 5) {
      const playerId = result.find(item => item.id === currentKitId)?.selectedPlayerId || '0';
      putDataFromResultToState(currentKitId, playerId);
    }
    setPreviousStep();
  }

  const handleNext = () => {
    if (isModelLoading) return;
    setNextStep(totalPages);
  }

  return (
    <WrappedPaginator
      isPreviousVisible={page > 1}
      isNextVisible={page > 1 && page < totalPages}
      isActive={!isModelLoading}
    >
      <div className="btn_wrap previous"
        onClick={handlePrevious}
      >
        <img
          src={arrowLeft}
          alt='prev'
        />
      </div>
      <p>{name} {page}/6</p>
      <div className="btn_wrap next"
        onClick={handleNext}
      >
        <img
          src={arrowRight}
          alt='next'
        />
      </div>
    </WrappedPaginator>
  );
}

export default Paginator;