import React, { useEffect, useState } from 'react';
import Button from '../Buttons/Button';
import { WrappedSwitchButtons } from './switchButtonsStyle';

export interface IButton {
  name: string; // lastName
  value: string; // Last name
}

interface IProps {
  buttons?: IButton[];
  currentSelected: string | null;
  handleClick?: (item: string) => void;
}

const SwitchButtons: React.FC<IProps> = ({ buttons, handleClick, currentSelected }) => {
  const [current, setCurrent] = useState<number>(0);

  useEffect(() => {
    if (buttons) {
      const index = buttons.findIndex(item => item.name === currentSelected);
      if (index !== -1) {
        setCurrent(index);
      }
    }
  }, [buttons, currentSelected])
  
  const buttonClick = (index: number, name: string) => {
    setCurrent(index);
    handleClick && buttons && handleClick(name);
  }

  return (
    <WrappedSwitchButtons>
      { 
        buttons?.map((item, index) => <Button 
            key={index}
            isPressed={index === current}
            handleClick={() => buttonClick(index, item.name)}
          >
            {item.value}
          </Button>)
      }
    </WrappedSwitchButtons>
  );
}

export default SwitchButtons;