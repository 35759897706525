import styled, {css} from 'styled-components';
import {BG_GREEN, BTN_BORDER_COLOR, BTN_TEXT_COLOR_PRESSED, TEXT_COLOR} from '../../../constants';

export const WrappedSwitchButtons = styled.div`
  display: flex;
  gap: 1em;
  align-items: center;
  
  @media all and (max-width: 768px) {
    gap: 8px;
  }
`

interface ISwitchProps {
  isOption1: boolean;
}

export const WrappedSwitch = styled.div<ISwitchProps>`
  display: flex;
  gap: 16px;
  padding: 6px 16px;
  background: #FFFFFF;
  border-radius: 16px;
  font-weight: 700;
  height: 29px;

  .green_text {
    color: ${BTN_TEXT_COLOR_PRESSED};
  }

  .switch_desktop {
    display: block;
  }

  .switch_mobile {
    display: none;
  }

  .cont {
    display: flex;
    gap: 10px;

    img {
      width: 22px;
    }

    .option1 {
      color: ${props => props.isOption1 ? TEXT_COLOR : BTN_BORDER_COLOR};
    }

    .option2 {
      color: ${props => props.isOption1 ? BTN_BORDER_COLOR : TEXT_COLOR};
    }
  }

  @media all and (max-width: 1920px) {
    p {
      font-size: 13px;
    }
  }

  @media all and (max-width: 768px) {
    justify-content: space-between;

    .switch_desktop {
      display: none;
    }

    .switch_mobile {
      display: block;
    }
  }
`

interface IPillSwitchProps {
  isOption1: boolean;
  isOption1Disabled: boolean | undefined;
  isOption2Disabled: boolean | undefined;
}

const selectedHalf = css`
  background: ${BG_GREEN};
  border: 1px solid white;
  
  p {
    color: white;
  }
`

const disabledHalf = css`
  background: ${BTN_BORDER_COLOR};
  border: 1px solid white;
  
  p {
    color: #c3c3c3;
  }
`

export const WrappedPillSwitch = styled.div<IPillSwitchProps>`
  display: flex;
  
  .half {
    border: 1px solid ${BTN_BORDER_COLOR};
    background: white;
    height: 40px;
    width: 50px;
    transition: background .2s ease-in-out;
    
    p {
      font-weight: 700;
      font-size: 14px;
      color: ${BTN_BORDER_COLOR};
      line-height: 40px;
      text-align: center;
    }
  }
  
  .halfLeft {
    border-radius: 16px 0 0 16px;
    cursor: ${props => props.isOption1Disabled ? 'default' : 'pointer'};
    ${props => props.isOption1Disabled ? disabledHalf : (props.isOption1 && selectedHalf)}
  }
  
  .halfRight {
    border-radius: 0 16px 16px 0;
    cursor: ${props => props.isOption2Disabled ? 'default' : 'pointer'};
    ${props => props.isOption2Disabled ? disabledHalf : (!props.isOption1 && selectedHalf)}
  }
`
