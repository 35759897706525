import { IButton } from "../components/UI/SwitchButtons/SwitchButtons";
import { TextNotFound } from "../constants";
import { collections } from "../db/collections";
import { models } from "../db/models";
import { EConfigGarment } from "../enums";

export const getCollectionArray = (sportId: string, garment: EConfigGarment): IButton[] => {
  const result: IButton[] = [];
  for (let item of models) {
    const name = getCollectionName(item.collectionId);
    if (
      item.sportIds.includes(sportId) && 
      item.garment === garment && 
      !result.some(item => item.value === name)
    ) {
      result.push({
        name: item.collectionId || '-1',
        value: name,
      });
    }
  }

  if (result.length > 1) {
    // Если больше 1 коллекции, то скрывать Renaissance
    return result.filter((item) => item.value !== 'Renaissance');
  }
  return result.sort((a, b) => a.value.localeCompare(b.value));
}

export const getCollectionName = (id: string | null): string => {
  return collections.find(item => item.id === id)?.name || TextNotFound;
}

export const getCollectionIdByModelId = (modelId: string): string | null => {
  const model = models.find(item => item.id === modelId);
  if (!model) {
    return null;
  }
  return model.collectionId;
}