import { fonts } from "../constants";
import FontFaceObserver from 'fontfaceobserver';

export const loadFonts = (): Promise<void[]> => {
  const observers: Promise<void>[] = [];
  fonts.forEach((family) => {
    const obs = new FontFaceObserver(family.value);
    observers.push(obs.load());
  });

  return Promise.all(observers);
}