import React, { useCallback, useEffect, useRef } from 'react';
import { AnimPopupError, BTN_TEXT_COLOR_PRESSED } from '../../constants';
import Button from '../UI/Buttons/Button';
import { WrappedDialogWarning } from './popupStyle';
import declineImg from '../../assets/UI/popup_close_white.svg';
import gsap from 'gsap';

interface IProps {
  mainText: string;
  describeText?: string;
  warningText?: string;
  handleWarning: () => void;
}

const DialogWarning: React.FC<IProps> = ({mainText, warningText = 'OK', handleWarning, describeText}) => {
  const myRef = useRef(null);
  const hotKeys = useCallback((e) => {
    //console.log(e.key)
    if (e.key === 'Enter') {
      handleWarning();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    window.addEventListener('keydown', hotKeys);
    return () => {
      window.removeEventListener('keydown', hotKeys);
    }
  }, [hotKeys])

  useEffect(() => {
    gsap.to(myRef.current, AnimPopupError)
  }, [])

  return (
    <WrappedDialogWarning ref={myRef} onClick={(e) => e.stopPropagation()}>
      <p className="text">{mainText}</p>
      <p className="text text__describe">{describeText}</p>
      <div className="buttons">
        <Button
          background={BTN_TEXT_COLOR_PRESSED}
          color={'white'}
          handleClick={handleWarning}
        >
          <img src={declineImg} alt='decline'/>
          {warningText}
        </Button>
      </div>
    </WrappedDialogWarning>
  );
}

export default DialogWarning;