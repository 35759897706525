// common

export enum EButtonOpenerTypes {
  'ARROW_DOWN' = 'arrowDown',
  'ARROW_UP' = 'arrowUp',
  'MENU' = 'menu',
  'CLOSE' = 'close',
  'CALC' = 'calculator',
}

export enum EDecorationChapters {
  'NUMBER' = 'Number',
  'LAST_NAME' = 'Last name',
  'TEAM_LOGO' = 'Team logo',
  'SPONSOR1' = 'Sponsor logo 1',
  'SPONSOR2' = 'Sponsor logo 2',
  'SPONSOR3' = 'Sponsor logo 3',
}

// data config

export enum EConfigGarment {
  'JERSEY' = 'jersey',
  'SHORTS' = 'shorts',
  'SOCKS' = 'socks',
}

export enum EConfigColorSchemes {
  'BASE' = 'Base',
  'DETAILS' = 'Details',
  'LOGO' = 'Logo',
  'DESIGN1' = 'Design 1',
  'DESIGN2' = 'Design 2',
  'DESIGN3' = 'Design 3',
}

export enum EConfigDesigns {
  'ADA' = 'Ada',
  'ALESSANDRO' = 'Alessandro',
  'ALLEN' = 'Allen',
  'BJORN' = 'Bjorn',
  'DEFAULT' = 'Default',
  'GABIN' = 'Gabin',
  'GUGA' = 'Guga',
  'HENRIK' = 'Henrik',
  'JOHAN' = 'Johan',
  'KARCH' = 'Karch',
  'LEWIS' = 'Lewis',
  'MARAT' = 'Marat',
  'MEGAN' = 'Megan',
  'MICHEL' = 'Michel',
  'NIKOLA' = 'Nikola',
  'ROD' = 'Rod',
  'SAFET' = 'Safet',
  'SEBASTIEN' = 'Sebastien',
  'SHELLY' = 'Shelly',
  'STEVEN' = 'Steven',
  'TONY' = 'Tony',
}
