import React from 'react';
import ImageSizeInput from "../../../components/ImageSizeInput";
import InputRange from "../../../components/UI/Input/InputRange";
import {WrapperStepDecorationSizeSlider} from "./stepDecorationStyle";
import {observer} from "mobx-react-lite";
import calculateYSize from "../../../utils/calculateYSize";
import {IPicSize} from "../../../store/types";
import {EConfigGarment} from "../../../enums";
import store from '../../../store/rootStore';
import {convertCmToPixels, getPositionMinMax, MinMaxReturn} from "../../../api/positionsApi";
// import {cloneDeep} from "lodash";
import calculateDefaultXSize from "../../../utils/calculateDefaultXSize";

interface IProps {
  width: string;
}

const SizeSlider = observer(({ width }: IProps) => {
  const {
    currentGarment,
    getDecorationShortsValue,
    getDecorationJerseyValue,
    setDecorationJersey,
    setDecorationShorts,
    stepDecoration,
    stepCollection,
  } = store.app;

  const {
    changeLogoSpriteSize,
  } = store.canvas;

  const getPicSize = (): IPicSize => {
    if (currentGarment === EConfigGarment.JERSEY) {
      return getDecorationJerseyValue(stepDecoration.currentJerseyChapter, 'picSize') as IPicSize;
    } else {
      return getDecorationShortsValue(stepDecoration.currentShortsChapter, 'picSize') as IPicSize;
    }
  }

  const getLimits = (): MinMaxReturn => {
    const chapter = currentGarment === EConfigGarment.JERSEY ? stepDecoration.currentJerseyChapter : stepDecoration.currentShortsChapter;
    const limits = getPositionMinMax(
      stepCollection[currentGarment],
      currentGarment,
      chapter,
      // @ts-ignore
      stepDecoration[currentGarment][chapter].position,
    );
    if (limits) {
      return limits;
    }
    return {
      minX: 0,
      maxX: 10,
      minY: 0,
      maxY: 10,
    }
  }

  const changePicSize = (x: number, limits: MinMaxReturn) => {
    const y = calculateYSize({
      size: x,
      minX: limits.minX,
      maxX: limits.maxX,
      minY: limits.minY,
      maxY: limits.maxY,
    });
    const width = convertCmToPixels(x);
    const height = convertCmToPixels(y);
    if (currentGarment === EConfigGarment.JERSEY) {
      setDecorationJersey(stepDecoration.currentJerseyChapter, 'picSize', { x, y, unit: 'cm' });
      changeLogoSpriteSize(width, height, currentGarment, stepDecoration.currentJerseyChapter);
    } else {
      setDecorationShorts(stepDecoration.currentShortsChapter, 'picSize', { x, y, unit: 'cm' });
      changeLogoSpriteSize(width, height, currentGarment, stepDecoration.currentShortsChapter);
    }
  }

  const renderComponent = (picSize: IPicSize, limits: MinMaxReturn) => {
    if (
      picSize.x < limits.minX ||
      picSize.x > limits.maxX ||
      picSize.y < limits.minY ||
      picSize.y > limits.maxY
    ) {
      // предотвращение ситуации, если текущий размер выходит за лимиты
      const x = calculateDefaultXSize(limits.minX, limits.maxX);
      picSize = {
        x,
        y: calculateYSize({
          size: x,
          minX: limits.minX,
          maxX: limits.maxX,
          minY: limits.minY,
          maxY: limits.maxY,
        }),
        unit: 'cm',
      }
      const width = convertCmToPixels(picSize.x);
      const height = convertCmToPixels(picSize.y);
      if (currentGarment === EConfigGarment.JERSEY) {
        changeLogoSpriteSize(width, height, currentGarment, stepDecoration.currentJerseyChapter);
      } else {
        changeLogoSpriteSize(width, height, currentGarment, stepDecoration.currentShortsChapter);
      }
    }

    // console.log({
    //   picSize: cloneDeep(picSize),
    //   limits: cloneDeep(limits),
    // })

    return <>
      <ImageSizeInput
        picSize={picSize}
      />
      <InputRange
        handleChange={(value) => changePicSize(Number(value), limits)}
        value={picSize.x}
        min={limits.minX}
        max={limits.maxX}
        step={0.1}
        width={width}
        hideValue
      />
    </>
  }

  return (
    <WrapperStepDecorationSizeSlider>
      { renderComponent(getPicSize(), getLimits()) }
    </WrapperStepDecorationSizeSlider>
  );
});

export default SizeSlider;
