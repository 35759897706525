import styled from "styled-components";
import {BTN_TEXT_COLOR, WHITE} from "../../../constants";

export interface CheckboxStyleProps {
  isActive: boolean;
}

export const Input = styled.input`
  opacity: 0;
  outline: 0;
  position: absolute;
  margin: 0;
  width: 0;
  height: 0;
`

export const CheckboxStyle = styled.label<CheckboxStyleProps>`
  border: 2px solid ${props => props.isActive ? WHITE : BTN_TEXT_COLOR};
  transition: all .2s ease;
  flex-shrink: 0;
  position: relative;
  display: flex;
  align-items: center;
  margin: 4px 0 0 4px;
  width: 20px;
  height: 20px;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  background: transparent;
  box-sizing: border-box;
`
