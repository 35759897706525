import React from 'react';
import Header from './containers/Layout/Header/Header';
import Main from './containers/Main/Main';
import MenuPanel from './containers/MenuPanel/MenuPanel';

const App: React.FC = () => {
  return (
    <>
      <Header/>
      <Main/>
      <MenuPanel/>
    </>
  );
}

export default App;
