import React, {useEffect, useState} from 'react';
import Option from "./Option";
import {useTranslation} from "react-i18next";
import store from "../../../store/rootStore";
import {observer} from "mobx-react-lite";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

interface IProps {
  closeMenu: () => void;
}

const MenuSelectorContent = observer(({ closeMenu }: IProps) => {
  const {setStep, currentStep, result, putDataFromResultToState, currentKitId, isModelLoading} = store.app;
  const [isAnyKit, setIsAnyKit] = useState<boolean>(Boolean(result.length));

  const { t } = useTranslation();
  const stepsArr: string[] = t('steps', {returnObjects: true});

  useEffect(() => {
    setIsAnyKit(Boolean(result.length));
  }, [result.length])

  const handleSetStep = (num: number) => {
    if (!isAnyKit || isModelLoading) {
      return;
    }
    if (currentStep === 5 && num > 0 && num < 5) {
      const playerId = result.find(item => item.id === currentKitId)?.selectedPlayerId || '0';
      putDataFromResultToState(currentKitId, playerId);
    }
    setStep(num);
    closeMenu();
  }

  return (
    <Wrapper>
      {
        stepsArr.map((item, index) => <Option
          key={index}
          handleClick={() => handleSetStep(index)}
          isSelected={index === currentStep}
          isActive={isAnyKit && !isModelLoading}
        >
          {item}
        </Option>)
      }
    </Wrapper>
  );
});

export default MenuSelectorContent;
