import React from 'react';
import { WrappedMenuPanelSlider } from './menuPanelStyle';

interface IProps {
  isStepSport: boolean;
}

const MenuPanelSlider: React.FC<IProps> = ({children, isStepSport}) => {

  return (
    <WrappedMenuPanelSlider isStepSport={isStepSport}>
      {children}
    </WrappedMenuPanelSlider>
  );
}

export default MenuPanelSlider;