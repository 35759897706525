import styled, { css } from 'styled-components';
import { BG_GREEN, BTN_BG_COLOR_DISABLED, BTN_BORDER_COLOR } from '../../constants';

const pad__normal = css`
  border: 1px solid ${BTN_BORDER_COLOR};
  background: #FFFFFF;
`

const pad__selected = css`
  border: 1px solid ${BG_GREEN};
  background: ${BG_GREEN};
`

const pad__border_none = css`
  border: 1px solid transparent;
`

const pad__hover = css`
  background: ${BTN_BG_COLOR_DISABLED};
  border: 1px solid ${BTN_BG_COLOR_DISABLED};
`

interface IClothSelectProps {
  isCollapsed: boolean;
  viewportHeight: number;
}

export const WrappedClothSelect = styled.div<IClothSelectProps>`
  position: absolute;
  z-index: 999;
  display: ${props => props.viewportHeight ? 'flex' : 'none'};
  align-items: center;

  .wrapper {
    background: #FFFFFF;
    border-radius: 16px;
    padding: 16px;
    position: relative;

    .collapsed {
      display: none;
    }

    .exploded {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 16px;
    }
  }

  @media all and (max-width: 1920px) {
    height: ${props => props.viewportHeight - 182 + 'px'};

    .wrapper {    
      padding: 10px;

      .exploded {
        gap: 8px;
      }
    }
  }

  @media all and (max-width: 768px) {
    width: ${props => props.isCollapsed ? 'unset' : '100%'};
    display: block;

    .wrapper {
      padding: 0;
      width: ${props => props.isCollapsed ? 'auto' : '90%'};
      background: unset;
      top: 80px;
      z-index: 10;

      .collapsed {
        width: 100px;
        height: 40px;
        background: white;
        border-radius: 0 16px 16px 0;
        display: ${props => props.isCollapsed ? 'flex' : 'none'};
        justify-content: flex-end;
        align-items: center;
        cursor: pointer;
        position: absolute;
        left: -60px;
        top: -63px;
        
        img {
          width: 24px;
          margin-right: 8px;
        }
      }

      .exploded {
        display: ${props => props.isCollapsed ? 'none' : 'flex'};
        position: absolute;
        left: 8px;
        top: -63px;
      }
    }
  }
`

interface IClothPadProps {
  isSelected: boolean;
  isInactive: boolean;
  isClick: boolean;
  isPlus: boolean;
}

export const WrappedClothPad = styled.div<IClothPadProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 0;
  padding: 16px;
  width: 120px;
  box-sizing: border-box;
  height: 88px;
  border: 1px solid ${props => {
    if (!props.isSelected && !props.isPlus) return BTN_BORDER_COLOR;
    return 'transparent'
  } } !important;
  border-radius: 16px;
  transition: background .2s ease-in-out;
  ${props => props.isSelected ? pad__selected : pad__normal}

  .icon, .btn {
    opacity: ${props => props.isInactive ? '.3' : '1'};
  }

  .btn {
    cursor: pointer;
  }

  ${props => props.isInactive && pad__border_none}

  &:hover {
    ${props => (!props.isInactive && !props.isSelected && props.isClick) && pad__hover}
  }

  @media all and (max-width: 1920px) {
    padding: 12px;
    width: 100px;
    height: 73px;

    .icon {
      height: 40px;
    }

    .btn {
      width: 22px;
    }
  }

  @media all and (max-width: 768px) {
    padding: 8px;
    width: 72px;
    height: 40px;

    .icon {
      height: 23px;
    }

    .btn {
      width: 20px;
    }
  }
`
