import React from 'react';
import {DisplayHeaderStyle} from "./totalPriceDisplayStyle";

interface IProps {
  total: string;
}

const TotalPriceDisplayHeader = ({ total }: IProps) => {
  return (
    <DisplayHeaderStyle
      isDiscount={false}
    >
      <p>{total}</p>
    </DisplayHeaderStyle>
  );
};

export default TotalPriceDisplayHeader;
