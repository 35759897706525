import styled, {css} from "styled-components";
import {BTN_BORDER_COLOR, BTN_TEXT_COLOR, TEXT_COLOR} from "../../constants";

interface IDisplayProps {
  isDiscount?: boolean;
}

interface IDisplayRowProps extends IDisplayProps {
  isSelected: boolean;
  isForTeam?: boolean;
}

const HeaderCss = css`
  p {
    text-align: right;
    padding-right: 12px;
  }
  
  .old-price {
    color: ${BTN_BORDER_COLOR};
    font-size: 10px;
    text-decoration-line: line-through;
  }

  .new-price {
    color: white;
    font-size: 18px !important;
  }
`

const RowCss = css`
  transform: translateX(-7px);

  .old-price {
    color: ${BTN_TEXT_COLOR};
    font-size: 10px;
    text-decoration-line: line-through;
  }

  .new-price {
    color: ${TEXT_COLOR};
  }
`

const RowCssSelected = css`
  .old-price {
    color: ${BTN_BORDER_COLOR};
  }

  .new-price {
    color: white;
  }
`

const mobileCssFix = css`
  @media all and (max-width: 768px) {
    font-size: 18px !important;
  }
`

export const DisplayHeaderStyle = styled.div<IDisplayProps>`
  p {
    color: white;
    font-weight: 700;
    min-width: 68px;

    ${props => !props.isDiscount && mobileCssFix}
  }

  ${props => props.isDiscount && HeaderCss}
`

export const DisplayRowStyle = styled.div<IDisplayRowProps>`
  p {
    color: ${props => props.isSelected ? 'white' : TEXT_COLOR};
    font-weight: 700;
    font-size: 14px;
    text-align: ${props => (props.isDiscount && !props.isForTeam) ? 'center' : 'left'};

    @media all and (max-width: 768px) {
      text-align: center;
    }
  }

  ${props => props.isDiscount && RowCss}
  ${props => props.isSelected && RowCssSelected}
  
  .old-price {
    color: ${props => !props.isDiscount && props.isSelected && 'white'}
  }
`
