import React, {useEffect, useState} from 'react';
import {CalcGarments, CalcGarmentsRow} from "../style";
import {EConfigGarment} from "../../../enums";
import ClothPad from "../../../components/ClothSelect/ClothPad";
import {observer} from "mobx-react-lite";
import store from '../../../store/rootStore';
import getClothPadImages from "../../../utils/getClothPadImages";
import useClothPads from "../../../hooks/useClothPads";
import PadColumn from "./PadColumn";
import TotalPriceColumn from "./TotalPriceColumn";
import UnitsRange from "./UnitsRange";
import { getPrice } from "../../../api/catalogApi";

export interface ReturnGarmentsPrice {
  jersey: number;
  shorts: number;
  socks: number;
  kit: number;
  total: number;
}

const CalculatorLayout = observer(() => {
  const {
    hideSocks,
    includeJersey,
    includeShorts,
    includeSocks,
    stepSportId,
    calculatorKitsCount,
  } = store.app;

  const [localIncludeJersey, setLocalIncludeJersey] = useState<boolean>(false);
  const [localIncludeShorts, setLocalIncludeShorts] = useState<boolean>(false);
  const [localIncludeSocks, setLocalIncludeSocks] = useState<boolean>(false);


  const { handleInclude, getInactive } = useClothPads({
    isCalc: true,
    setLocalIncludeJersey,
    setLocalIncludeShorts,
    setLocalIncludeSocks,
    localIncludeSocks,
    localIncludeShorts,
    localIncludeJersey,
  });

  const [stats, setStats] = useState<ReturnGarmentsPrice>({
    jersey: 0,
    shorts: 0,
    socks: 0,
    kit: 0,
    total: 0,
  });

  useEffect(() => {
    setLocalIncludeJersey(includeJersey);
    setLocalIncludeShorts(includeShorts);
    setLocalIncludeSocks(includeSocks);
  }, [includeSocks, includeShorts, includeJersey]);

  useEffect(() => {
    const jersey = localIncludeJersey ? getPrice(stepSportId, EConfigGarment.JERSEY, calculatorKitsCount) : 0;
    const shorts = localIncludeShorts ? getPrice(stepSportId, EConfigGarment.SHORTS, calculatorKitsCount) : 0;
    const socks = localIncludeSocks ? getPrice(stepSportId, EConfigGarment.SOCKS, calculatorKitsCount) : 0;
    const kit = jersey + shorts + socks;

    setStats({
      jersey,
      shorts,
      socks,
      kit,
      total: kit * calculatorKitsCount,
    });
  }, [localIncludeJersey, localIncludeShorts, localIncludeSocks, calculatorKitsCount, stepSportId])

  return (
    <>
      <CalcGarmentsRow>
        <CalcGarments>
          {
            Object.values(EConfigGarment).map((item, index) => {
              if (hideSocks && item === EConfigGarment.SOCKS) {
                return null;
              }

              const isInactive = getInactive(item as EConfigGarment);

              let price = 0;
              let isPlus = false;
              if (item === EConfigGarment.SHORTS && localIncludeShorts) {
                isPlus = true;
                price = stats.shorts;
              }
              if (item === EConfigGarment.SOCKS && localIncludeSocks) {
                isPlus = true;
                price = stats.socks;
              }
              if (item === EConfigGarment.JERSEY && localIncludeJersey) {
                isPlus = true;
                price = stats.jersey;
              }
              const myItem = item as EConfigGarment;

              return <PadColumn
                key={index}
                isInactive={isInactive}
                price={price}
              >
                <ClothPad
                  isSelected={false}
                  isInactive={isInactive}
                  handleButton={() => handleInclude({
                    item: myItem,
                    isPlus,
                    includeJersey,
                    includeShorts,
                    includeSocks,
                  })}
                  isPlus={isPlus}
                  images={getClothPadImages(myItem)}
                />
              </PadColumn>
            })
          }
        </CalcGarments>

        <TotalPriceColumn
          stats={stats}
        />

      </CalcGarmentsRow>
      <UnitsRange />
    </>
  );
});

export default CalculatorLayout;
