import React from 'react';
import styled from "styled-components";
import {BTN_BORDER_COLOR} from "../../../constants";
import {
  TextPrice,
  TotalPriceRow,
  TextPriceDescription,
  TotalPriceHorizontal,
  TextTotalPrice,
  FirstPrice
} from "../style";
import {useTranslation} from "react-i18next";
import {ReturnGarmentsPrice} from "./CalculatorLayout";
import {convertPrice} from "../../../utils";

const Wrapper = styled.div`
  background: #FFFFFF;
  border: 1px solid ${BTN_BORDER_COLOR};
  border-radius: 16px;

  @media (max-width: 1200px) {
    display: flex;
  }
  
  @media (max-width: 768px) {
    display: flex;
  }
`;

interface IProps {
  stats: ReturnGarmentsPrice;
}

const TotalPriceColumn = ({ stats }: IProps) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <FirstPrice>
        <TextPrice>
          { convertPrice(stats.kit) }
        </TextPrice>
        <TextPriceDescription>
          {t('calculator.per_unit')}
        </TextPriceDescription>
      </FirstPrice>

      <TotalPriceHorizontal />

      <TotalPriceRow>
        <TextTotalPrice>
          { convertPrice(stats.total) }
        </TextTotalPrice>
        <TextPriceDescription>
          {t('order_formation.total_price')}
        </TextPriceDescription>
      </TotalPriceRow>
    </Wrapper>
  );
};

export default TotalPriceColumn;
