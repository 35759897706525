import React from 'react';
import {WrappedTableHeader} from './orderStyle';
import imgChevronDown from '../../../assets/UI/chevron_white_down.svg';
import imgChevronUp  from '../../../assets/UI/chevron_white_up.svg';
import { useTranslation } from 'react-i18next';
import {IReturnPrice} from "./TableLayout";
import TotalPriceDisplayHeader from "../../../components/TotalPriceDisplay/TotalPriceDisplayHeader";

interface IProps {
  toggleOpen: () => void;
  isOpen: boolean;
  renderKitInfo: JSX.Element;
  renderGarments: JSX.Element;
  renderSport: string;
  renderEdit: JSX.Element;
  renderTotalPrice: IReturnPrice;
  renderRemoveKit: JSX.Element;
  renderModel?: string;
  renderTotalPlayers?: JSX.Element;
  renderCopy?: JSX.Element;
}

const TableHeader: React.FC<IProps> = ({
  toggleOpen,
  isOpen,
  renderKitInfo,
  renderGarments,
  renderSport,
  renderEdit,
  renderTotalPrice,
  renderRemoveKit,
  renderModel,
  renderTotalPlayers,
  renderCopy,
}) => {
  const { t } = useTranslation();

  return (
    <WrappedTableHeader isOpen={isOpen}>

      <div className="header desktop">

        <div className="left">
          {renderKitInfo}
          <div className="garments">
            {renderGarments}
          </div>
          <p className='sport'>{renderSport}</p>
          <p
            className='sport'
            title={t('order_formation.titles.garment_design')}
          >
            {renderModel}
          </p>
        </div>

        <div className="right">
          {
            renderTotalPlayers
          }
          {renderEdit}
          <TotalPriceDisplayHeader
            total={renderTotalPrice.discount || renderTotalPrice.total}
          />
          {
            renderCopy
          }
          {renderRemoveKit}
          <div
            className='cell_wrap'
            onClick={toggleOpen}
          >
            <img
              src={isOpen ? imgChevronDown : imgChevronUp}
              alt='open'
            />
          </div>
        </div>

      </div>

      <div className="header mobile">

        <div className="first_row">
          {renderKitInfo}
          {
            renderTotalPlayers
          }
          <TotalPriceDisplayHeader
            total={renderTotalPrice.discount || renderTotalPrice.total}
          />
          {renderRemoveKit}
          <div
            className='cell_wrap'
            onClick={toggleOpen}
          >
            <img
              src={isOpen ? imgChevronDown : imgChevronUp}
              alt='open'
            />
          </div>
        </div>

        {
          isOpen && <>
            <div className="garments">
              {renderGarments}
            </div>
            <div className='first_row'>
              <p className='sport'>{renderSport}</p>
              {renderEdit}
              {renderCopy}
            </div>
          </>
        }
      </div>
    </WrappedTableHeader>
  );
}

export default TableHeader;
