import styled from 'styled-components';

interface IProps {
  isStepSport: boolean;
}

export const WrappedMain = styled.div<IProps>`
  width: 100%;
  overflow-x: ${props => props.isStepSport ? 'hidden' : 'auto'};
  padding-top: 88px;

  @media all and (max-width: 768px) {
    padding-top: 64px;
  }
`
