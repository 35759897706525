import React from 'react';
import { WrappedOption } from './style';

interface IProps {
  handleClick: () => void;
  isSelected: boolean;
  isActive: boolean;
}

const Option: React.FC<IProps> = ({ children, handleClick, isSelected, isActive }) => {
  return (
    <WrappedOption 
      onClick={handleClick}
      isSelected={isSelected}
      isActive={isActive}
    >
      {children}
    </WrappedOption>
  );
}

export default Option;
