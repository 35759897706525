import React from 'react';
import { WrappedCardMainMenu } from './cardsStyle';

interface IProps {
  imgPath: string;
  text: string;
  handleClick: () => void;
  handleMouseOver: () => void;
  handleMouseOut: () => void;
  isMouseOver: boolean;
  isActive: boolean;
}

const CardMainMenu: React.FC<IProps> = ({ 
    text, 
    imgPath, 
    handleClick, 
    handleMouseOver, 
    handleMouseOut, 
    isMouseOver,
    isActive
  }) => {
  
  return (
    <WrappedCardMainMenu
      imgPath={imgPath}
      onClick={handleClick}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      isMouseOver={isMouseOver}
      isActive={isActive}
    >
      <div className="text__cont">
        {text}
      </div>
    </WrappedCardMainMenu>
  );
}

export default CardMainMenu;