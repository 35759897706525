import {SxProps} from "@mui/material";
import styled, {css} from "styled-components";
import {
  BG_GREEN, BTN_BG_COLOR_DISABLED,
  BTN_BORDER_COLOR,
  BTN_TEXT_COLOR,
  BTN_TEXT_COLOR_PRESSED
} from "../../constants";

export const CalcMenuPaperStyle: SxProps = {
  background: '#FFFFFF',
  borderRadius: '16px',
  padding: '32px',
  boxShadow: '0px 8px 33px -16px rgba(0, 0, 0, 0.25)',
  width: '760px',

  '@media (max-width: 768px)': {
    padding: '88px 16px 16px',
  }
}

export const FakeCalcMenuButton = styled('div')`
  pointer-events: none;
  position: absolute;
  right: -8px;
  bottom: -8px;
`;

export const CalcGarmentsRow = styled.div`
  display: flex;
  gap: 43px;
  margin-bottom: 40px;
  width: 100%;

  @media (max-width: 1200px) {
    margin-bottom: 22px;
  }
  
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const CalcGarments = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;
`;

interface TextPadCostProps {
  isInactive: boolean;
}

export const TextPadCost = styled.p<TextPadCostProps>`
  font-family: Montserrat, sans-serif;
  font-weight: 700;
  font-size: 14px;
  font-style: italic;
  color: ${props => props.isInactive ? BTN_BORDER_COLOR : BTN_TEXT_COLOR};
  
  > span {
    font-size: 10px;
    font-weight: 400;
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;

export const FirstPrice = styled.div`
  padding: 12px 16px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 275px;

  @media (max-width: 1200px) {
    max-width: 180px;
    flex-grow: 1;
  }

  @media (max-width: 768px) {
    padding: 8px 16px;
  }
`;

export const TotalPriceRow = styled.div`
  padding: 12px 16px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 275px;

  @media (max-width: 1200px) {
    width: auto;
  }

  @media (max-width: 768px) {
    padding: 8px 16px;
  }
`;

export const TotalPriceHorizontal = styled.div`
  border-bottom: 1px solid ${BTN_BORDER_COLOR};
  width: 100%;

  @media (max-width: 1200px) { 
    width: 1px;
    background: ${BTN_BORDER_COLOR};
    height: 100%;
  }
  
  @media (max-width: 768px) {
    width: unset;
    height: 90px;
    border-bottom: none;
    border-left: 1px solid ${BTN_BORDER_COLOR};
  }
`;

export const TextPrice = styled.p`
  font-style: italic;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: ${BG_GREEN};
  
  @media (max-width: 1200px) {
    font-size: 18px;
    line-height: 22px;
  }
`;

export const TextPriceDescription = styled.p`
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: black;

  @media (max-width: 1200px) {
    font-size: 12px;
    line-height: 16px;
  }
`;

export const TextTotalPrice = styled.p`
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: ${BTN_TEXT_COLOR};

  @media (max-width: 1200px) {
    font-size: 14px;
    line-height: 17px;
  }
`;

// range

const rangeTrack = css`
  background-color: #BDBDBD;
  border-radius: 2px;
  height: 4px;
  -webkit-appearance: none;
`

const rangeThumb = css`
  width: 16px;
  height: 32px;
  -webkit-appearance: none;
  cursor: ew-resize;
  background: ${BTN_TEXT_COLOR_PRESSED};
  border: 2px solid #FFFFFF;
  border-radius: 7px;
  margin-top: -13px;
`

export const CalcInputRange = styled.input`
  width: 100%;
  height: 32px;
  padding: 0 11px 0 4px;
  
  overflow: hidden;
  -webkit-appearance: none;
  background-color: transparent;
  border: none;

  &::-webkit-slider-runnable-track {
    ${rangeTrack}
  }
  &::-moz-range-track {
    ${rangeTrack}
  }
  &::-webkit-slider-thumb {
    ${rangeThumb}
  }
  &::-moz-range-thumb {
    ${rangeThumb}
  }
`;

export const CalcRangeScale = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  padding: 0 18px 0 4px;
  
  > div {
    height: 24px;
    border-left: 2px solid ${BTN_BG_COLOR_DISABLED};
  }
  
  > div:nth-of-type(2n) {
    height: 14px;
  }

  @media (max-width: 768px) {
    padding: 0 18px 0 8px;
  }
`;

export const CalcRangeNumbers = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  
  p {
    width: 32px;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: ${BTN_TEXT_COLOR};
  }

  @media (max-width: 1200px) {
    margin-bottom: 24px;
  }

  @media (max-width: 768px) {
    padding-left: 4px;
    
    p {
      font-size: 12px;
      line-height: 15px;
    }
  }
`;

export const CalcExitButton = styled.div`
  position: absolute;
  right: 16px;
  bottom: 8px;
  
  @media (max-width: 768px) {
    right: 16px;
    bottom: unset;
    top: 16px;
  }
`
