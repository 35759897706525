import { observer } from 'mobx-react-lite';
import React from 'react';
import Viewport from './Viewport';
import { WrappedLayoutViewport } from './viewportStyle';
import ClothSelect from '../../components/ClothSelect/ClothSelect';
import InfoPanel from "../InfoPanel";

const LayoutViewport: React.FC = observer(() => {
  return (
    <WrappedLayoutViewport>
        <ClothSelect/>
        <Viewport/>
        <InfoPanel />
    </WrappedLayoutViewport>
  );
})

export default LayoutViewport;
