import styled from 'styled-components';

interface IDecProps {
  isColorPicker: boolean;
}

export const WrappedStepDecorationPanel = styled.div<IDecProps>`
  .desktop {
    justify-content: center;
    display: flex;
  }

  .mobile {
    display: none;
  }

  @media all and (max-width: 768px) {
    .desktop {
      display: none;
    }

    .mobile {
      display: block;
      padding: 16px 0 0 0;
      > div {
        overflow-x: ${props => props.isColorPicker ? 'scroll' : 'unset'};
      }
    }
  }
`

export const WrappedStepDecorationPanelHead = styled.div`
  display: flex;
  gap: 16px;
  padding-left: 120px;

  @media all and (max-width: 1120px) {
    padding-left: 0;
  }

  @media all and (max-width: 768px) {
    display: none;
  }
`

interface IProps {
  isNumberOrName: boolean;
}

export const WrappedStepDecorationPanelContent = styled.div<IProps>`
  .desktop {
    display: flex;
    justify-content: center;
    padding-top: 32px;
    gap: 32px;

    .upload_image {
      display: flex;
      gap: 16px;
    }
  }

  .mobile {
    display: none;
  }

  .supports {
    font-weight: 700;
    margin-top: 4px;
  }

  @media all and (max-width: 1920px) {
    .desktop {
      gap: 16px;
    }
  }

  @media all and (max-width: 768px) {
    .desktop {
      display: none;
    }

    .mobile {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 16px;
      padding: 16px 0 0 0;

      .categories {
        > div {
          justify-content: space-between;
          > button {
            width: 100%;
            height: 32px;
          }
        }
      }

      .mobile__content {
        display: flex;
        flex-wrap: ${props => props.isNumberOrName ? 'nowrap' : 'wrap'};
        width: 100%;
        gap: 16px;
        justify-content: center;
        align-items: center;

        input[type='text'] {
          width: 100%;
        }
        
        .upload_image {
          display: flex;
          width: 100%;
          justify-content: center;
          gap: 16px;
        }
      }
    }
  }
`

export const WrapperStepDecorationSizeSlider = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const WrapperStepDecorationPosition = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;
`;
