import React, {FC, useCallback, useMemo} from 'react';
import Checkbox from "../UI/Checkbox/Checkbox";
import {WrappedClothPad} from "./style";

export interface IClothPadImages {
  cloth: string;
  currentCloth: string;
  disableCloth: string;
}
interface ClothPadProps {
  isSelected: boolean;
  handleClick?: () => void;
  handleButton: () => void;
  images: IClothPadImages;
  isPlus: boolean;
  isInactive: boolean;
  item?: string;
}

const ClothPad: FC<ClothPadProps> = (props) => {
  const {
    isSelected,
    handleClick,
    handleButton,
    images,
    item,
    isPlus,
    isInactive
  } = props;

  const handlePadClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (!isInactive && handleClick) {
      handleClick();
    }
  }

  const currentImage = useMemo(() => {
    if (isSelected) return images.currentCloth;
    if (!isSelected && isPlus) return images.cloth;
    return images.disableCloth;
  }, [isPlus, isSelected, images]);

  const checkboxClickHandler = useCallback((e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    handleButton();
  }, [handleButton]);

  return (
    <WrappedClothPad
      isPlus={isPlus}
      isSelected={isSelected}
      isInactive={isInactive}
      isClick={Boolean(handleClick)}
      onClick={handlePadClick}
    >
      <img
        className='icon'
        src={currentImage}
        alt='cloth'
      />
      <div onClick={(e) => checkboxClickHandler(e as any)}>
        <Checkbox
          id={item}
          checked={isPlus}
          isActive={isSelected}
        />
      </div>
    </WrappedClothPad>
  );
}

export default ClothPad;
