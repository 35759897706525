import React, { useState } from 'react';
import { WrappedTableLayout } from './orderStyle';
import InputInfo from '../../../components/UI/Input/InputInfo';
import imgJersey from '../../../assets/icons/cloth_football_jersey.svg';
import imgShortsWhite from '../../../assets/icons/cloth_football_shorts_selected.svg';
import imgSocksWhite from '../../../assets/icons/cloth_football_socks_selected.svg';
import imgEdit from '../../../assets/UI/edit.svg';
import imgRemoveWhite from '../../../assets/UI/remove_white.svg';
import imgPerson from '../../../assets/UI/person.svg';
import imgCopy from '../../../assets/UI/copy.svg';
import imgPlus from '../../../assets/UI/plus.svg';
import TableHeader from './TableHeader';
import Button from '../../../components/UI/Buttons/Button';
import {IGarmentsQuantityResult, IIndividualConfig, IOrderFormation} from '../../../store/types';
import store from '../../../store/rootStore';
import { EConfigGarment } from '../../../enums';
import { getSportName } from '../../../api/sportsApi';
import { BTN_TEXT_COLOR } from '../../../constants';
import { observer } from 'mobx-react-lite';
import DialogConfirm from '../../../components/Popups/DialogConfirm';
import TableRowTeam from './TableRowTeam';
import InputText from '../../../components/UI/Input/InputText';
import DialogWarning from '../../../components/Popups/DialogWarning';
import {TGender} from "../../../db/types";
import { useTranslation } from 'react-i18next';
import {
  getKitSumDiscount,
  getPlayerSum,
  getPlayerSumDiscount
} from '../../../api/catalogApi';
import {convertPrice} from "../../../utils";
import svgLens from '../../../assets/UI/lens.svg';
import PreviewGarments from "../../../components/Popups/PreviewGarments";

interface IProps {
  kitsCount: number;
  data: IOrderFormation;
  playerData: IIndividualConfig;
  allGarmentsQuantity: IGarmentsQuantityResult;
  handleCopyKit: () => void;
  handleEditKit: () => void;
}

export interface IReturnPrice {
  total: string;
  discount?: string;
}

const TableLayout: React.FC<IProps> = observer(({
  data,
  playerData,
  handleCopyKit,
  handleEditKit,
  kitsCount,
  allGarmentsQuantity,
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const {
    setOpenDialog,
    deleteKit,
    setDialogJSX,
    setSelectedPlayerId,
    setOrderGarmentLastName,
    setOrderGarmentNumber,
    setOrderGarmentSize,
    setOrderGarmentQuantity,
    setOrderKitsQuantity,
    deletePlayer,
    isGeneralQuantity,
    updateKitName,
    addNewPlayer,
    isModelLoading,
    setPlayerGender,
    includeJersey,
    includeShorts,
    includeSocks,
  } = store.app;

  // UI

  const toggleOpen = () => {
    setIsOpen(prev => !prev);
  }

  const handleRowTeamClick = (playerId: string) => {
    setSelectedPlayerId(data.id, playerId);
  }

  const handleRemoveKit = () => {
    if (isModelLoading) {
      return;
    }
    if (kitsCount < 2) {
      setDialogJSX(
        <DialogWarning
          mainText={t('ui.error')}
          describeText={t('warnings.last_kit')}
          handleWarning={() => setOpenDialog(false)}
        />
      );
      setOpenDialog(true);
      return;
    }
    setDialogJSX(
      <DialogConfirm
        mainText={t('warnings.delete_kit', {kitName: data.name})}
        confirmText={t('ui.yes')}
        declineText={t('ui.no')}
        handleConfirm={() => {
          deleteKit(data.id);
          setOpenDialog(false);
        }}
        handleDecline={() => setOpenDialog(false)}
      />
    );
    setOpenDialog(true);
  }

  const handleRemovePlayer = (playerId: string, name: string, length: number) => {
    if (length< 2) {
      setDialogJSX(
        <DialogWarning
          mainText={t('ui.error')}
          describeText={t('warnings.last_player')}
          handleWarning={() => setOpenDialog(false)}
        />
      );
      setOpenDialog(true);
      return;
    }
    setDialogJSX(
      <DialogConfirm
        mainText={t('warnings.delete_player', {player: name})}
        confirmText={t('ui.yes')}
        declineText={t('ui.no')}
        handleConfirm={() => {
          deletePlayer(data.id, playerId);
          setOpenDialog(false);
        }}
        handleDecline={() => setOpenDialog(false)}
      />
    );
    setOpenDialog(true);
  }

  const handleAddNewPlayer = () => {
    //console.log('add player to kitId:', data.id)
    addNewPlayer(data.id);
  }

  const handleOpenPreview = (garment: EConfigGarment) => {
    setDialogJSX(
      <PreviewGarments
        initialGarment={garment}
        modelImagesPath={data.modelConfig.modelImagesPath}
      />
    );
    setOpenDialog(true);
  }

  // CHANGE VALUES

  const handleChangeKitName = (value: string) => {
    updateKitName(data.id, value);
  }

  const handleLastName = (value: string) => {
    setOrderGarmentLastName(data.id, playerData.id, value);
  }

  const handleNumber = (value: string) => {
    setOrderGarmentNumber(data.id, playerData.id, value);
  }

  const handleSize = (value: string, garment: EConfigGarment) => {
    setOrderGarmentSize(data.id, playerData.id, garment, value);
  }

  const handleQuantityGarments = (value: number, garment: EConfigGarment) => {
    // for individual table
    setOrderGarmentQuantity(data.id, playerData.id, garment, value);
  }

  const handleQuantityKits = (value: number, playerId: string) => {
    setOrderKitsQuantity(data.id, playerId, value);
  }

  const handleGenderClick = (value: string, playerId: string | undefined) => {
    setPlayerGender(data.id, playerId, value as TGender);
  }

  // RENDER HEADER

  const renderKitInfo = (): JSX.Element => {
    return <InputText
          value={data.name}
          handleChange={handleChangeKitName}
          width='86px'
          style={{
            color: BTN_TEXT_COLOR,
            fontWeight: 700,
            fontSize: '14px',
          }}
        />
  }

  const renderGarments = (): JSX.Element => {
    if (!data) {
      //console.log('renderGarments: player not found');
      return <></>;
    }

    const {
      jerseyQuantity,
      shortsQuantity,
      socksQuantity
    } = allGarmentsQuantity;

    const isJerseyInactive = jerseyQuantity === 0 || !includeJersey;
    const isShortsInactive = shortsQuantity === 0 || !includeShorts;
    const isSocksInactive = socksQuantity === 0 || !includeSocks;

    return <>
      <InputInfo
        background={isJerseyInactive ? 'transparent' : 'white'}
        width={isJerseyInactive ? 'auto' : '65px'}
        handleClick={isJerseyInactive ? undefined : () => handleOpenPreview(EConfigGarment.JERSEY)}
      >
        <img src={isJerseyInactive ? imgJersey : data.modelConfig.modelImagesPath.jersey} alt='icon'/>
        {
          !isJerseyInactive
          && <>
              <img src={svgLens} alt='' className='lens'/>
              <p>{jerseyQuantity}</p>
            </>
        }
      </InputInfo>

      <InputInfo
        background={isShortsInactive ? 'transparent' : 'white'}
        width={isShortsInactive ? 'auto' : '65px'}
        handleClick={isShortsInactive ? undefined : () => handleOpenPreview(EConfigGarment.SHORTS)}
      >
        <img src={isShortsInactive ? imgShortsWhite : data.modelConfig.modelImagesPath.shorts} alt='icon'/>
        {
          !isShortsInactive
          && <>
              <img src={svgLens} alt='' className='lens'/>
              <p>{shortsQuantity}</p>
            </>
        }
      </InputInfo>

      {
        !data.hideSocks &&
          <InputInfo
              background={isSocksInactive ? 'transparent' : 'white'}
              width={isSocksInactive ? 'auto' : '65px'}
              handleClick={isSocksInactive ? undefined : () => handleOpenPreview(EConfigGarment.SOCKS)}
          >
            <img src={isSocksInactive ? imgSocksWhite : data.modelConfig.modelImagesPath.socks} alt='icon'/>
            {
              !isSocksInactive
              && <>
                  <img src={svgLens} alt='' className='lens'/>
                  <p>{socksQuantity}</p>
                </>
            }
          </InputInfo>
      }
    </>
  }

  const renderSport = (): string => {
    const name = getSportName(data.sportId);
    return t(`sports.${name}`);
  }

  const renderEdit = (): JSX.Element => {
    return <div
      className='cell_wrap'
      onClick={handleEditKit}
      title={t('order_formation.titles.edit')}
    >
      <img
        src={imgEdit}
        alt='edit'
      />
    </div>
  }

  const renderHeaderTeamPrice = (): IReturnPrice => {
    const total = data.sum;
    const discount = getKitSumDiscount(data.teamConfig, data.sportId);

    return {
      total: convertPrice(total),
      ...(discount !== total ? {
        discount: convertPrice(discount),
      } : {})
    }
  }

  const renderRemoveKit = (): JSX.Element => {
    return <div
      className='cell_wrap'
      onClick={handleRemoveKit}
      title={t('order_formation.titles.remove')}
    >
      <img
        src={imgRemoveWhite}
        alt='remove'
      />
    </div>
  }

  // RENDER HEADER TEAM

  const renderModel = (): string => {
    const design = data.modelConfig.designName || 'default';
    return `${t('garments.jersey')}: ` + design;
  }

  const renderTotalPlayers = (): JSX.Element => {
    return <InputInfo
            background='transparent'
            borderColor='transparent'
            title={t('order_formation.titles.total')}
          >
            <img src={imgPerson} alt='players'/>
            <p style={{color: 'white'}}>{data.teamConfig.length}</p>
          </InputInfo>
  }

  const renderCopy = (): JSX.Element => {
    return <InputInfo
          background='transparent'
          borderColor='transparent'
          handleClick={handleCopyKit}
          title={t('order_formation.titles.copy')}
        >
        <img
          src={imgCopy}
          alt='copy'
        />
        <p className='copy_text'>{t('order_formation.duplicate')}</p>
      </InputInfo>
  }

  // TOOLS

  const getKitSum = (player: IIndividualConfig, playerQuantityKits: number): IReturnPrice => {
    const total = getPlayerSum(player) * playerQuantityKits;
    const discount = getPlayerSumDiscount(player, allGarmentsQuantity, data.sportId) * playerQuantityKits;

    return {
      total: convertPrice(total),
      ...(discount !== total ? {
        discount: convertPrice(discount),
      } : {})
    };
  }

  const getQuantityGarment = (player: IIndividualConfig, garment: EConfigGarment): string => {
    return String(player.garments.find(item => item.garment === garment)?.quantityGarments) || '1';
  }

  return (
    <WrappedTableLayout
      isGeneralQuantity={isGeneralQuantity}
    >
      <TableHeader
        isOpen={isOpen}
        toggleOpen={toggleOpen}
        renderKitInfo={renderKitInfo()}
        renderSport={renderSport()}
        renderEdit={renderEdit()}
        renderTotalPrice={renderHeaderTeamPrice()}
        renderRemoveKit={renderRemoveKit()}
        renderGarments={renderGarments()}
        renderModel={renderModel()}
        renderTotalPlayers={renderTotalPlayers()}
        renderCopy={renderCopy()}
      />
      {
        isOpen &&
        <div className="table">
          <div className="thead">
            <div><p>{t('order_formation.last_name')}</p></div>
            <div><p>{t('order_formation.number')}</p></div>
            <div><p>{isGeneralQuantity ? t('order_formation.size.t_shirt') : t('order_formation.t_shirt')}</p></div>
            <div><p>{isGeneralQuantity ? t('order_formation.size.shorts') : t('order_formation.shorts')}</p></div>
            <div>
              <p
                style={{ visibility: data.hideSocks ? 'hidden' : 'visible'}}
              >
                {isGeneralQuantity ? t('order_formation.size.socks') : t('order_formation.socks')}
              </p>
            </div>
            {
              isGeneralQuantity &&
                <>
                  <div><p>{t('order_formation.gender')}</p></div>
                  <div><p>{t('order_formation.quantity')}</p></div>
                </>
            }
            <div><p>{t('order_formation.sum')}</p></div>
            <div className="add_player_header">
              <Button
                handleClick={handleAddNewPlayer}
              >
                <img src={imgPlus} alt='plus'/>
              </Button>
            </div>
          </div>

          <div className='tbody'>
            {
              data.teamConfig.map(player => {
                const jersey = player.garments.find(item => item.isInclude && item.garment === EConfigGarment.JERSEY);
                const shorts = player.garments.find(item => item.isInclude && item.garment === EConfigGarment.SHORTS);
                const socks = player.garments.find(item => item.isInclude && item.garment === EConfigGarment.SOCKS);
                return <TableRowTeam
                  key={player.id}
                  lastName={player.lastName}
                  number={player.number}
                  sizeShirt={jersey?.size || '-'}
                  sizeShorts={shorts?.size || '-'}
                  sizeSocks={socks?.size || '-'}
                  quantityKits={player.quantityKits}
                  sumKit={getKitSum(player, player.quantityKits)}
                  handleRowClick={() => handleRowTeamClick(player.id)}
                  isSelected={data.selectedPlayerId === player.id}
                  handleLastName={handleLastName}
                  handleNumber={handleNumber}
                  handleSize={handleSize}
                  handleQuantityKits={(value) => handleQuantityKits(value, player.id)}
                  handleQuantityGarment={handleQuantityGarments}
                  handleRemovePlayer={() => handleRemovePlayer(player.id, player.lastName, data.teamConfig.length)}
                  isGeneralQuantity={isGeneralQuantity}
                  quantityShirts={getQuantityGarment(player, EConfigGarment.JERSEY)}
                  quantityShorts={getQuantityGarment(player, EConfigGarment.SHORTS)}
                  quantitySocks={getQuantityGarment(player, EConfigGarment.SOCKS)}
                  isWomanOnly={data.isWomanOnly}
                  gender={data.isWomanOnly ? 'w' : player.gender}
                  handleGender={(value) => handleGenderClick(value, player.id)}
                  hideSocks={data.hideSocks}
                  includeJersey={jersey?.isInclude}
                  includeShorts={shorts?.isInclude}
                  includeSocks={socks?.isInclude}
                />
              })
            }
          </div>

          <div className='add_player'>
            <Button
              handleClick={handleAddNewPlayer}
            >
              <img src={imgPlus} alt='plus'/>
              {t('order_formation.add_new_player')}
            </Button>
          </div>
        </div>
      }
    </WrappedTableLayout>
  );
})

export default TableLayout;
