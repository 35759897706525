import React, { useEffect } from 'react';
import { WrappedInputFile } from './style';
import imgUpload from '../../../assets/UI/upload.svg';
import { useTranslation } from 'react-i18next';
import store from '../../../store/rootStore';
import DialogNotice from '../../Popups/DialogNotice';

interface IProps {
  height?: string;
  minWidth?: string;
  handleChange: (files: FileList | null) => void;
}

const InputFile: React.FC<IProps> = ({handleChange, height, minWidth}) => {
  const { t } = useTranslation();
  const { showUploadImageHint, setOpenDialog, setDialogJSX, setShowUploadImageHint } = store.app;

  useEffect(() => {
    if (showUploadImageHint) {
      setDialogJSX(
        <DialogNotice
          mainText={t('step_decoration.upload_image')}
          secondaryText={t('step_decoration.imageFormatHint')}
          btnText='OK'
          handleClick={() => setOpenDialog(false)}
        />
      );
      setOpenDialog(true);
      setShowUploadImageHint(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(e.target.files);
  }

  return (
    <WrappedInputFile
      height={height}
      minWidth={minWidth}
    >
      <input
        type='file'
        id='input__file'
        onChange={onChangeInput}
      />
      <label htmlFor='input__file'>
        <img src={imgUpload} alt='upload'/>
        <p>{t('step_decoration.upload_image')}</p>
      </label>
    </WrappedInputFile>
  );
}

export default InputFile;
