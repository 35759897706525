import React from 'react';
import {DisplayRowStyle} from "./totalPriceDisplayStyle";

interface IProps {
  total: string;
  isSelected: boolean;
  isForTeam?: boolean;
}

const TotalPriceDisplayRow = ({ total, isSelected, isForTeam }: IProps) => {
  return (
    <DisplayRowStyle
      isDiscount={false}
      isSelected={isSelected}
      isForTeam={isForTeam}
    >
      <p>{total}</p>
    </DisplayRowStyle>
  );
};

export default TotalPriceDisplayRow;
