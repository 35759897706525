import React from 'react';
import { WrappedInput } from './style';

interface IProps {
  value: string;
  handleChange?: (value: string) => void;
  maxLength?: number;
  isNumberOnly?: boolean;
  setToZero?: boolean;
  width?: string;
  isSimpleStyle?: boolean;
  style?: React.CSSProperties;
  isDisabled?: boolean;
}

const InputText: React.FC<IProps> = ({value = '', handleChange, maxLength, isNumberOnly, isSimpleStyle, width, style, isDisabled, setToZero}) => {

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    if (isNumberOnly && /\D/.test(val)) {
      return;
    }
    if (handleChange) {
      if (setToZero && isNumberOnly && !val) {
        handleChange('0');
      } else {
        handleChange(val);
      }
    }
  }
  
  return (
    <WrappedInput
      type='text'
      value={value}
      onChange={onChangeInput}
      maxLength={maxLength}
      width={width}
      isSimpleStyle={isSimpleStyle}
      disabled={isDisabled}
      style={{...style}}
    />
  );
}

export default InputText;