import React from 'react';
import {observer} from 'mobx-react-lite';
import store from '../../../store/rootStore';
import ColorPicker from '../../../components/ColorPicker/ColorPicker';
import StepDecorationPanelContent from './StepDecorationPanelContent';
import { ColorHex } from '../../../db/types';
import { EConfigGarment } from '../../../enums';
import { WrappedStepDecorationPanel } from './stepDecorationStyle';
import {getColorsData} from "../../../db/colors";

const StepDecorationPanel: React.FC = observer(() => {
  const {
    stepDecoration,
    currentGarment,
    getDecorationJerseyValue,
    getDecorationShortsValue,
    setDecorationJersey,
    setDecorationShorts,
    toggleTouchUI
  } = store.app;

  const getCurrentColor = (): ColorHex => {
    const field = stepDecoration.isStrokeColor ? 'strokeColor' : 'textColor';
    if (currentGarment === EConfigGarment.JERSEY) {
      return getDecorationJerseyValue(stepDecoration.currentJerseyChapter, field) as ColorHex;
    }
    return getDecorationShortsValue(stepDecoration.currentShortsChapter, field) as ColorHex;
  }

  const handlePickColor = (color: ColorHex) => {
    const field = stepDecoration.isStrokeColor ? 'strokeColor' : 'textColor';
    if (currentGarment === EConfigGarment.JERSEY) {
      setDecorationJersey(stepDecoration.currentJerseyChapter, field, color);
    } else {
      setDecorationShorts(stepDecoration.currentShortsChapter, field, color);
    }
    toggleTouchUI();
  }

  return (
    <WrappedStepDecorationPanel
      isColorPicker={stepDecoration.isColorPicker}
    >
      {
        stepDecoration.isColorPicker ?
        <>
          <div className="desktop">
            <ColorPicker
              handleClick={handlePickColor}
              currentColor={getCurrentColor()}
              data={getColorsData()}
              isCarousel
            />
          </div>

          <div className="mobile">
            <ColorPicker
              handleClick={handlePickColor}
              currentColor={getCurrentColor()}
              data={getColorsData()}
            />
          </div>
        </> :
        <StepDecorationPanelContent/>
      }
    </WrappedStepDecorationPanel>
  );
})

export default StepDecorationPanel;
