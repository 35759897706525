import React, { useEffect, useRef } from 'react';
import { EButtonOpenerTypes, EConfigGarment } from '../../enums';
import ButtonOpener from '../../components/UI/ButtonOpener/ButtonOpener';
import { WrappedLayoutMenuPanel } from './menuPanelStyle';
import Paginator from './Paginator';
import { observer } from 'mobx-react-lite';
import store from '../../store/rootStore';
import MenuSelector from '../../components/UI/MenuSelector/MenuSelector';
import MenuPanelSlider from './MenuPanelSlider';
import MySelect from '../../components/UI/Select/MySelect';
import Button from '../../components/UI/Buttons/Button';
import arrowLeft from '../../assets/UI/arrow_left.svg';
import { useTranslation } from 'react-i18next';
import { IButton } from '../../components/UI/SwitchButtons/SwitchButtons';
import { gsap } from 'gsap';
import { AnimOpenPanel } from '../../constants';
import Calculator from "../Caclulator";

interface IProps {
  headComponent: React.ReactNode;
  contentComponent: React.ReactNode;
  colorProps?: {
    options: IButton[];
    handleOptionClick: (option: string) => void;
  }
  decorationProps?: {
    chapters: IButton[];
    handleChapterClick: (option: string) => void;
  }
}

const LayoutMenuPanel: React.FC<IProps> = observer(({ headComponent, contentComponent, colorProps, decorationProps}) => {
  const {currentStep, isOpenPanel, setOpenPanel, stepDecoration, setIsColorPicker, isModelLoading, currentColorChapter, currentGarment} = store.app;
  const { t } = useTranslation();
  const animRef = useRef(null);
  const animRef2 = useRef(null);
  const stepsArr = t('steps', {returnObjects: true});

  useEffect(() => {
    if (isOpenPanel) {
      gsap.to(animRef.current, AnimOpenPanel)
      gsap.to(animRef2.current, AnimOpenPanel)
    }
  }, [isOpenPanel])

  const handleOpen = () => {
    setOpenPanel(!isOpenPanel);
  }

  const handleBack = () => {
    setIsColorPicker(false);
  }

  const renderHeadingColor = (): JSX.Element => {
    return <>
        <div className='heading__mobile'>
          <div className="heading__wrap">
            <MySelect
              options={colorProps?.options}
              onOptionClick={colorProps?.handleOptionClick}
              currentProp={currentColorChapter[currentGarment]}
              isRollsUp
            />
          </div>
        </div>
        <div className='buttons'>
          {headComponent}
        </div>
      </>
  }

  const renderHeadingDecoration = (): JSX.Element => {
    if (stepDecoration.isColorPicker) {
      return <>
        <div className='heading__mobile'>
          <div className="heading__back">
            <Button
              handleClick={handleBack}
            >
              <img src={arrowLeft} alt='arrow'/>
              <p>{t('ui.back')}</p>
            </Button>
          </div>
        </div>
        <div className='desktop'>
            <Button
              handleClick={handleBack}
            >
              <img src={arrowLeft} alt='arrow'/>
              <p>{t('ui.back')}</p>
            </Button>
        </div>
      </>
    } else {
      return <>
        <div className='heading__mobile'>
          <div className="heading__wrap">
            <MySelect
              options={decorationProps?.chapters}
              onOptionClick={decorationProps?.handleChapterClick}
              currentProp={currentGarment === EConfigGarment.JERSEY ? stepDecoration.currentJerseyChapter : stepDecoration.currentShortsChapter}
              isRollsUp
            />
          </div>
        </div>
        <div className="desktop">
          {headComponent}
        </div>
      </>
    }
  }

  const renderSliderContent = (): JSX.Element => {
    return <MenuPanelSlider
        isStepSport={currentStep === 0}
      >
        {contentComponent}
      </MenuPanelSlider>
  }

  const renderFade = (): JSX.Element | null => {
    switch (currentStep) {
      case 1:
      case 2:
      case 3: return <div className='fade'/>
      case 4: {
        if (stepDecoration.isColorPicker) {
          return <div className='fade'/>
        }
        return null;
      }
      default: return null;
    }
  }

  return (
    <WrappedLayoutMenuPanel
      isOpen={isOpenPanel}
      isStepSport={currentStep === 0}
      isStepOrder={currentStep === 5}
      isColorPicker={stepDecoration.isColorPicker}
    >
      <div className='head'>
        <div className='opener'>
          <ButtonOpener
            type={isOpenPanel ? EButtonOpenerTypes.ARROW_DOWN : EButtonOpenerTypes.ARROW_UP}
            handleClick={handleOpen}
          />
        </div>
          {
          isOpenPanel &&
            <div className='animation' ref={animRef}>
              {
                (currentStep === 5 ?
                headComponent :
                currentStep === 4 ?
                renderHeadingDecoration() :
                currentStep === 3 ?
                renderHeadingColor() :
                <div className='buttons'>
                  {headComponent}
                </div>)
              }
          </div>
          }
        <div className='menu'>
          <Calculator />
          <MenuSelector />
        </div>
      </div>
      {
        isOpenPanel &&
        <div className='animation animation--content' ref={animRef2}>
        {
          currentStep === 4 || currentStep === 5 ?
          contentComponent :
          renderSliderContent()
        }
        {
          renderFade()
        }
        </div>
      }
      <div className='paginator'>
        <Paginator
          name={stepsArr[currentStep]}
          totalPages={stepsArr.length}
          page={currentStep + 1}
          isModelLoading={isModelLoading}
        />
      </div>
    </WrappedLayoutMenuPanel>
  );
})

export default LayoutMenuPanel;
