import React, { useEffect, useState } from 'react';
import { WrappedInfo } from './popupStyle';
import imgInfo from '../../assets/UI/popup_info.svg';
import imgClose from '../../assets/UI/popup_close.svg';
import imgCloseHover from '../../assets/UI/popup_close_hover.svg';

interface IProps {
  modelName: string;
  heading: string | null;
  text: JSX.Element[];
  isOpen: boolean;
  handleOpen: (value: boolean) => void;
}

const Info: React.FC<IProps> = ({modelName, heading, text, isOpen, handleOpen}) => {
  const [myImg, setMyImg] = useState<string>(imgInfo);
  const [showContent, setShowContent] = useState<boolean>(false);

  const handleClick = () => {
    handleOpen(!isOpen);
  }

  const handleClickBg = () => {
    handleOpen(false);
  }

  const handleMouseOver = () => {
    if (isOpen) {
      setMyImg(imgCloseHover);
    }
  }

  const handleMouseOut = () => {
    if (isOpen) {
      setMyImg(imgClose);
    }
  }

  useEffect(() => {
    if (isOpen) {
      setMyImg(imgClose);
      setTimeout(() => {
        setShowContent(true);
      }, 200)
    } else {
      setMyImg(imgInfo);
      setShowContent(false);
    }
  }, [isOpen])

  return (
    <WrappedInfo 
      isOpen={isOpen} 
      onClick={handleClickBg}
    >
      <div 
        className="info"
        onClick={(e) => e.stopPropagation()}
      >
        {
          showContent && <>
            <h2 className="model_name">{modelName}</h2>
            {
              heading && <p className="heading">{heading}</p>
            }
            {
              text.map(item => item)
            }
          </>
        }
          <img 
            src={myImg} 
            alt='i'
            onClick={handleClick}
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
          />
      </div> 
    </WrappedInfo>
  );
}

export default Info;