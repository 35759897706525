import { maxKits } from "../../../constants";
import { defaultModels } from "../../../db/models";
import { EConfigGarment } from "../../../enums";
import appStore from '../../../store/appStore';

export const handleSetSport = (store: typeof appStore, sportId: string): boolean | undefined => {
  if (store.result.length >= maxKits) {
    return true;
  }

  store.setNeedModelRefresh(true);
  store.restoreDefaults();
  store.setSportId(sportId);
  store.setCurrentGarment(EConfigGarment.JERSEY);
  store.createNewKit(sportId);

  // prepare collection step for the first loading

  function hideSocks() {
    store.setHideSocks(true);
    store.setIncludeSocks(false);
  }
  
  let ids;
  switch (sportId) {
    case '0': {
      ids = defaultModels.football;
      break;
    }
    case '1': {
      ids = defaultModels.basketball;
      hideSocks();
      break;
    }
    case '2': {
      ids = defaultModels.handball;
      hideSocks();
      break;
    }
    case '3': {
      ids = defaultModels.racket;
      hideSocks();
      break;
    }
    case '4': {
      ids = defaultModels.rugby;
      break;
    }
    default: {
      ids = defaultModels.volleyball;
      hideSocks();
    }
  }

  store.setModelId(ids.jersey, EConfigGarment.JERSEY);
  store.setModelId(ids.shorts, EConfigGarment.SHORTS);
  store.setModelId('200', EConfigGarment.SOCKS);
  store.setCollectionId(ids.collectionJersey);

  store.setStep(1);
  store.setOpenPanel(true);
}
