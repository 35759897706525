import React, {ReactNode} from 'react';
import styled from "styled-components";
import {TextPadCost} from "../style";
import {convertPrice} from "../../../utils";
import {useTranslation} from "react-i18next";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 121px;
  
  > div {
    width: 121px;
  }
  
  @media (max-width: 768px) {
    width: 100%;

    > div {
      width: 100%;
      padding: 16px 24px;
    }
  }
`;

interface IProps {
  children: ReactNode;
  isInactive: boolean;
  price: number;
}

const PadColumn = ({ children, isInactive, price }: IProps) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      { children }
      {
        price > 0
        && <TextPadCost
            isInactive={isInactive}
          >
            {convertPrice(price)}
            <span> {t('calculator.per_unit')}</span>
          </TextPadCost>
      }
    </Wrapper>
  );
};

export default PadColumn;
