import styled from 'styled-components';

interface IViewportProps {
  isDevelopMode: boolean;
  isModelLoading: boolean;
  renderHeight: number;
}

export const WrappedViewport = styled.div<IViewportProps>`
  width: 100%;
  min-height: 600px;
  height: ${props => props.renderHeight ? props.renderHeight + 'px' : '50%'};
  padding: ${props => props.isDevelopMode ? '16px 150px' : 'unset'};
  border: ${props => props.isDevelopMode ? '1px dotted black' : 'unset'};
  margin: auto;
  font-style: normal;
  z-index: 500;
  position: relative;

  .data {
    pointer-events: none;
    z-index: 1;
    position: relative;
    display: flex;
    justify-content: space-between;

    .small_text {
      p {
        font-size: .7em;
      }
    }

    span {
      font-weight: bold;
    }

    .colors {
      display: flex;
      justify-content: flex-start;
      gap: 10px;

      p {
        margin: 0;
      }
    }

    p, span {
      color: gray;
    }
  }

  .dev_buttons {
    display: flex;
    gap: 10px;
    position: relative;
    z-index: 2000;

    .btn_texture {
      background: yellow;
    }

    .btn_overview {
      background: #7add7a;
    }
  }

  #render {
    position: absolute;
    top: -88px;
    left: 0;
    z-index: 0;

    canvas {
      visibility: ${props => props.isModelLoading ? 'hidden' : 'visible'};
    }
  }

  .spinner {
    position: absolute;
    top: -200px;
    left: 0;
    z-index: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    pointer-events: none;
    display: ${props => props.isModelLoading ? 'block' : 'none'};
  }

  @media all and (max-width: 768px) {
    min-height: 450px;
    position: absolute;
    margin: auto;
    z-index: 0;

    #render {
      top: -64px;
    }

    .data div {
      span, p, .small_text p, .small_text span {
        font-size: .7em;
      }
    }
  }
`

export const WrappedLayoutViewport = styled.div`
  width: 90%;
  margin: 0 auto;

  @media all and (max-width: 768px) {
    width: 100%;
  }
`
