import styled, { css } from 'styled-components';
import { BG_GREEN, BTN_BORDER_COLOR, BTN_TEXT_COLOR } from '../../../constants';
import { TLanguages } from '../../../store/types';

interface IProps {
  isStepOrder: boolean;
}

export const WrappedHeader = styled.div<IProps>`
  background: ${props => props.isStepOrder ? 'white' : 'transparent'};
  width: 100%;
  position: fixed;
  z-index: 1000;

  .container {
    margin: 0 auto;
    width: 1200px;
    max-width: 90%;
    height: 88px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    nav {
      display: flex;
      gap: 1em;
      
      p {
        font-family: Rubik, sans-serif;
        font-weight: bold;
        font-size: 15px;
        line-height: 18px;
        text-transform: uppercase;
      }
    }

    .burger {
      display: none;
    }
    
    .logo {
      line-height: 0;
    }

    .logo__desktop {
      display: block;
    }

    .logo__mobile {
      display: none;
      
      img {
        width: 60px;
      }
    }
  }

  @media all and (max-width: 768px) {
    .container {
      width: 90%;
      height: 64px;

      nav {
        display: none;
      }

      .burger {
        display: flex;
        gap: 16px;
      }

      .logo__desktop {
        display: none;
      }

      .logo__mobile {
        display: block;
      }
    }
  }
`

interface ILanguageProps {
  language: TLanguages;
}

const onButtonStyle = css`
  background: ${BG_GREEN};
  color: white;
`

const offButtonStyle = css`
  background: white;
  color: ${BTN_TEXT_COLOR};
`

export const WrappedLanguages = styled.div<ILanguageProps>`
  display: flex;
  gap: 10px;

  button {
    outline: none;
    border-radius: 4px;
    border: 1px solid ${BTN_BORDER_COLOR};
    cursor: pointer;
  }

  .en {
    ${props => props.language === 'en' ? onButtonStyle : offButtonStyle}
  }

  .fr {
    ${props => props.language === 'fr' ? onButtonStyle : offButtonStyle}
  }
`
