import React, { useState } from 'react';
import { configSport } from '../../../constants';
import CardMainMenu from '../../../components/Cards/CardMainMenu';
import { WrappedStepSport } from './sportStyle';
import { observer } from 'mobx-react-lite';
import store from '../../../store/rootStore';
import CardMenu from '../../../components/Cards/CardMenu';
import { handleSetSport } from './handleSetSport';
import { getSportId } from '../../../api/sportsApi';
import { useTranslation } from 'react-i18next';
import DialogWarning from '../../../components/Popups/DialogWarning';

const StepSport: React.FC = observer(() => {
  const { t } = useTranslation();
  const text: {[key: string]: string} = t('sports', {returnObjects: true});
  const [isMouseOver, setIsMouseOver] = useState<boolean>(false);
  const {isModelLoading, setDialogJSX, setOpenDialog, setStep} = store.app;

  const handleCardClick = (sport: string) => {
    if (isModelLoading) return;
    const max = handleSetSport(store.app, getSportId(sport));
    if (max) {
      setDialogJSX(
        <DialogWarning
          mainText={t('ui.warning')}
          describeText={t('warnings.max_kits')}
          handleWarning={() => {
            setStep(5);
            setOpenDialog(false);
          }}
        />
      );
      setOpenDialog(true);
    }
  }

  const handleMouseOver = () => {
    if (isModelLoading) return;
    setIsMouseOver(true);
  }

  return (
    <WrappedStepSport>
      <div className="cards">
        {
          Object.keys(configSport).map((sport, index) => <CardMainMenu
            key={index}
            imgPath={configSport[sport].imgPath}
            text={text[sport]}
            handleClick={() => handleCardClick(sport)}
            handleMouseOver={handleMouseOver}
            handleMouseOut={() => setIsMouseOver(false)}
            isMouseOver={isMouseOver}
            isActive={!isModelLoading}
          />)
        }
      </div>
      <div className="cards__mobile">
        {
          Object.keys(configSport).map((sport, index) => <CardMenu
            key={index}
            iconPath={configSport[sport].iconPath}
            iconSelectedPath={configSport[sport].iconSelectedPath}
            text={text[sport]}
            handleClick={() => handleCardClick(sport)}
            isActive={!isModelLoading}
          />)
        }
      </div>
    </WrappedStepSport>
  );
})

export default StepSport;
