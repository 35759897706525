import React, { useState } from 'react';
import { WrappedCardMenu } from './cardsStyle';

interface IProps {
  iconPath: string;
  iconSelectedPath: string;
  text: string;
  handleClick?: () => void;
  isActive: boolean;
}

const CardMenu: React.FC<IProps> = ({iconPath, iconSelectedPath, text, handleClick, isActive}) => {
  const [icon, setIcon] = useState<string>(iconPath);

  const handleMouseOver = () => {
    if (!isActive) return;
    setIcon(iconSelectedPath);
  }

  return (
    <WrappedCardMenu
      onClick={handleClick}
      onMouseOver={handleMouseOver}
      onMouseOut={() => setIcon(iconPath)}
      isActive={isActive}
    >
      <img src={icon} alt='icon'/>
      <p>{text}</p>
    </WrappedCardMenu>
  );
}

export default CardMenu;