import {makeAutoObservable} from "mobx";
import {APP_VERSION, LANGUAGE_DEFAULT, maxKits, sizes} from "../constants";
import {EConfigColorSchemes, EConfigDesigns, EConfigGarment,} from "../enums";
import {
  ICollectData,
  IColorChapters,
  IGetIndices,
  IIndividualConfig,
  IJerseyParams,
  IModelScreenshot,
  IOrderFormation,
  IPicJersey,
  IPicShortsLogo,
  IPicShortsSponsor,
  IPicSize,
  IShortsParams,
  IStepCollection,
  IStepColor,
  IStepDecoration,
  IStyles,
  ITextJersey,
  ITextShorts,
  TLanguages
} from "./types";
import {
  getKitGarmentsQuantity,
  getModels,
  getModelsCollectionId,
} from "../api/modelsApi";
import {
  getCatalog,
  getModelCatalog,
  TCatalogTypes,
} from "../api/catalogApi";
import {getCatalogType, getKitSum, getKitSumDiscount, getModelGender, getPrice,} from "../api/catalogApi";
import {ColorHex, IColorScheme, TGender} from "../db/types";
import ImgSpinner from '../assets/UI/spinner.gif';
import {
  colorChaptersDefaults,
  colorSchemeDefault,
  colorSchemeDefault2,
  colorSchemeDefaultMishel,
  colorSchemeDefaultRod,
  colorSchemeDefaultTony,
  stepCollectionDefaults,
  stepColorDefaults,
  stepDecorationDefaults
} from "./defaults";
import {cloneDeep} from 'lodash';
import {getPositionCoords} from "../api/positionsApi";
import {colors} from "../db/colors";
import {getCollectionIdByModelId} from "../api/collectionsApi";

/*
currentStep:
0 - sport
1 - collection
2 - design
3 - color
4 - decoration
5 - order formation
*/

class AppStore {
  // development
  isDevelopMode: boolean = false;

  // model
  isNeedModelRefresh: boolean = true; // viewport component have to re-initialize if user went directly from steps without the viewport
  isModelLoading: boolean = false;

  // ui
  isOpenPanel: boolean = false;
  currentStep: number = 0;
  currentGarment: EConfigGarment = EConfigGarment.JERSEY;
  currentColorChapter: IColorChapters = colorChaptersDefaults;
  isOpenDialog: boolean = false;
  dialogJSX: JSX.Element = <></>;
  touchUI: boolean = false; // checking UI touch for the debounce function
  language: TLanguages = LANGUAGE_DEFAULT;
  showUploadImageHint: boolean = true; // step Decoration, tab with 'Upload Image' button. The hint must be shown one time
  hideSocks: boolean = false; // socks are available only for football (0) and rugby (4)
  calculatorKitsCount: number = 10;

  // completion
  public includeJersey: boolean = true;

  public includeShorts: boolean = false;

  public includeSocks: boolean = false;

  // steps
  stepSportId: string | null = null;
  stepCollection: IStepCollection = stepCollectionDefaults;
  stepDesignName: EConfigDesigns = EConfigDesigns.DEFAULT;
  stepColor: IStepColor = stepColorDefaults;
  stepDecoration: IStepDecoration = stepDecorationDefaults;

  // order formation table
  kitsCount: number = 1; // kits naming, increment number
  currentKitId: string = '0';
  result: IOrderFormation[] = [];
  isGeneralQuantity: boolean = true;

  // Screenshot
  modelScreenshot: IModelScreenshot = {};


  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  // developer methods

  toggleDevMode = () => {
    this.isDevelopMode = !this.isDevelopMode;
  }

  // model methods

  setNeedModelRefresh = (value: boolean) => {
    this.isNeedModelRefresh = value;
  }

  setModelLoading = (value: boolean) => {
    this.isModelLoading = value;
  }

  // UI methods

  setNextStep = (totalPages: number) => {
    if (this.currentStep < totalPages - 1) {
      this.currentStep += 1;
    }
  }

  setPreviousStep = () => {
    if (this.currentStep > 0) {
      this.currentStep -= 1;
    }
  }

  setStep = (num: number) => {
    this.currentStep = num;
  }

  setOpenPanel = (open: boolean) => {
    this.isOpenPanel = open;
  }

  setOpenDialog = (open: boolean) => {
    this.isOpenDialog = open;
    if (!open) {
      this.dialogJSX = <></>;
      document.querySelector('body')?.classList.remove('scroll-hidden');
    } else {
      document.querySelector('body')?.classList.add('scroll-hidden');
    }
  }

  setDialogJSX = (jsx: JSX.Element) => {
    this.dialogJSX = jsx;
  }

  setCurrentGarment = (garment: EConfigGarment) => {
    this.currentGarment = garment;
    const newCollectionId = getModelsCollectionId(this.stepCollection[garment]);
    this.setCollectionId(newCollectionId);
  }

  toggleTouchUI = () => {
    this.touchUI = !this.touchUI;
  }

  setLanguage = (value: TLanguages) => {
    this.language = value;
  }

  setShowUploadImageHint = (value: boolean) => {
    this.showUploadImageHint = value;
  }

  setHideSocks = (value: boolean) => {
    this.hideSocks = value;
  }

  setCalculatorKitsCount = (value: number) => {
    this.calculatorKitsCount = value;
  }

  // completion methods

  setIncludeJersey = (value: boolean) => {
    this.includeJersey = value;
  }

  setIncludeShorts = (value: boolean) => {
    this.includeShorts = value;
  }

  setIncludeSocks = (value: boolean) => {
    this.includeSocks = value;
  }

  isQuantityEnough = (includeJersey: boolean, includeShorts: boolean, includeSocks: boolean): boolean => {
    const qty = (includeJersey ? 1 : 0) + (includeShorts ? 1 : 0) + (includeSocks ? 1 : 0);
    return qty >= 2;
  }

  // steps methods
  // SPORT

  setSportId = (id: string) => {
    this.stepSportId = id;
  }

  // COLLECTION

  setModelList = (sportId?: string) => {
    const mySportId = sportId || this.stepSportId || '0';
    const modelsList = getModels(mySportId, this.currentGarment, this.stepCollection.collectionId);
    this.stepCollection.modelsList = modelsList;
  }

  setCollectionId = (id: string | null) => {
    this.stepCollection.collectionId = id;
    this.setModelList();
    const list = this.stepCollection.modelsList;
    if (list.length) {
      // if nothing is selected, then select first model in the list automatically
      const selectedModelId = this.stepCollection[this.currentGarment];
      const check = list.some(item => item.id === selectedModelId);
      if (!check) {
        const modelId = list[0].id;
        const garment = list[0].garment;
        this.setModelId(modelId, garment);
      }
    }
  }

  setModelId = (id: string, garment: EConfigGarment) => {
    this.stepCollection[garment] = id;
    this.stepCollection.gender[garment] = getModelGender(id);
  }

  // DESIGN

  setCurrentDesign = (design: EConfigDesigns) => {
    this.stepDesignName = design;

    if (this.currentGarment !== EConfigGarment.JERSEY) {
      return;
    }

    // если в цветовых схемах отсутствует данный дизайн, то добавить его
    const index = this.stepColor.jersey.findIndex((k) => k.design === design);
    if (index > -1) {
      return;
    }

    switch (design) {
      case EConfigDesigns.ALLEN:
      case EConfigDesigns.SEBASTIEN: {
        this.stepColor.jersey.push({
          design,
          scheme: colorSchemeDefault2,
        });
        break;
      }
      case EConfigDesigns.MICHEL: {
        this.stepColor.jersey.push({
          design,
          scheme: colorSchemeDefaultMishel,
        });
        break;
      }
      case EConfigDesigns.ROD: {
        this.stepColor.jersey.push({
          design,
          scheme: colorSchemeDefaultRod,
        });
        break;
      }
      case EConfigDesigns.TONY: {
        this.stepColor.jersey.push({
          design,
          scheme: colorSchemeDefaultTony,
        });
        break;
      }
      default: {
        this.stepColor.jersey.push({
          design,
          scheme: colorSchemeDefault,
        });
      }
    }
  }

  // COLOR

  setColor = (color: ColorHex) => {
    //console.log('set hex color = ', color);
    const chapter = this.currentColorChapter[this.currentGarment];

    if (this.currentGarment === EConfigGarment.JERSEY) {
      const index = this.stepColor.jersey.findIndex((k) => k.design === this.stepDesignName);
      this.stepColor.jersey[index].scheme[chapter as keyof IColorScheme] = color;
    } else {
      this.stepColor[this.currentGarment][chapter as keyof IColorScheme] = color;
    }

    // if color = white and garment = socks, then set the socks logo as black
    if (this.currentGarment === EConfigGarment.SOCKS) {
      if (color === colors.white) {
        this.stepColor.socks.logo = colors.black;
      } else {
        this.stepColor.socks.logo = colors.white;
      }
    }
  }

  setShortsDefaultColors = () => {
    this.stepColor.shorts = colorSchemeDefault;
  }

  setColorChapter = (chapter: EConfigColorSchemes) => {
    this.currentColorChapter[this.currentGarment] = chapter;
  }

  // DECORATION

  setCurrentJerseyChapter = (chapter: string) => {
    this.stepDecoration.currentJerseyChapter = chapter;
  }

  setCurrentShortsChapter = (chapter: string) => {
    this.stepDecoration.currentShortsChapter = chapter;
  }

  setCurrentJerseyCategory = (category: string) => {
    this.stepDecoration.currentJerseyCategory = category;
  }

  setCurrentShortsCategory = (category: string) => {
    this.stepDecoration.currentShortsCategory = category;
  }

  setIsColorPicker = (value: boolean) => {
    this.stepDecoration.isColorPicker = value;
  }

  setIsStrokeColor = (value: boolean) => {
    this.stepDecoration.isStrokeColor = value;
  }

  setDecorationJersey = (chapter: string, field: string, value: any) => {
    this.stepDecoration.jersey[chapter as keyof IJerseyParams][field as keyof (IPicJersey | ITextJersey)] = value;
  }

  setDecorationShorts = (chapter: string, field: string, value: any) => {
    this.stepDecoration.shorts[chapter as keyof IShortsParams][field as keyof (IPicShortsLogo | IPicShortsSponsor | ITextShorts)] = value;
  }

  getDecorationJerseyValue = (chapter: string, field: string): number | string | ColorHex | IPicSize => {
    return this.stepDecoration.jersey[chapter as keyof IJerseyParams][field as keyof (IPicJersey | ITextJersey)];
  }

  getDecorationShortsValue = (chapter: string, field: string): number | string | ColorHex | IPicSize => {
    return this.stepDecoration.shorts[chapter as keyof IShortsParams][field as keyof (IPicShortsLogo | IPicShortsSponsor | ITextShorts)];
  }

  isSkipNumber = (): boolean => {
    const file1 = this.stepDecoration.shorts.sponsorLogo1.imgFileName;
    const file2 = this.stepDecoration.shorts.sponsorLogo2.imgFileName;
    const file3 = this.stepDecoration.shorts.sponsorLogo3.imgFileName;
    const position1 = this.stepDecoration.shorts.sponsorLogo1.position;
    const position2 = this.stepDecoration.shorts.sponsorLogo2.position;
    const position3 = this.stepDecoration.shorts.sponsorLogo3.position;
    if (
      (file1 && position1 === 'front_2') ||
      (file2 && position2 === 'front_2') ||
      (file3 && position3 === 'front_2')
      ) {
        return true;
      }
      return false;
  }

  isJerseySponsorIntersect = (chapter: string, position: string | number): boolean => {
    if (
      chapter !== 'sponsorLogo1' &&
      chapter !== 'sponsorLogo2' &&
      chapter !== 'sponsorLogo3'
      ) {
        return false;
      }
    const file1 = this.stepDecoration.jersey.sponsorLogo1.imgFileName;
    const file2 = this.stepDecoration.jersey.sponsorLogo2.imgFileName;
    const file3 = this.stepDecoration.jersey.sponsorLogo3.imgFileName;
    if (
      (chapter === 'sponsorLogo1' && !file1) ||
      (chapter === 'sponsorLogo2' && !file2) ||
      (chapter === 'sponsorLogo3' && !file3)
      ) {
        return false;
    }
    const pos = [
      // find out what positions with uploaded files are in other chapters
      chapter === 'sponsorLogo1' ? 0 : (file1 ? this.stepDecoration.jersey.sponsorLogo1.position : 0),
      chapter === 'sponsorLogo2' ? 0 : (file2 ? this.stepDecoration.jersey.sponsorLogo2.position : 0),
      chapter === 'sponsorLogo3' ? 0 : (file3 ? this.stepDecoration.jersey.sponsorLogo3.position : 0),
    ];
    for (let i = 0; i < pos.length; i++) {
      const reg1 = new RegExp(pos[i] as string);
      const reg2 = new RegExp(String(position));
      if (reg1.test(String(position)) || reg2.test(pos[i] as string)) {
        return true;
      }
    }
    return false;
  }

  isShortsSponsorIntersect = (chapter: string, position: string | number): boolean => {
    if (
      chapter !== 'sponsorLogo1' &&
      chapter !== 'sponsorLogo2' &&
      chapter !== 'sponsorLogo3'
      ) {
        return false;
      }
    const file1 = this.stepDecoration.shorts.sponsorLogo1.imgFileName;
    const file2 = this.stepDecoration.shorts.sponsorLogo2.imgFileName;
    const file3 = this.stepDecoration.shorts.sponsorLogo3.imgFileName;
    if (
      (chapter === 'sponsorLogo1' && !file1) ||
      (chapter === 'sponsorLogo2' && !file2) ||
      (chapter === 'sponsorLogo3' && !file3)
      ) {
        return false;
    }
    const pos = [
      // find out what positions with uploaded files are in other chapters
      chapter === 'sponsorLogo1' ? 0 : (file1 ? this.stepDecoration.shorts.sponsorLogo1.position : 0),
      chapter === 'sponsorLogo2' ? 0 : (file2 ? this.stepDecoration.shorts.sponsorLogo2.position : 0),
      chapter === 'sponsorLogo3' ? 0 : (file3 ? this.stepDecoration.shorts.sponsorLogo3.position : 0),
    ];
    for (let i = 0; i < pos.length; i++) {
      const reg1 = new RegExp(pos[i] as string);
      const reg2 = new RegExp(String(position));
      if (reg1.test(String(position)) || reg2.test(pos[i] as string)) {
        return true;
      }
    }
    return false;
  }

  getStyles = (): IStyles => {
    const jersey = this.stepDecoration.jersey;
    const shorts = this.stepDecoration.shorts;

    return {
      jersey: {
        lastName: jersey.lastName,
        number: jersey.number,
        numberPosition: getPositionCoords(
          this.stepCollection.jersey,
          'number',
          jersey.number.position,
          EConfigGarment.JERSEY
        ),
        lastNamePosition: getPositionCoords(
          this.stepCollection.jersey,
          'lastName',
          jersey.lastName.position,
          EConfigGarment.JERSEY
        ),
        logoPosition: getPositionCoords(
          this.stepCollection.jersey,
          'teamLogo',
          jersey.teamLogo.position,
          EConfigGarment.JERSEY,
          jersey.teamLogo.picSize,
        ),
        sponsor1Position: getPositionCoords(
          this.stepCollection.jersey,
          'sponsorLogo1',
          jersey.sponsorLogo1.position,
          EConfigGarment.JERSEY,
          jersey.sponsorLogo1.picSize,
        ),
        sponsor2Position: getPositionCoords(
          this.stepCollection.jersey,
          'sponsorLogo2',
          jersey.sponsorLogo2.position,
          EConfigGarment.JERSEY,
          jersey.sponsorLogo2.picSize,
        ),
        sponsor3Position: getPositionCoords(
          this.stepCollection.jersey,
          'sponsorLogo3',
          jersey.sponsorLogo3.position,
          EConfigGarment.JERSEY,
          jersey.sponsorLogo3.picSize,
        ),
        layout: {
          logo: jersey.teamLogo.imgPath,
          sponsor1: jersey.sponsorLogo1.imgPath,
          sponsor2: jersey.sponsorLogo2.imgPath,
          sponsor3: jersey.sponsorLogo3.imgPath,
          logoSizes: {
            w: jersey.teamLogo.baseWidth,
            h: jersey.teamLogo.baseHeight,
          },
          sponsor1Sizes: {
            w: jersey.sponsorLogo1.baseWidth,
            h: jersey.sponsorLogo1.baseHeight,
          },
          sponsor2Sizes: {
            w: jersey.sponsorLogo2.baseWidth,
            h: jersey.sponsorLogo2.baseHeight,
          },
          sponsor3Sizes: {
            w: jersey.sponsorLogo3.baseWidth,
            h: jersey.sponsorLogo3.baseHeight,
          },
        }
      },
      shorts: {
        number: shorts.number,
        numberPosition: getPositionCoords(
          this.stepCollection.shorts,
          'number',
          shorts.number.position,
          EConfigGarment.SHORTS
        ),
        logoPosition: getPositionCoords(
          this.stepCollection.shorts,
          'teamLogo',
          shorts.teamLogo.position,
          EConfigGarment.SHORTS,
          shorts.teamLogo.picSize,
        ),
        sponsor1Position: getPositionCoords(
          this.stepCollection.shorts,
          'sponsorLogo1',
          shorts.sponsorLogo1.position,
          EConfigGarment.SHORTS,
          shorts.sponsorLogo1.picSize,
        ),
        sponsor2Position: getPositionCoords(
          this.stepCollection.shorts,
          'sponsorLogo2',
          shorts.sponsorLogo2.position,
          EConfigGarment.SHORTS,
          shorts.sponsorLogo2.picSize,
        ),
        sponsor3Position: getPositionCoords(
          this.stepCollection.shorts,
          'sponsorLogo3',
          shorts.sponsorLogo3.position,
          EConfigGarment.SHORTS,
          shorts.sponsorLogo3.picSize,
        ),
        layout: {
          sponsor1: shorts.sponsorLogo1.imgPath,
          sponsor2: shorts.sponsorLogo2.imgPath,
          sponsor3: shorts.sponsorLogo3.imgPath,
          sponsor1Sizes: {
            w: shorts.sponsorLogo1.baseWidth,
            h: shorts.sponsorLogo1.baseHeight,
          },
          sponsor2Sizes: {
            w: shorts.sponsorLogo2.baseWidth,
            h: shorts.sponsorLogo2.baseHeight,
          },
          sponsor3Sizes: {
            w: shorts.sponsorLogo3.baseWidth,
            h: shorts.sponsorLogo3.baseHeight,
          },
        }
      },
    }
  }

  // ORDER FORMATION

  getUnmountActualValues = (): {
    currentStep: number;
    stepDesignName: EConfigDesigns;
    jerseyModelId: string;
    shortsModelId: string;
    socksModelId: string;
    includeJersey: boolean;
    includeShorts: boolean;
    includeSocks: boolean;
    currentKitId: string;
  } => {
    return {
      currentStep: this.currentStep,
      stepDesignName: this.stepDesignName,
      jerseyModelId: this.stepCollection.jersey,
      shortsModelId: this.stepCollection.shorts,
      socksModelId: this.stepCollection.socks,
      includeJersey: this.includeJersey,
      includeShorts: this.includeShorts,
      includeSocks: this.includeSocks,
      currentKitId: this.currentKitId,
    }
  }

  toggleGeneralQuantity = () => {
    this.isGeneralQuantity = !this.isGeneralQuantity;
  }

  createNewKit = (sportId: string) => {
    const id = String(Date.now());
    const newKit: IOrderFormation = {
      id,
      name: 'Kit No.' + this.kitsCount,
      sportId,
      selectedPlayerId: '0',
      isWomanOnly: false,
      hideSocks: false,
      modelConfig: {
        stepCollection: stepCollectionDefaults,
        modelsScreenshot: {},
        modelImagesPath: {
          jersey: '',
          shorts: '',
          socks: '',
        },
        designName: EConfigDesigns.DEFAULT,
        colorSchemes: stepColorDefaults,
        stepDecoration: stepDecorationDefaults,
      },
      teamConfig: [{
        id: '0',
        selectedGarment: null,
        lastName: '',
        number: '',
        gender: 'm',
        quantityKits: 1,
        garments: [
          {
            catalogId: '',
            isInclude: true,
            garment: EConfigGarment.JERSEY,
            size: 'ad_m',
            catalog: '',
            quantityGarments: 1,
            price: 50,
          },
          {
            catalogId: '',
            isInclude: true,
            garment: EConfigGarment.SHORTS,
            size: 'ad_m',
            catalog: '',
            quantityGarments: 1,
            price: 50,
          },
          {
            catalogId: '',
            isInclude: true,
            garment: EConfigGarment.SOCKS,
            size: 'sock_m',
            catalog: '',
            quantityGarments: 1,
            price: 10,
          },
        ],
      }],
      sum: 110,
      isDone: false,
    }
    this.kitsCount = this.kitsCount + 1;
    this.currentKitId = id;
    this.result.push(newKit);
    //console.log('created new kit: ', newKit);
  }

  copyKit = (kitId: string): boolean | undefined => {
    //console.log('copyKit ' + kitId);
    if (this.result.length >= maxKits) {
      return true;
    }
    const copy: IOrderFormation | undefined = this.result.find(kit => kit.id === kitId);
    if (copy) {
      const id = String(Date.now());
      const newKit = {
        ...cloneDeep(copy),
        id,
        name: 'Kit No.' + this.kitsCount,
      }
      this.kitsCount = this.kitsCount + 1;
      this.result.push(newKit);
      this.currentKitId = id;
      //console.log('created new kit: ', newKit);
    } else {
      //console.log('copyKit error');
    }
  }

  getUndoneKitId = (): string | null => {
    const found = this.result.find(item => !item.isDone);
    if (found) {
      return found.id;
    }
    return null;
  }

  setKitDone = (kitId?: string) => {
    const id = kitId || this.currentKitId;
    const index = this.result.findIndex(item => item.id === id);
    this.result[index].isDone = true;
  }

  continueEditKit = (kitId: string) => {
    const kit: IOrderFormation | undefined = this.result.find(kit => kit.id === kitId);
    if (kit) {
      this.setNeedModelRefresh(true);
      this.setCurrentGarment(EConfigGarment.JERSEY);
      this.putDataFromResultToState(kit.id, '0');
      this.setCollectionId(kit.modelConfig.stepCollection.collectionId);
      this.setStep(1);
      this.setOpenPanel(true);
    }
  }

  deleteKit = (kitId: string) => {
    //console.log('deleteKit ' + kitId);
    this.result = this.result.filter(kit => kit.id !== kitId);
    this.currentKitId = this.result[0]?.id || '0';
  }

  deletePlayer = (kitId: string, playerId: string | undefined) => {
    //console.log('deletePlayer ' + playerId + ' from kit ' + kitId);
    const indices = this.getIndices(kitId);
    const newPlayersList = this.result[indices.kit].teamConfig.filter(player => player.id !== playerId);
    this.result[indices.kit].teamConfig = newPlayersList;
    this.result[indices.kit].selectedPlayerId = this.result[indices.kit].teamConfig[0]?.id || '0';
    this.updateKitSum(indices.kit);
  }

  addNewPlayer = (kitId: string) => {
    const indices = this.getIndices(kitId);
    const id = String(Date.now());
    const kit = this.result[indices.kit];
    const jerseyCollectionId = getCollectionIdByModelId(kit.modelConfig.stepCollection.jersey);
    const shortsCollectionId = getCollectionIdByModelId(kit.modelConfig.stepCollection.shorts);
    const catalogJersey = getCatalog(kit.sportId, EConfigGarment.JERSEY, jerseyCollectionId);
    const catalogShorts = getCatalog(kit.sportId, EConfigGarment.SHORTS, shortsCollectionId);
    const catalogSocks = getCatalog(kit.sportId, EConfigGarment.SOCKS, null);
    const catalogType: TCatalogTypes = kit.isWomanOnly ? 'women' : 'men';
    let includeSocks = this.includeSocks;
    if (kit.sportId !== '0' && kit.sportId !== '4') {
      includeSocks = false;
    }
    const quantity = getKitGarmentsQuantity(this.result[indices.kit].teamConfig);

    const newPlayer: IIndividualConfig = {
      id,
      selectedGarment: null,
      lastName: '',
      number: '',
      gender: kit.isWomanOnly ? 'w' : 'm',
      quantityKits: 1,
      garments: [
        {
          catalogId: catalogJersey?.infoId || '',
          isInclude: this.includeJersey,
          garment: EConfigGarment.JERSEY,
          size: 'ad_m',
          catalog: (catalogJersey && getModelCatalog(catalogJersey, catalogType)) || '',
          quantityGarments: 1,
          price: getPrice(kit.sportId, EConfigGarment.JERSEY, quantity.jerseyQuantity + 1) || 0,
        },
        {
          catalogId: catalogShorts?.infoId || '',
          isInclude: this.includeShorts,
          garment: EConfigGarment.SHORTS,
          size: 'ad_m',
          catalog: (catalogShorts && getModelCatalog(catalogShorts, catalogType)) || '',
          quantityGarments: 1,
          price: getPrice(kit.sportId, EConfigGarment.SHORTS, quantity.shortsQuantity + 1) || 0,
        },
        {
          catalogId: catalogSocks?.infoId || '',
          isInclude: includeSocks,
          garment: EConfigGarment.SOCKS,
          size: 'sock_m',
          catalog: (catalogSocks && getModelCatalog(catalogSocks, catalogType)) || '',
          quantityGarments: 1,
          price: getPrice(kit.sportId, EConfigGarment.SOCKS, quantity.socksQuantity + 1) || 0,
        },
      ],
    }
    this.result[indices.kit].teamConfig.push(newPlayer);
    this.result[indices.kit].selectedPlayerId = id;
    //console.log('NEW PLAYER', newPlayer);
    this.updateGarmentsPrices(kitId, id);
    this.updateKitSum(indices.kit);
  }

  updateKitName = (kitId: string, value: string) => {
    const indices = this.getIndices(kitId);
    this.result[indices.kit].name = value;
  }

  updateKitSum = (kitIndex: number) => {
    const sum = getKitSum(this.result[kitIndex].teamConfig);
    this.result[kitIndex].sum = sum;
  }

  /**
   * when moving to step 6, transfers the state values to the result
   */

  putDataFromStateToResult = () => {
    //console.log('putDataFromStateToResult');

    const kit: IOrderFormation | undefined = this.result.find(kit => kit.id === this.currentKitId);
    if (!kit) {
      //console.log('kit not found');
      return;
    }

    const jerseyCollectionId = getCollectionIdByModelId(this.stepCollection.jersey);
    const shortsCollectionId = getCollectionIdByModelId(this.stepCollection.shorts);
    const catalogJersey = getCatalog(this.stepSportId, EConfigGarment.JERSEY, jerseyCollectionId);
    const catalogShorts = getCatalog(this.stepSportId, EConfigGarment.SHORTS, shortsCollectionId);
    const catalogSocks = getCatalog(this.stepSportId, EConfigGarment.SOCKS, null);
    const isWomanOnly = this.stepCollection.gender.jersey === 'w' ||
    this.stepCollection.gender.shorts === 'w' ||
    this.stepCollection.gender.socks === 'w';
    const catalogType: TCatalogTypes = isWomanOnly ? 'women' : 'men';
    const includeList = [
      this.includeJersey && EConfigGarment.JERSEY,
      this.includeShorts && EConfigGarment.SHORTS,
      this.includeSocks && !this.hideSocks && EConfigGarment.SOCKS,
    ];

    const extractCatalogId = (garment: EConfigGarment): string => {
      if (garment === EConfigGarment.JERSEY) {
        return catalogJersey?.infoId || '';
      }
      if (garment === EConfigGarment.SHORTS) {
        return catalogShorts?.infoId || '';
      }
      return catalogSocks?.infoId || '';
    }

    const extractCatalog = (garment: EConfigGarment, isKids: boolean): string => {
      const catType = isKids ? 'kids' : catalogType;
      if (garment === EConfigGarment.JERSEY) {
        return (catalogJersey && getModelCatalog(catalogJersey, catType)) || '';
      }
      if (garment === EConfigGarment.SHORTS) {
        return (catalogShorts && getModelCatalog(catalogShorts, catType)) || '';
      }
      return (catalogSocks && getModelCatalog(catalogSocks, catType)) || '';
    }

    const extractPrice = (garment: EConfigGarment): number => {
      // original price, without discounts
      if (garment === EConfigGarment.JERSEY) {
        return getPrice(kit.sportId, EConfigGarment.JERSEY, 1) || 0;
      }
      if (garment === EConfigGarment.SHORTS) {
        return getPrice(kit.sportId, EConfigGarment.SHORTS, 1) || 0;
      }
      return getPrice(kit.sportId, EConfigGarment.SOCKS, 1) || 0;
    }

    const teamConfig: IIndividualConfig[] = kit.teamConfig.map(player => {
      if (player.id === kit.selectedPlayerId) {
        const update: IIndividualConfig = {
          ...cloneDeep(player),
          lastName: this.stepDecoration.jersey.lastName.text,
          number: this.stepDecoration.jersey.number.text,
          garments: player.garments.map(cloth => {
            const isKids = sizes.children.some(item => item.name === cloth.size);
            const catalogId = extractCatalogId(cloth.garment);
            const catalog = extractCatalog(cloth.garment, isKids);
            const price = extractPrice(cloth.garment);
            return {
              ...cloneDeep(cloth),
              isInclude: includeList.includes(cloth.garment),
              catalogId,
              catalog,
              price,
            }
          }),
        }
        return update;
      } else {
        return player;
      }
    })

    const updatedKit: IOrderFormation = {
      ...cloneDeep(kit),
      isWomanOnly,
      hideSocks: this.hideSocks,
      modelConfig: {
        stepCollection: this.stepCollection,
        modelsScreenshot: this.modelScreenshot,
        modelImagesPath: {
          jersey: ImgSpinner,
          shorts: ImgSpinner,
          socks: ImgSpinner,
        },
        designName: this.stepDesignName,
        colorSchemes: this.stepColor,
        stepDecoration: this.stepDecoration
      },
      teamConfig,
      sum: getKitSum(teamConfig),
    }

    this.result = this.result.map(k => {
      if (k.id === this.currentKitId) {
        return updatedKit;
      } else {
        return k;
      }
    })
  }

  putScreenshotToResult = (kitId: string, garment: EConfigGarment, imgPath: string) => {
    const indices = this.getIndices(kitId);
    this.result[indices.kit].modelConfig.modelImagesPath[garment] = imgPath;
  }

  /**
   * when clicking 'edit kit', transfers the result values to the state
   * @param kitId
   * @param playerId
   */

  putDataFromResultToState = (kitId: string, playerId: string) => {
    //console.log('putDataFromResultToState');

    const kit: IOrderFormation | undefined = this.result.find(kit => kit.id === kitId);
    if (!kit) {
      //console.log('kit not found');
      return;
    }

    const player: IIndividualConfig | undefined = kit.teamConfig.find(player => player.id === playerId);
    if (!player) {
      //console.log('player not found');
      return;
    }

    this.currentKitId = kitId;
    this.includeJersey = player.garments[0].isInclude;
    this.includeShorts = player.garments[1].isInclude;
    this.includeSocks = player.garments[2].isInclude;
    this.hideSocks = kit.hideSocks;
    this.stepSportId = kit.sportId;
    this.stepCollection = kit.modelConfig.stepCollection;
    this.stepDesignName = kit.modelConfig.designName;
    this.stepColor = kit.modelConfig.colorSchemes;

    const decoration: IStepDecoration = {
      ...cloneDeep(kit.modelConfig.stepDecoration),
    }
    decoration.jersey.lastName.text = player.lastName;
    decoration.jersey.number.text = player.number;
    decoration.isColorPicker = false;
    this.stepDecoration = decoration;
  }

  getAllKitsSum = () => {
    return this.result.reduce((sum, current) => {
      return sum += current.sum;
    }, 0)
  }

  getAllKitsDiscountSum = () => {
    return this.result.reduce((sum, current) => {
      const kitSum = getKitSumDiscount(current.teamConfig, current.sportId);
      return sum += kitSum;
    }, 0)
  }

  getPlayer = (kitId: string, playerId: string): IIndividualConfig | undefined => {
    const team = this.result.find(kit => kit.id === kitId)?.teamConfig;
    if (team) {
      return team.find((player) => player.id === playerId);
    }
  }

  getIndices = (kitId: string, playerId?: string, garment?: EConfigGarment): IGetIndices => {
    const indices: IGetIndices = {
      kit: this.result.findIndex(kit => kit.id === kitId),
    };
    if (playerId) {
      indices.player = this.result[indices.kit].teamConfig.findIndex(player => player.id === playerId);
      if (garment) {
        indices.garment = this.result[indices.kit].teamConfig[indices.player].garments.findIndex(item => item.garment === garment);
      }
    }
    return indices;
  }

  setSelectedPlayerId = (kitId: string, newId: string) => {
    const indices = this.getIndices(kitId);
    this.result[indices.kit].selectedPlayerId = newId;
  }

  setOrderGarmentLastName = (kitId: string, playerId: string | undefined, value: string) => {
    const indices = this.getIndices(kitId, playerId);
    this.result[indices.kit].teamConfig[indices.player ?? -1].lastName = value;
  }

  setOrderGarmentNumber = (kitId: string, playerId: string | undefined, value: string) => {
    const indices = this.getIndices(kitId, playerId);
    this.result[indices.kit].teamConfig[indices.player ?? -1].number = value;
  }

  setOrderGarmentSize = (kitId: string, playerId: string | undefined, garment: EConfigGarment, value: string) => {
    const indices = this.getIndices(kitId, playerId, garment);
    const kit = this.result[indices.kit];
    const garments = kit.teamConfig[indices.player ?? -1].garments;
    garments[indices.garment ?? -1].size = value;

    // set catalog number

    const collectionId = getCollectionIdByModelId(kit.modelConfig.stepCollection[garment]);
    const catalog = getCatalog(kit.sportId, garment, collectionId);
    if (!catalog) {
      console.log('setOrderGarmentSize ERROR: catalog not found');
      return;
    }
    const gender = kit.teamConfig[indices.player ?? -1].gender;
    const isKids = sizes.children.some(item => item.name === value);
    const type = getCatalogType(gender, isKids);
    const catalogNumber = getModelCatalog(catalog, type);
    const index = garments.findIndex(item => item.garment === garment);
    garments[index ?? -1].catalog = catalogNumber || '';
  }

  updateGarmentsPrices = (kitId: string, playerId: string | undefined) => {
    const indices = this.getIndices(kitId, playerId);
    const kit = this.result[indices.kit];
    const extractPrice = (garment: EConfigGarment): number => {
      // original price, without discounts
      if (garment === EConfigGarment.JERSEY) {
        return getPrice(kit.sportId, EConfigGarment.JERSEY, 1) || 0;
      }
      if (garment === EConfigGarment.SHORTS) {
        return getPrice(kit.sportId, EConfigGarment.SHORTS, 1) || 0;
      }
      return getPrice(kit.sportId, EConfigGarment.SOCKS, 1) || 0;
    }

    kit.teamConfig = kit.teamConfig.map(player => {
      if (player.id === playerId) {
        const update: IIndividualConfig = {
          ...cloneDeep(player),
          garments: player.garments.map(cloth => {
            const price = extractPrice(cloth.garment);
            return {
              ...cloneDeep(cloth),
              price,
            }
          }),
        }
        return update;
      } else {
        return player;
      }
    });
  }

  setOrderGarmentQuantity = (kitId: string, playerId: string | undefined, garment: EConfigGarment, value: number) => {
    const indices = this.getIndices(kitId, playerId, garment);
    this.result[indices.kit].teamConfig[indices.player ?? -1].garments[indices.garment ?? -1].quantityGarments = value;
    this.updateGarmentsPrices(kitId, playerId);
    this.updateKitSum(indices.kit);
  }

  setOrderKitsQuantity = (kitId: string, playerId: string | undefined, value: number) => {
    const indices = this.getIndices(kitId, playerId);
    this.result[indices.kit].teamConfig[indices.player ?? -1].quantityKits = value;
    this.updateGarmentsPrices(kitId, playerId);
    this.updateKitSum(indices.kit);
  }

  setPlayerGender = (kitId: string, playerId: string | undefined, gender: TGender) => {
    const indices = this.getIndices(kitId, playerId);
    const kit = this.result[indices.kit];
    kit.teamConfig[indices.player ?? -1].gender = gender;
  }

  setModelScreenShot = (data: IModelScreenshot) => {
    this.modelScreenshot = data;
  }

  restoreDefaults = () => {
    this.isOpenPanel = false;
    this.currentGarment = EConfigGarment.JERSEY;
    this.currentColorChapter = colorChaptersDefaults;
    this.includeJersey = true;
    this.includeShorts = false;
    this.includeSocks = false;
    this.stepCollection = stepCollectionDefaults;
    this.stepDesignName = EConfigDesigns.DEFAULT;
    this.stepColor = stepColorDefaults;
    this.stepDecoration = stepDecorationDefaults;
    this.hideSocks = false;
  }

  collectData = (): ICollectData => {
    return {
      // UI
      // isOpenPanel: this.isOpenPanel,
      // currentStep: this.currentStep,
      // currentGarment: this.currentGarment,
      // currentColorChapter: this.currentColorChapter,
      version: APP_VERSION,
      showUploadImageHint: this.showUploadImageHint,
      hideSocks: this.hideSocks,

      // completion
      includeJersey: this.includeJersey,
      includeShorts: this.includeShorts,
      includeSocks: this.includeSocks,

      // steps
      stepSportId: this.stepSportId,
      stepCollection: this.stepCollection,
      stepDesignName: this.stepDesignName,
      stepColor: this.stepColor,
      stepDecoration: this.stepDecoration,

      // order formation table
      kitsCount: this.kitsCount,
      currentKitId: this.currentKitId,
      result: this.result,
    }
  }

  putData = (data: ICollectData) => {
    // UI
    // this.isOpenPanel = data.isOpenPanel;
    // this.currentStep = data.currentStep;
    // this.currentGarment = data.currentGarment;
    // this.currentColorChapter = data.currentColorChapter;
    this.showUploadImageHint = data.showUploadImageHint;
    this.hideSocks = data.hideSocks;

    // completion
    this.includeJersey = data.includeJersey;
    this.includeShorts = data.includeShorts;
    this.includeSocks = data.includeSocks;

    // steps
    this.stepSportId = data.stepSportId;
    this.stepCollection = data.stepCollection;
    this.stepDesignName = data.stepDesignName;
    this.stepColor = data.stepColor;
    this.stepDecoration = data.stepDecoration;

    // order formation table
    this.kitsCount = data.kitsCount;
    this.currentKitId = data.currentKitId;
    this.result = data.result;
  }
}

export default new AppStore();
