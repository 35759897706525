import React, {useCallback, useEffect, useState, useMemo, useRef} from 'react';
import { WrappedOrderDetails } from './popupStyle';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import InputText from '../UI/Input/InputText';
import InputTextArea from '../UI/Input/InputTextArea';
import imgClose from '../../assets/UI/close.svg';
import store from '../../store/rootStore';
import Button from '../UI/Buttons/Button';
import {AnimPopup, TEXT_COLOR} from '../../constants';
import { observer } from 'mobx-react-lite';
import { IOrderInfo } from '../../store/types';
import DialogNotice from '../Popups/DialogNotice';
import DialogWarning from '../Popups/DialogWarning';
import { orderService } from '../../api/rest/';
import { useTranslation } from 'react-i18next';
import gsap from "gsap";

const OrderDetails: React.FC = observer(() => {
  const { t } = useTranslation();
  const {setOpenDialog, setDialogJSX} = store.app;
  const {setStep, result} = store.app;
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const myRef = useRef(null);

  useEffect(() => {
    gsap.to(myRef.current, AnimPopup);
  }, []);

  const {
    firstName,
    lastName,
    email,
    phone,
    userMessage,
    setFirstName,
    setLastName,
    setEmail,
    setPhone,
    setUserMessage,
    restoreDefaults
  } = store.order;

  const schema = useMemo(() => Yup.object({
    firstName: Yup.string()
      .trim()
      .min(2, t('order_details.errors.longer'))
      .required(t('order_details.errors.required')),
    lastName: Yup.string()
      .trim()
      .min(2, t('order_details.errors.longer'))
      .required(t('order_details.errors.required')),
    email: Yup.string()
      .trim()
      .email(t('order_details.errors.email'))
      .required(t('order_details.errors.required')),
    phone: Yup.string()
      .required(t('order_details.errors.required')),
    userMessage: Yup.string()
      .trim()
      .max(2000, t('order_details.errors.shorter'))
  }), [t]);

  const { control, handleSubmit, watch, formState:{ errors }, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      firstName,
      lastName,
      email,
      phone,
      userMessage,
    }
  });

  const renderErrorDialog = useCallback(() => (
    <DialogWarning
      mainText={t('ui.error')}
      warningText={t('ui.close')}
      handleWarning={() => setOpenDialog(false)}
    />
  ), [setOpenDialog, t]);

  const onSubmit = async (data: IOrderInfo) => {
    if (isSubmitting) {
      return;
    }

    try {
      setIsSubmitting(true);

      const response = await orderService.sendOrderToEmail(data, result, t);

      if (response.status !== 200 || !response.data?.result) {
        setDialogJSX(renderErrorDialog());
      } else {
        setDialogJSX(
          <DialogNotice
            mainText={t('order_details.success')}
            secondaryText={t('order_details.success_text')}
            btnText={t('order_details.home_page')}
            handleClick={() => {
              setOpenDialog(false);
              restoreDefaults();
              reset();
              setStep(0);
            }}
          />
        );
      }
    } catch {
      setDialogJSX(renderErrorDialog());
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleClose = () => {
    setOpenDialog(false);
  }

  const watchFirstName = watch('firstName');
  const watchLastName = watch('lastName');
  const watchEmail = watch('email');
  const watchPhone = watch('phone');
  const watchMessage = watch('userMessage');

  useEffect(() => {
    setFirstName(watchFirstName || '');
  }, [setFirstName, watchFirstName])

  useEffect(() => {
    setLastName(watchLastName || '');
  }, [setLastName, watchLastName])

  useEffect(() => {
    setEmail(watchEmail || '');
  }, [setEmail, watchEmail])

  useEffect(() => {
    setPhone(watchPhone || '');
  }, [setPhone, watchPhone])

  useEffect(() => {
    setUserMessage(watchMessage || '');
  }, [setUserMessage, watchMessage])

  return (
    <WrappedOrderDetails
      onClick={(e) => e.stopPropagation()}
      ref={myRef}
    >
      <div className="orderHeading">
        <h2>{t('order_details.order_details')}</h2>
        <p className='hint'>{t('order_details.hint')}</p>
        <img
          src={imgClose}
          alt='X'
          onClick={handleClose}
          className='btn_close'
        />
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>

        <div className='wrap'>

          <div className='wrap_cell'>
            <label>{t('order_details.first_name')} <span className='req'>*</span>
              <Controller
                name="firstName"
                control={control}
                render={({ field }) => <InputText
                  value={field.value}
                  handleChange={field.onChange}
                  maxLength={30}
                />}
              />
            </label>
            <p className='error'>{errors.firstName?.message}</p>
          </div>

          <div className='wrap_cell'>
            <label>{t('order_details.last_name')} <span className='req'>*</span>
              <Controller
                name="lastName"
                control={control}
                render={({ field }) => <InputText
                  value={field.value}
                  handleChange={field.onChange}
                  maxLength={30}
                />}
              />
            </label>
            <p className='error'>{errors.lastName?.message}</p>
          </div>

          <div className='wrap_cell'>
            <label>{t('order_details.email')} <span className='req'>*</span>
              <Controller
                name="email"
                control={control}
                render={({ field }) => <InputText
                  value={field.value}
                  handleChange={field.onChange}
                  maxLength={50}
                />}
              />
            </label>
            <p className='error'>{errors.email?.message}</p>
          </div>

          <div className='wrap_cell'>
            <label>{t('order_details.phone')} <span className='req'>*</span>
              <Controller
                name="phone"
                control={control}
                render={({ field }) => <InputText
                  value={field.value}
                  handleChange={field.onChange}
                  maxLength={15}
                  isNumberOnly
                />}
              />
            </label>
            <p className='error'>{errors.phone?.message}</p>
          </div>

        </div>

        <div className='wrap_message'>
          <label>{t('order_details.message')}
            <Controller
              name="userMessage"
              control={control}
              render={({ field }) => <InputTextArea
                value={field.value}
                handleChange={field.onChange}
                style={{
                  resize: 'none',
                  margin: '8px 0 5px 0',
                }}
              />}
            />
          </label>
          <p className='error error__message'>{errors.userMessage?.message}</p>
        </div>

        <div className='wrap_submit'>
          <Button
            isSubmit
            isActive={!isSubmitting}
            background={TEXT_COLOR}
            color='white'
          >
            <p>{isSubmitting ? t('order_details.submitting') : t('order_details.submit')}</p>
          </Button>
        </div>

    </form>
    </WrappedOrderDetails>
  );
})

export default OrderDetails;
