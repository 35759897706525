import styled, { css } from 'styled-components';
import { BG_GREEN, BTN_BG_COLOR_DISABLED, BTN_TEXT_COLOR_DISABLED, BTN_TEXT_COLOR_PRESSED, TEXT_COLOR } from '../../constants';

interface ICardMainMenuProps {
  imgPath: string;
  isMouseOver: boolean;
  isActive: boolean;
}

export const WrappedCardMainMenu = styled.div<ICardMainMenuProps>`
  width: 15%;
  height: 372px;
  border-radius: 32px;
  background-image: url(${props => props.imgPath});
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  cursor: ${props => props.isActive ? 'pointer' : 'default'};
  transition: transform 0.15s ease, opacity 0.4s ease;
  opacity: ${props => props.isActive ? (props.isMouseOver ? '0.7' : 1) : '0.7'};

  &:hover {
    transform: scale(${props => props.isActive ? 1.1 : 1});
    opacity: ${props => props.isActive ? 1 : 0.7};

    .text__cont {
      background: ${props => props.isActive ? BG_GREEN : BTN_TEXT_COLOR_DISABLED};
    }
  }

  .text__cont {
    width: 100%;
    height: 54px;
    background: ${BTN_TEXT_COLOR_DISABLED};
    position: absolute;
    bottom: 0;
    border-radius: 0 0 32px 32px;
    font-family: Montserrat, sans-serif;
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media all and (max-width: 1920px) {
    width: 15%;
    height: 305px;
    border-radius: 24px;

    .text__cont {
      height: 45px;
      border-radius: 0 0 24px 24px;
      font-size: 16px;
    }
  }

  @media all and (max-width: 1050px) {
    height: 250px;
  }
`

interface ICardMenuProps {
  isActive: boolean;
}

export const WrappedCardMenu = styled.div<ICardMenuProps>`
  width: 113px;
  height: 140px;
  background: #FFFFFF;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  cursor: ${props => props.isActive ? 'pointer' : 'default'};
  opacity: ${props => props.isActive ? '1' : '0.7'};
  border: 1px solid transparent;

  &:hover {
    border: 1px solid ${props => props.isActive ? BTN_BG_COLOR_DISABLED : 'transparent'};
    box-shadow: ${props => props.isActive ? '0px 8px 33px -16px rgba(0, 0, 0, 0.25)' : 'none'};

    p {
      color: ${props => props.isActive ? BTN_TEXT_COLOR_PRESSED : 'inherit'};
    }
  }

  img {
    width: 32px;
  }

  p {
    font-style: italic;
    font-weight: 700;
    text-align: center;
  }

  @media all and (max-width: 1920px) {
    width: 100px;
    height: 110px;

    img {
      width: 28px;
    }
  }

  @media all and (max-width: 768px) {
    width: 85px;
    height: 104px;

    img {
      width: 24px;
    }
  }
`

interface ICardModelProps {
  isSelected: boolean;
  isStepDesign?: boolean;
}

const model__normal = css`
  border: 1px solid white;
  box-shadow: 'none';
`

const model__selected = css`
  border: 1px solid ${BTN_BG_COLOR_DISABLED};
  box-shadow: 0px 8px 33px -16px rgba(0, 0, 0, 0.25);
`

export const WrappedCardModel = styled.div<ICardModelProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  min-width: 113px;
  width: max-content;
  height: 140px;
  background: #FFFFFF;
  border-radius: 16px;
  cursor: pointer;
  ${props => props.isSelected ? model__selected : model__normal}

  img {
    height: 75px;
  }

  p {
    font-weight: 700;
    font-style: italic;
    color: ${props => props.isStepDesign ? (props.isSelected ? BG_GREEN : TEXT_COLOR) : TEXT_COLOR};
  }

  .price {
    color: ${props => props.isSelected ? BG_GREEN : TEXT_COLOR};
  }

  @media all and (max-width: 1920px) {
    min-width: 85px;
    width: max-content;
    height: 104px;

    img {
      height: 60px;
    }

    p, .price {
      font-size: 11px;
    }
  }

  @media all and (max-width: 768px) {
    min-width: 85px;
    width: max-content;
    height: 104px;
    ${props => props.isSelected ? model__selected : model__normal}

    img {
      height: 60px;
    }

    p, .price {
      font-size: 10px;
    }
  }
`
