import styled from 'styled-components';

export const WrappedStepPanelHead = styled.div`
  display: flex;
  gap: 16px;
`

export const WrappedStepPanelContent = styled.div`
  display: flex;
  gap: 32px;

  @media all and (max-width: 1920px) {
    gap: 16px;
  }
`