import React, {
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { WrappedMain } from './mainStyle';
import store from '../../store/rootStore';
import StepSport from '../Steps/sport/StepSport';
import {observer} from 'mobx-react-lite';
import LayoutViewport from '../Viewport/LayoutViewport';
import OrderFormation from '../Steps/order/OrderFormation';
import Modal from '../../components/Popups/Modal';
import DialogConfirm from '../../components/Popups/DialogConfirm';
import { useTranslation } from 'react-i18next';
import useLocalStorage from "../../hooks/useLocalStorage";
import {ICollectData} from "../../store/types";
import {APP_VERSION} from "../../constants";

/*
currentStep:
0 - sport
1 - collection
2 - design
3 - color
4 - decoration
5 - order formation
*/

const Main: React.FC = observer(() => {
  const { t } = useTranslation();
  const myRef = useRef(true);
  const {
    currentStep,
    toggleDevMode,
    dialogJSX,
    isOpenDialog,
    collectData,
    putData,
    getUndoneKitId,
    setDialogJSX,
    setOpenDialog,
    continueEditKit,
    setKitDone
  } = store.app;

  const { onWrite, onRead, onRemove } = useLocalStorage();

  // dev mode

  const devMode = useCallback((e) => {
    if (e.ctrlKey && e.altKey && (e.key === 's' || e.key === 'ы')) {
      toggleDevMode();
    }
  }, [toggleDevMode])

  useEffect(() => {
    window.addEventListener('keydown', devMode);
    return () => {
      window.removeEventListener('keydown', devMode);
    }
  }, [devMode])

  // local storage and undone kits

  useEffect(() => {
    if (myRef.current) {
      // first render
      const save = onRead('configurator') as (ICollectData | undefined);
      if (save) {
        if (!save.version || save.version < APP_VERSION) {
          onRemove('configurator');
        } else {
          // console.log('Restore data');
          putData(save);
        }
      } else {
        // console.log('No data in storage');
      }
      myRef.current = false;
    } else {
      // every render exclude first
      onWrite('configurator', collectData());
    }
  })

  useEffect(() => {
    return () => {
      onWrite('configurator', collectData());
    }
  }, [onWrite, collectData])

  useEffect(() => {
    if (currentStep === 0) {
      const undoneKitId = getUndoneKitId();
      if (undoneKitId) {
        setDialogJSX(
          <DialogConfirm
            mainText={t('warnings.undone_kit')}
            confirmText={t('ui.yes')}
            declineText={t('ui.no_create_new')}
            handleConfirm={() => {
              continueEditKit(undoneKitId);
              setOpenDialog(false);
            }}
            handleDecline={() => {
              setKitDone(undoneKitId);
              setOpenDialog(false);
            }}
            declineWidth='200px'
          />
        );
        setOpenDialog(true);
      }
    }
  }, [continueEditKit, currentStep, getUndoneKitId, setDialogJSX, setKitDone, setOpenDialog, t])

  const renderStep = () => {
    switch (currentStep) {
      case 0: return <StepSport/>
      case 1:
      case 2:
      case 3:
      case 4: return <LayoutViewport/>
      case 5: return <OrderFormation/>
      default: return null;
    }
  }

  return (
    <WrappedMain isStepSport={currentStep === 0}>
        {renderStep()}
        {
          isOpenDialog &&
          <Modal>
            {dialogJSX}
          </Modal>
        }
    </WrappedMain>
  );
})

export default Main;
