import imgFootball from './assets/cards/card_football.png';
import imgHandball from './assets/cards/card_handball.png';
import imgBasketball from './assets/cards/card_basketball.png';
import imgRacket from './assets/cards/card_racket.png';
import imgRugby from './assets/cards/card_rugby.png';
import imgVolleyball from './assets/cards/card_volleyball.png';

import iconFootball from './assets/icons/icon_football.svg';
import iconHandball from './assets/icons/icon_handball.svg';
import iconBasketball from './assets/icons/icon_basketball.svg';
import iconRacket from './assets/icons/icon_racket.svg';
import iconRugby from './assets/icons/icon_rugby.svg';
import iconVolleyball from './assets/icons/icon_volleyball.svg';

import iconFootballSelected from './assets/icons/icon_football_selected.svg';
import iconHandballSelected from './assets/icons/icon_handball_selected.svg';
import iconBasketballSelected from './assets/icons/icon_basketball_selected.svg';
import iconRacketSelected from './assets/icons/icon_racket_selected.svg';
import iconRugbySelected from './assets/icons/icon_rugby_selected.svg';
import iconVolleyballSelected from './assets/icons/icon_volleyball_selected.svg';

import {css} from 'styled-components';
import { IButton } from './components/UI/SwitchButtons/SwitchButtons';
import {colors} from "./db/colors";
import {ColorHex} from "./db/types";

// button and card colors
export const WHITE = '#FFFFFF';
export const BTN_TEXT_COLOR = '#5E5E5E';
export const BTN_TEXT_COLOR_PRESSED = '#5DB799';
export const BTN_TEXT_COLOR_DISABLED = '#222222';
export const BTN_BORDER_COLOR = '#D9D9D9';
export const BTN_BG_COLOR_DISABLED = '#F2F2F2';

// common for all site

export const TEXT_COLOR = '#222222';
export const BG_GREEN = '#5DB799';
export const IS_COLOR_PICKER = false;

// data of 3d model config

interface ISportElement {
  [key: string]: {
    text?: string,
    imgPath: string,
    iconPath: string,
    iconSelectedPath: string,
  }
}

export const configSport: ISportElement = {
  football: {
    imgPath: imgFootball,
    iconPath: iconFootball,
    iconSelectedPath: iconFootballSelected,
  },
  basketball: {
    imgPath: imgBasketball,
    iconPath: iconBasketball,
    iconSelectedPath: iconBasketballSelected,
  },
  handball: {
    imgPath: imgHandball,
    iconPath: iconHandball,
    iconSelectedPath: iconHandballSelected,
  },
  racket: {
    imgPath: imgRacket,
    iconPath: iconRacket,
    iconSelectedPath: iconRacketSelected,
  },
  rugby: {
    imgPath: imgRugby,
    iconPath: iconRugby,
    iconSelectedPath: iconRugbySelected,
  },
  volleyball: {
    imgPath: imgVolleyball,
    iconPath: iconVolleyball,
    iconSelectedPath: iconVolleyballSelected,
  },
}

export const TextNotFound = 'not found';
export const TextInfiniteMesh = 'Infinite Mesh©';

export const fonts: IButton[] = [
  {name: 'masque', value: 'Masque'},
  {name: 'rubik', value: 'Rubik'},
  {name: 'chakra_petch', value: 'Chakra Petch'},
  {name: 'crenzo', value: 'Crenzo'},
  {name: 'dm_serif_text', value: 'DM Serif Text'},
  {name: 'horizon', value: 'Horizon'},
  {name: 'kaisei_haruno_umi', value: 'Kaisei HarunoUmi'},
  {name: 'm_plus_1p', value: 'MPLUS1p'},
  {name: 'promesh', value: 'Promesh'},
  {name: 'ridgeline_201', value: 'Ridgeline201'},
];

export const fontSizes = [
  {name: '1', value: '1'},
  {name: '2', value: '2'},
  {name: '3', value: '3'},
];

export const sizes = {
  socks: [
    {name: 'sock_s', value: '25-28'},
    {name: 'sock_m', value: '29-32'},
    {name: 'sock_l', value: '33-36'},
    {name: 'sock_xl', value: '37-40'},
    {name: 'sock_2xl', value: '41-45'},
    {name: 'sock_3xl', value: '46-48'},
  ],
  children: [
    {name: 'ch_s', value: '4Y'},
    {name: 'ch_m', value: '6Y'},
    {name: 'ch_l', value: '8Y'},
    {name: 'ch_xl', value: '10Y'},
    {name: 'ch_2xl', value: '12Y'},
    {name: 'ch_3xl', value: '14Y'},
  ],
  adult: [
    {name: 'ad_s', value: 'S'},
    {name: 'ad_m', value: 'M'},
    {name: 'ad_l', value: 'L'},
    {name: 'ad_xl', value: 'XL'},
    {name: 'ad_2xl', value: '2XL'},
    {name: 'ad_3xl', value: '3XL'},
    {name: 'ad_4xl', value: '4XL'},
  ]
}

export const numberCategories = ['position', 'number', 'font', 'stroke'];
export const textCategories = ['position', 'text', 'font', 'stroke'];
export const SOCKS_COLORS_FILTER: ColorHex[] = [colors.white, colors.black, colors.blue];

// STYLED COMPONENTS

export const desktopToMobileCss = css`
  .desktop {
    display: block;
  }

  .mobile {
    display: none;
  }

  @media all and (max-width: 768px) {
    .desktop {
      display: none;
    }

    .mobile {
      display: block;
    }
  }
`

export const scrollBarCss = css`
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    width: 4px;
    height: 80px;
    background: ${BTN_TEXT_COLOR};
    border-radius: 2px;
  }
`

// animations

export const AnimPopover = {
  opacity: 1,
  scale: 1,
  duration: .1,
}

export const AnimOpenPanel = {
  opacity: 1,
  duration: .2,
  ease: 'power1.in',
}

export const AnimPopup = {
  opacity: 1,
  x: 0,
  duration: .4,
  ease: 'power3.out',
}

export const AnimPopupError = {
  x: 0,
  duration: .5,
  ease: 'elastic.out(1, 0.3)',
}

// other

export const fileTypes = ['image/jpeg', 'image/png', 'image/svg', 'image/svg+xml'];
export const debounceTime = 800;

export interface IModelCoords {
  y: number;
  z: number;
}

export interface IAllModelCoords {
  jersey: IModelCoords;
  shorts: IModelCoords;
  socks: IModelCoords;
}

export const modelCoords: IAllModelCoords = {
  // y: decrease amount to get model down

  // new version
  // jersey: {y: -110, z: 4},
  // shorts: {y: -70, z: 4},
  // socks: {y: -5, z: 4},

  jersey: {y: -123, z: 4},
  shorts: {y: -80, z: 4},
  socks: {y: -15, z: 4},
}

export const maxKits = 10;
export const noltSiteLink = 'https://wearenolt.com/';
export const LANGUAGE_DEFAULT = 'fr';
export const PIXELS_PER_CM = 35;
export const APP_VERSION = 1; // increase this value by 1 if you want to DELETE client's localStorage saves
