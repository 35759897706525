import styled from 'styled-components';
import { desktopToMobileCss } from '../../../constants';

export const WrappedStepColorPanelHead = styled.div`
  display: flex;
  gap: 16px;
  padding-left: 120px;

  @media all and (max-width: 768px) {
    display: none;
  }
`

export const WrappedStepColorPanelContent = styled.div`
  ${desktopToMobileCss}
`
