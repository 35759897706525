import {IDiscount} from "./types";

const minMax1 = {
  min: 1,
  max: 5,
}

const minMax2 = {
  min: 6,
  max: 9,
}

const minMax3 = {
  min: 10,
  max: 30,
}

const minMax4 = {
  min: 31,
  max: 50,
}

const minMax5 = {
  min: 51,
  max: 75,
}

const minMax6 = {
  min: 76,
  max: Infinity,
}

// повторяющиеся фрагменты price

export const priceJersey: IDiscount[] = [
  {
    ...minMax1,
    price: 55,
  },
  {
    ...minMax2,
    price: 35,
  },
  {
    ...minMax3,
    price: 31.5,
  },
  {
    ...minMax4,
    price: 29.75,
  },
  {
    ...minMax5,
    price: 28,
  },
  {
    ...minMax6,
    price: 26.25,
  },
];

export const priceShorts: IDiscount[] = [
  {
    ...minMax1,
    price: 49,
  },
  {
    ...minMax2,
    price: 29,
  },
  {
    ...minMax3,
    price: 26.1,
  },
  {
    ...minMax4,
    price: 24.65,
  },
  {
    ...minMax5,
    price: 23.2,
  },
  {
    ...minMax6,
    price: 21.75,
  },
];

export const priceJerseyRugby: IDiscount[] = [
  {
    ...minMax1,
    price: 65,
  },
  {
    ...minMax2,
    price: 45,
  },
  {
    ...minMax3,
    price: 40.5,
  },
  {
    ...minMax4,
    price: 38.25,
  },
  {
    ...minMax5,
    price: 36,
  },
  {
    ...minMax6,
    price: 33.75,
  },
];

export const priceShortsRugbyRacket: IDiscount[] = [
  {
    ...minMax1,
    price: 54,
  },
  {
    ...minMax2,
    price: 34,
  },
  {
    ...minMax3,
    price: 30.6,
  },
  {
    ...minMax4,
    price: 28.9,
  },
  {
    ...minMax5,
    price: 27.2,
  },
  {
    ...minMax6,
    price: 25.5,
  },
];

export const priceSocksFootball: IDiscount[] = [
  {
    ...minMax1,
    price: 14,
  },
  {
    ...minMax2,
    price: 14,
  },
  {
    ...minMax3,
    price: 12.6,
  },
  {
    ...minMax4,
    price: 11.9,
  },
  {
    ...minMax5,
    price: 11.2,
  },
  {
    ...minMax6,
    price: 10.5,
  },
];
export const priceSocksRugby: IDiscount[] = [
  {
    ...minMax1,
    price: 19,
  },
  {
    ...minMax2,
    price: 14,
  },
  {
    ...minMax3,
    price: 12.6,
  },
  {
    ...minMax4,
    price: 11.9,
  },
  {
    ...minMax5,
    price: 11.2,
  },
  {
    ...minMax6,
    price: 10.5,
  },
];
