import styled from 'styled-components';
import { BTN_BORDER_COLOR } from '../../constants';

interface IProps {
  isCarousel?: boolean;
}

export const WrappedColorPicker = styled.div<IProps>`
  margin-top: ${props => props.isCarousel ? '15px': 0};
  display: flex;
  align-items: center;

  .wrap {
    width: 100%;

    .wrap__slider {
      display: flex;
      gap: 13px;
      height: 50px;
      align-items: center;
    }
  }

  .color-picker {
    position: absolute;
    top: 60px;
    left: 100px;
    display: flex;
    gap: 16px;
    
    input {
      cursor: pointer;
    }

    button {
      font-size: 12px;
      cursor: pointer;
    }
  }

  @media all and (max-width: 768px) {
    .color-picker {
      top: -40px;
      left: 20px;
    }
  }
`

interface IColorSlotProps {
  color: string;
  isSelected: boolean;
}

export const WrappedColorSlot = styled.div<IColorSlotProps>`
  width: 40px;
  min-width: 40px;
  height: ${props => props.isSelected ? '80px' : '40px'};
  min-height: 40px;
  border-radius: 20px;
  border: 1px solid ${BTN_BORDER_COLOR};
  background: ${props => props.color};
  cursor: pointer;
  transition: all 0.2s ease;

  @media all and (max-width: 1920px) {
    width: 35px;
    min-width: 35px;
    height: ${props => props.isSelected ? '70px' : '35px'};
    min-height: 35px;
    border-radius: 17px;
  }

  @media all and (max-width: 768px) {
    width: 32px;
    min-width: 32px;
    height: ${props => props.isSelected ? '50px' : '32px'};
    min-height: 32px;
    border-radius: 16px;
  }
`