import React from "react";
import store from "../store/rootStore";
import {useTranslation} from "react-i18next";
import {EConfigGarment} from "../enums";
import DialogWarning from "../components/Popups/DialogWarning";

interface IncludeParams {
  item: EConfigGarment;
  isPlus: boolean;
  includeJersey: boolean;
  includeShorts: boolean;
  includeSocks: boolean;
}

interface IClothPadsProps {
  isCalc?: boolean;
  localIncludeJersey?: boolean;
  localIncludeShorts?: boolean;
  localIncludeSocks?: boolean;
  setLocalIncludeJersey?: (value: boolean) => void;
  setLocalIncludeShorts?: (value: boolean) => void;
  setLocalIncludeSocks?: (value: boolean) => void;
}

interface IReturnClothPadsResult {
  handleInclude: (params: IncludeParams) => void;
  getInactive: (item: EConfigGarment) => boolean;
}

const useClothPads = (props: IClothPadsProps): IReturnClothPadsResult => {
  const {
    currentGarment,
    currentStep,
    setCurrentGarment,
    isQuantityEnough,
    setDialogJSX,
    setOpenDialog,
    setIncludeJersey,
    setIncludeShorts,
    setIncludeSocks,
  } = store.app;

  const {
    setLocalIncludeJersey = () => undefined,
    setLocalIncludeShorts = () => undefined,
    setLocalIncludeSocks = () => undefined,
    isCalc = false,
    localIncludeJersey = false,
    localIncludeShorts = false,
    localIncludeSocks = false,
  } = props;

  const { setVisibility } = store.canvas;

  const { t: translate } = useTranslation();

  const handleInclude = (params: IncludeParams) => {
    const {item, isPlus, includeJersey, includeShorts, includeSocks} = params;
    if (!isCalc && isPlus && !isQuantityEnough(includeJersey, includeShorts, includeSocks)) {
      setDialogJSX(
        <DialogWarning
          mainText={translate('ui.error')}
          describeText={translate('warnings.last_cloth')}
          handleWarning={() => setOpenDialog(false)}
        />
      );
      setOpenDialog(true);
      return;
    }
    if (isCalc && isPlus && !isQuantityEnough(localIncludeJersey, localIncludeShorts, localIncludeSocks)) {
      setDialogJSX(
        <DialogWarning
          mainText={translate('ui.error')}
          describeText={translate('warnings.last_cloth')}
          handleWarning={() => setOpenDialog(false)}
        />
      );
      setOpenDialog(true);
      return;
    }

    if (item === EConfigGarment.JERSEY) {
      if (isCalc) {
        setLocalIncludeJersey(!isPlus)
      } else {
        setIncludeJersey(!isPlus);
        setVisibility(EConfigGarment.JERSEY, !isPlus);
      }
    }
    if (item === EConfigGarment.SHORTS) {
      if (isCalc) {
        setLocalIncludeShorts(!isPlus)
      } else {
        setIncludeShorts(!isPlus);
        setVisibility(EConfigGarment.SHORTS, !isPlus);
      }
    }
    if (item === EConfigGarment.SOCKS) {
      if (isCalc) {
        setLocalIncludeSocks(!isPlus)
      } else {
        setIncludeSocks(!isPlus);
        setVisibility(EConfigGarment.SOCKS, !isPlus);
      }
    }
  };

  const getInactive = (item: EConfigGarment): boolean => {
    switch (currentStep) {
      case 2: {
        if (item !== EConfigGarment.JERSEY) {

          if (currentGarment !== EConfigGarment.JERSEY) {
            // auto set to the first active garment
            setCurrentGarment(EConfigGarment.JERSEY);
          }

          return true;
        }
        return false;
      }
      case 4: {
        if (item === EConfigGarment.SOCKS) {

          if (currentGarment === EConfigGarment.SOCKS) {
            // auto set to the first active garment
            setCurrentGarment(EConfigGarment.JERSEY);
          }

          return true;
        }
        return false;
      }
      default: return false;
    }
  };

  return {
    handleInclude,
    getInactive,
  }
}

export default useClothPads;
