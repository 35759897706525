import { IPositions } from "./types";

/*
!!! ВАЖНО
Теперь размеры картинок width и height не хардкодятся, а рассчитываются, исходя из значений sizeX, sizeY и константы PIXELS_PER_CM

Например, фрагмент из старого кода:

logo chest_r1
width: 195,
height: 195,
logo chest_r3
width: 351,
height: 351,
sizeX min = 5 cm
sizeX max = 10 cm

Следовательно, sizeX min = 5 см = 195 = PIXELS_PER_CM * 5
sizeX max = 10 см = 351 = PIXELS_PER_CM * 10

Можно рассчитать промежуточный размер.

Например, если sizeX = 7 см, то width будет = PIXELS_PER_CM * 7 = 273

Пересчет происходит в файле positionsApi.ts

Примечание: конечные цифры могут отличаться от цифр из старого кода, т.к. клиент изменил размеры
 */

export const positions: IPositions[] = [
  // JERSEY

  {
    modelIds: ['0'], // basketball_fire
    positions: {
      number: {
        number: {
          x: 3123,
          y: 1592,
        },
      },
      lastName: {
        name_1: {
          x: 3123,
          y: 918,
        },
        name_2: {
          x: 3123,
          y: 2285,
        },
      },
      logo: {
        chest: {
          x: 1401,
          y: 985,
          minMaxX: [5, 10],
          minMaxY: [5, 10],
        },
      },
      sponsors: {
        back_c1: {
          x: 3136,
          y: 502,
          minMaxX: [6, 12],
          minMaxY: [5, 7],
        },
        back_c2: {
          x: 3136,
          y: 2778,
          minMaxX: [10, 20],
          minMaxY: [7, 14],
        },
        chest_c1: {
          x: 1077,
          y: 1550,
          minMaxX: [15, 30],
          minMaxY: [10, 20],
        },
      }
    },
  },
  {
    modelIds: ['1'], // basketball_renaissance
    positions: {
      number: {
        number: {
          x: 3059,
          y: 1490,
        },
      },
      lastName: {
        name_1: {
          x: 3059,
          y: 847,
        },
        name_2: {
          x: 3059,
          y: 2184,
        },
      },
      logo: {
        chest: {
          x: 1496,
          y: 1132,
          minMaxX: [5, 10],
          minMaxY: [5, 10],
        },
      },
      sponsors: {
        back_c1: {
          x: 3074,
          y: 508,
          minMaxX: [6, 12],
          minMaxY: [5, 7],
        },
        back_c2: {
          x: 3055,
          y: 2966,
          minMaxX: [10, 20],
          minMaxY: [7, 14],
        },
        chest_c1: {
          x: 1156,
          y: 1671,
          minMaxX: [15, 30],
          minMaxY: [10, 20],
        },
      },
    },
  },
  {
    modelIds: ['2'], // football_fire
    positions: {
      number: {
        number: {
          x: 3042,
          y: 1330,
        },
      },
      lastName: {
        name_1: {
          x: 3042,
          y: 652,
        },
        name_2: {
          x: 3042,
          y: 1985,
        },
      },
      logo: {
        chest: {
          x: 1317,
          y: 854,
          minMaxX: [5, 10],
          minMaxY: [5, 10],
        },
      },
      sponsors: {
        back_c1: {
          x: 3046,
          y: 309,
          minMaxX: [6, 12],
          minMaxY: [5, 7],
        },
        back_c2: {
          x: 3025,
          y: 2431,
          minMaxX: [10, 20],
          minMaxY: [7, 14],
        },
        chest_c1: {
          x: 1031,
          y: 1426,
          minMaxX: [15, 30],
          minMaxY: [10, 20],
        },
        sleeves_l: {
          x: 725,
          y: 3835,
          minMaxX: [7, 14],
          minMaxY: [7, 14],
        },
        sleeves_r: {
          x: 2186,
          y: 3837,
          minMaxX: [7, 14],
          minMaxY: [7, 14],
        },
      },
    },
  },
  {
    modelIds: ['3', '8'], // football_renaissance handball_renaissance volleyball_renaissance volleyball_fire 
    positions: {
      number: {
        number: {
          x: 3076,
          y: 1367,
        },
      },
      lastName: {
        name_1: {
          x: 3079,
          y: 769,
        },
        name_2: {
          x: 3079,
          y: 1999,
        },
      },
      logo: {
        chest: {
          x: 1347,
          y: 976,
          minMaxX: [5, 10],
          minMaxY: [5, 10],
        },
      },
      sponsors: {
        back_c1: {
          x: 3090,
          y: 451,
          minMaxX: [6, 12],
          minMaxY: [5, 7],
        },
        back_c2: {
          x: 3115,
          y: 2461,
          minMaxX: [10, 20],
          minMaxY: [7, 14],
        },
        chest_c1: {
          x: 1005,
          y: 1512,
          minMaxX: [15, 30],
          minMaxY: [10, 20],
        },
        sleeves_l: {
          x: 1130,
          y: 3659,
          minMaxX: [7, 14],
          minMaxY: [7, 14],
        },
        sleeves_r: {
          x: 3137,
          y: 3658,
          minMaxX: [7, 14],
          minMaxY: [7, 14],
        },
      },
    },
  },
  {
    modelIds: ['5'], // racket_renaissance
    positions: {
      number: {
        number: {
          x: 3000,
          y: 1275,
        },
      },
      lastName: {
        name_1: {
          x: 3000,
          y: 662,
        },
        name_2: {
          x: 3000,
          y: 1911,
        },
      },
      logo: {
        chest: {
          x: 1350,
          y: 825,
          minMaxX: [5, 10],
          minMaxY: [5, 10],
        },
      },
      sponsors: {
        back_c1: {
          x: 3000,
          y: 322,
          minMaxX: [6, 12],
          minMaxY: [5, 7],
        },
        back_c2: {
          x: 3000,
          y: 2344,
          minMaxX: [10, 20],
          minMaxY: [7, 14],
        },
        chest_c1: {
          x: 1056,
          y: 1362,
          minMaxX: [15, 30],
          minMaxY: [10, 20],
        },
        sleeves_l: {
          x: 746,
          y: 3636,
          minMaxX: [7, 14],
          minMaxY: [7, 14],
        },
        sleeves_r: {
          x: 2127,
          y: 3688,
          minMaxX: [7, 14],
          minMaxY: [7, 14],
        },
      },
    },
  },
  {
    modelIds: ['6'], // rugby_fire
    positions: {
      number: {
        number: {
          x: 3053,
          y: 1239,
        },
      },
      lastName: {
        name_1: {
          x: 3053,
          y: 589,
        },
        name_2: {
          x: 3053,
          y: 1870,
        },
      },
      logo: {
        chest: {
          x: 1375,
          y: 685,
          minMaxX: [5, 10],
          minMaxY: [5, 10],
        },
      },
      sponsors: {
        back_c1: {
          x: 3072,
          y: 202,
          minMaxX: [6, 12],
          minMaxY: [5, 7],
        },
        back_c2: {
          x: 3072,
          y: 2312,
          minMaxX: [10, 20],
          minMaxY: [7, 14],
        },
        chest_c1: {
          x: 1077,
          y: 1060,
          minMaxX: [15, 30],
          minMaxY: [10, 20],
        },
        sleeves_l: {
          x: 765,
          y: 3739,
          minMaxX: [7, 14],
          minMaxY: [7, 14],
        },
        sleeves_r: {
          x: 2241,
          y: 3736,
          minMaxX: [7, 14],
          minMaxY: [7, 14],
        },
      },
    },
  },
  {
    modelIds: ['7'], // rugby_renaissance
    positions: {
      number: {
        number: {
          x: 3255,
          y: 1220,
        },
      },
      lastName: {
        name_1: {
          x: 3257,
          y: 561,
        },
        name_2: {
          x: 3257,
          y: 1855,
        },
      },
      logo: {
        chest: {
          x: 1247,
          y: 762,
          minMaxX: [5, 10],
          minMaxY: [5, 10],
        },
      },
      sponsors: {
        back_c1: {
          x: 3275,
          y: 184,
          minMaxX: [6, 12],
          minMaxY: [5, 7],
        },
        back_c2: {
          x: 3275,
          y: 2294,
          minMaxX: [10, 20],
          minMaxY: [7, 14],
        },
        chest_c1: {
          x: 1079,
          y: 1289,
          minMaxX: [15, 30],
          minMaxY: [10, 20],
        },
        sleeves_l: {
          x: 765,
          y: 3739,
          minMaxX: [7, 14],
          minMaxY: [7, 14],
        },
        sleeves_r: {
          x: 2241,
          y: 3736,
          minMaxX: [7, 14],
          minMaxY: [7, 14],
        },
      },
    },
  },
  {
    modelIds: ['9'], // volleyball_fire_woman
    positions: {
      number: {
        number: {
          x: 3079,
          y: 1401,
        },
      },
      lastName: {
        name_1: {
          x: 3079,
          y: 731,
        },
        name_2: {
          x: 3079,
          y: 2047,
        },
      },
      logo: {
        chest: {
          x: 1320,
          y: 1038,
          minMaxX: [5, 10],
          minMaxY: [5, 10],
        },
      },
      sponsors: {
        back_c1: {
          x: 3062,
          y: 447,
          minMaxX: [6, 12],
          minMaxY: [5, 7],
        },
        back_c2: {
          x: 3062,
          y: 2476,
          minMaxX: [10, 20],
          minMaxY: [7, 14],
        },
        chest_c1: {
          x: 1015,
          y: 1560,
          minMaxX: [15, 30],
          minMaxY: [10, 20],
        },
        sleeves_l: {
          x: 765,
          y: 3739,
          minMaxX: [7, 14],
          minMaxY: [7, 14],
        },
        sleeves_r: {
          x: 2241,
          y: 3736,
          minMaxX: [7, 14],
          minMaxY: [7, 14],
        },
      },
    },
  },

  // SHORTS

  {
    modelIds: ['100', '101', '102', '103', '104'],
    // football handball volleyball renaissance \ fire; racket renaissance; basketball fire \  renaissance
    positions: {
      number: {
        number: {
          x: 1254,
          y: 3310,
        },
      },
      logo: {
        front: {
          x: 1319,
          y: 3723,
          minMaxX: [5, 10],
          minMaxY: [5, 10],
        },
      },
      // sponsors - общий набор позиций для всех спонсоров
      // sponsor3 - предназначен для спонсора 3, отличается только мин-макс размер
      sponsors: {
        front_1: {
          x: 2303,
          y: 3457,
          minMaxX: [5, 10],
          minMaxY: [5, 10],
        },
        front_2: {
          x: 1219,
          y: 3449,
          minMaxX: [5, 10],
          minMaxY: [5, 10],
        },
      },
      sponsor3: {
        front_1: {
          x: 2303,
          y: 3457,
          minMaxX: [8, 16],
          minMaxY: [8, 16],
        },
        front_2: {
          x: 1219,
          y: 3449,
          minMaxX: [8, 16],
          minMaxY: [8, 16],
        },
      },
    },
  },
  {
    modelIds: ['105', '106'], // rugby renaissance \ fire
    positions: {
      number: {
        number: {
          x: 1344,
          y: 2811,
        },
      },
      logo: {
        front: {
          x: 1477,
          y: 3246,
          minMaxX: [5, 10],
          minMaxY: [5, 10],
        },
      },
      sponsors: {
        front_1: {
          x: 1286,
          y: 2932,
          minMaxX: [5, 10],
          minMaxY: [5, 10],
        },
        front_2: {
          x: 2741,
          y: 2938,
          minMaxX: [5, 10],
          minMaxY: [5, 10],
        },
      },
      sponsor3: {
        front_1: {
          x: 1286,
          y: 2932,
          minMaxX: [8, 16],
          minMaxY: [8, 16],
        },
        front_2: {
          x: 2741,
          y: 2938,
          minMaxX: [8, 16],
          minMaxY: [8, 16],
        },
      },
    },
  },
  {
    modelIds: ['107'], // volleyball_woman
    positions: {
      number: {
        number: {
          x: 1340,
          y: 975,
        },
      },
      logo: {
        front: {
          x: 1403,
          y: 1368,
          minMaxX: [5, 10],
          minMaxY: [5, 10],
        },
      },
      sponsors: {
        front_1: {
          x: 2782,
          y: 1102,
          minMaxX: [5, 10],
          minMaxY: [5, 10],
        },
        front_2: {
          x: 1309,
          y: 1103,
          minMaxX: [5, 10],
          minMaxY: [5, 10],
        },
      },
      sponsor3: {
        front_1: {
          x: 2782,
          y: 1102,
          minMaxX: [8, 16],
          minMaxY: [8, 16],
        },
        front_2: {
          x: 1309,
          y: 1103,
          minMaxX: [8, 16],
          minMaxY: [8, 16],
        },
      },
    },
  },
]
