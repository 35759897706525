import React from 'react';
import styled, {css} from "styled-components";
import {PreviewArrowContent, TextPreviewTitle} from "./style";
import svgLeft from '../../../assets/UI/arrow_left.svg';
import svgRight from '../../../assets/UI/arrow_right.svg';

const leftCss = css`
  left: -24px;
  right: unset;
`;

const rightCss = css`
  left: unset;
  right: -24px;
`;

interface WrapperProps {
  type: 'left' | 'right';
}

const Wrapper = styled.div<WrapperProps>`
  width: 103px;
  height: 160px;
  background: #FFFFFF;
  border-radius: 16px;
  position: absolute;
  top: calc(50% - 80px);
  display: flex;
  align-items: center;
  
  ${props => props.type === 'left' ? leftCss : rightCss};

  @media all and (max-width: 768px) {
    width: 100%;
    height: 56px;
    position: static;
  }
`;

interface IProps {
  type: 'left' | 'right';
  disabled?: boolean;
  hide?: boolean;
  title: string | undefined;
  onClick: () => void;
}

const PreviewArrow = ({ type, disabled, hide, title, onClick }: IProps) => {
  const handleClick = () => {
    if (!disabled) {
      onClick();
    }
  }

  const renderContent = (): JSX.Element => <>
    {
      type === 'left'
      && <img src={svgLeft} alt='<'/>
    }
    <TextPreviewTitle>
      { title ? title : null }
    </TextPreviewTitle>
    {
      type === 'right'
      && <img src={svgRight} alt='>'/>
    }
  </>

  return (
    <Wrapper
      type={type}
    >
      <PreviewArrowContent
        onClick={handleClick}
        disabled={disabled}
        hide={hide}
        type={type}
      >
        {
          !hide && renderContent()
        }
      </PreviewArrowContent>
    </Wrapper>
  );
};

export default PreviewArrow;
