import styled, {css} from 'styled-components';
import { BTN_BORDER_COLOR, BTN_TEXT_COLOR, BTN_TEXT_COLOR_DISABLED, BTN_TEXT_COLOR_PRESSED } from '../../../constants';

interface IInputNumberProps {
  width?: string;
  isSimpleStyle?: boolean;
  disabled?: boolean;
}

const inputCommonCss = css`
  padding: 8px 16px;
  background-color: white;
  border: 1px solid ${BTN_BORDER_COLOR};
  border-radius: 16px;
  color: ${BTN_TEXT_COLOR};
  font-weight: 700;
  font-size: 14px;
  outline: none;
  height: 50px;

  @media all and (max-width: 1920px) {
    padding: 6px 12px;
    height: 40px;
  }
`

const inputCommonCssSimple = css`
  padding: 0;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid ${BTN_BORDER_COLOR};
  color: ${BTN_TEXT_COLOR};
  font-weight: 700;
  font-size: 14px;
  text-align: center;
  outline: none;
  height: 30px;

  @media all and (max-width: 1920px) {
    padding: 0;
    height: 20px;
  }
`

export const WrappedInput = styled.input<IInputNumberProps>`
  ${props => props.isSimpleStyle ? inputCommonCssSimple : inputCommonCss}
  width: ${({width, isSimpleStyle}) => width ? width : (isSimpleStyle ? '80%' : '100%')};
  user-select: text;
  ${props => props.disabled && css`
    background-color: rgb(217, 217, 217);
  `};
`

const rangeTrack = css`
  background-color: #BDBDBD;
  border-radius: 2px;
  height: 4px;
  -webkit-appearance: none;
`

const rangeThumb = css`
  width: 16px;
  height: 32px;
  -webkit-appearance: none;
  cursor: ew-resize;
  background: ${BTN_TEXT_COLOR_PRESSED};
  border: 2px solid #FFFFFF;
  border-radius: 7px;
  margin-top: -13px;
`

export const WrappedInputRange = styled.div<IInputNumberProps>`
  display: flex;
  gap: 8px;
  align-items: center;

  input {
    ${inputCommonCss};
    width: ${({width}) => width ? width : '100%'};

    overflow: hidden;
    -webkit-appearance: none;
    background-color: transparent;
    border: none;

    &::-webkit-slider-runnable-track {
      ${rangeTrack}
    }
    &::-moz-range-track {
      ${rangeTrack}
    }
    &::-webkit-slider-thumb {
      ${rangeThumb}
    }
    &::-moz-range-thumb {
      ${rangeThumb}
    }

    @media all and (max-width: 1920px) {
      &::-webkit-slider-thumb {
        width: 12px;
        height: 28px;
        margin-top: -12px;
      }
      &::-moz-range-thumb {
        width: 9px;
        height: 25px;
        margin-top: -12px;
      }
    }
  }

  p {
    font-weight: 700;
    font-size: 16px;
    color: ${BTN_TEXT_COLOR_DISABLED};
    width: 20px;
  }  
`

interface IInputFileProps {
  height?: string;
  minWidth?: string;
}

export const WrappedInputFile = styled.div<IInputFileProps>`
  background-color: white;
  border: 1px solid ${BTN_BORDER_COLOR};
  border-radius: 16px;
  cursor: pointer;
  color: ${BTN_TEXT_COLOR};
  font-weight: 700;
  font-size: 13px;
  min-width: ${props => props.minWidth ? props.minWidth : 'auto'};

  label {
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5em;
    cursor: pointer;
    padding: 8px 16px;
    height: ${props => props.height ? props.height : 'auto'};

    p {
      font-weight: 700;
    }
    
  }

  input {
    display: none;
  }

  @media all and (max-width: 1920px) {
    label {
      padding: 6px 12px;
    }

    label img {
      height: 16px;
    }
  }
`

interface IInputInfoProps {
  background?: string;
  borderColor?: string;
  padding?: string;
  isClickable: boolean;
  width?: string;
}

export const WrappedInputInfo = styled.div<IInputInfoProps>`
  background: ${props => props.background ? props.background : '#FFFFFF'};
  border: 1px solid ${props => props.borderColor ? props.borderColor : '#FFFFFF'};
  border-radius: 16px;
  padding: ${props => props.padding ? props.padding : '0 8px'};
  display: flex;
  align-items: center;
  gap: 7px;
  height: 40px;
  cursor: ${props => props.isClickable ? 'pointer' : 'default'};
  width: ${props => props.width ? props.width : 'auto'};
  position: relative;
  
  .lens {
    opacity: 0;
    position: absolute;
    bottom: 4px;
    left: 30%;
    transition: .2s;
  }

  img {
    height: 30px;
  }

  p {
    color: ${BTN_TEXT_COLOR};
    font-weight: 700;
  }
  
  &:hover {
    .lens {
      opacity: 1;
    }
  }

  @media all and (max-width: 768px) {
    .lens {
      display: none;
    }
  }
`

export const WrappedInputCounter = styled.div`
  ${inputCommonCss};

  width: 110px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-color: ${BTN_TEXT_COLOR_PRESSED};

  img {
    width: 20px;
    cursor: pointer;
  }

  p {
    font-weight: 700;
    color: ${BTN_TEXT_COLOR};
    width: 100%;
    text-align: center;
    line-height: 40px;
  }

  @media all and (max-width: 768px) {
    width: 100%;
  }
`

export const WrappedTextArea = styled.textarea`
  ${inputCommonCss};
  width: 100%;
  user-select: text;
`
