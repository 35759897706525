import styled, {css} from 'styled-components';
import {
  BG_GREEN,
  BTN_BG_COLOR_DISABLED,
  BTN_TEXT_COLOR,
  BTN_TEXT_COLOR_PRESSED,
  scrollBarCss,
  TEXT_COLOR
} from '../../../constants';

const columnTeamWidth = css`
  > div:nth-child(1) {
    width: 200px;
  }
  > div:nth-child(2) {
    width: 70px;
  }
  > div:nth-child(3), div:nth-child(4) {
    width: 80px;
  }
  > div:nth-child(5) {
    width: 90px;
  }
  > div:nth-child(6) {
    width: 90px;
  }
  > div:nth-child(7) {
    width: 128px;
  }
  > div:nth-child(8) {
    width: 70px;
    flex-grow: 1;
  }
  > div:nth-child(9) {
    width: 40px;
  }
`

const columnTeamStyleSwitch = css`
  > div:nth-child(3), div:nth-child(4) {
    width: 136px;
  }
  > div:nth-child(5) {
    width: 156px;
  }
  > div:nth-child(6) {
    width: 70px;
    flex-grow: 1;
  }
  > div:nth-child(7) {
    width: 40px;
    flex-grow: 0;
  }
`

const columnTeamCenterText = css`
  > div:nth-child(3), div:nth-child(4), > div:nth-child(5) {
    p {
      margin-left: 30px;
    }
  }
`

const tableHeaderPaddingTeam = css`
  > div:nth-child(1),
  > div:nth-child(3),
  > div:nth-child(4),
  > div:nth-child(5)
  {
    padding-top: 5px;
  }
  > div:nth-child(2),
  > div:nth-child(6),
  > div:nth-child(7)
  {
    padding-top: 10px;
  }
`

const tableHeaderPaddingTeamStyleSwitch = css`
  > div:nth-child(1),
  > div:nth-child(3)
  {
    padding-top: 5px;
  }
  > div:nth-child(4),
  > div:nth-child(5)
  {
    padding-top: 10px;
  }
  > div:nth-child(2) {
    padding-top: 10px;
  }
`

export const WrappedOrderFormation = styled.div`
  width: 90%;
  min-width: 885px;
  max-width: 1100px;
  height: 428px;
  position: absolute;
  top: -100px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;

  .controllers {
    display: flex;
    justify-content: center;
    padding-bottom: 16px;
  }

  .add_kit {
    display: flex;
    justify-content: center;
    margin-top: 32px;
    padding-bottom: 300px;

    button {
      font-size: 14px;
      width: max-content;
      height: 50px;
    }
  }

  @media all and (max-width: 1920px) {
    min-width: 974px;
    max-width: 1133px;
    height: auto;
    position: static;
    margin-top: 80px;

    .add_kit {
      margin-top: 16px;
      padding-bottom: 260px;

      button {
        height: 40px;
      }
    }
  }

  @media all and (max-width: 768px) {
    width: 100%;
    min-width: 300px;
    margin-top: 0;

    .controllers {
      flex-direction: column;
      justify-content: flex-start;
      gap: 16px;
      padding: 16px;
    }

    .add_kit {
      padding: 0 16px;
      padding-bottom: 200px;
      
      button {
        width: 100%;
      }
    }
  }
`

interface ITableLayoutProps {
  isGeneralQuantity: boolean;
}

export const WrappedTableLayout = styled.div<ITableLayoutProps>`
  .table {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;
    //max-height: 400px;

    .thead {
      height: 31px;
      background-color: ${BTN_BG_COLOR_DISABLED};
      display: flex;
      align-items: center;
      gap: 32px;
      padding: 0 16px;

      p {
        color: #333333;
        font-size: 12px;
        font-weight: 700;
      }
      
      ${columnTeamWidth};
      ${props => !props.isGeneralQuantity && columnTeamStyleSwitch}
      ${props => !props.isGeneralQuantity && columnTeamCenterText}

      .add_player_header {
        visibility: hidden;
      }
    }

    .tbody {
      //overflow-y: scroll;
      //width: calc(100% + 4px);
      ${scrollBarCss}
      
      > div:nth-last-child(1) {
        border-bottom-left-radius: 16px;
        border-bottom-right-radius: 16px;
      }
    }

    .add_player {
      margin-top: 8px;

      button {
        background: transparent;
        border: none;
        font-size: 14px;
      }
    }
  }

  @media all and (max-width: 1920px) {
    .table {
      .thead {
        gap: 24px;

        p {
          font-size: 11px;
        }
      }
    }
  }

  @media all and (max-width: 768px) {
    .table {
      flex-direction: row;
      background-color: ${BTN_BG_COLOR_DISABLED};
      overflow-x: scroll;
      padding: 16px 16px 16px 0;

      .thead {
        flex-direction: column;
        align-items: flex-end;
        gap: 16px;
        width: 87px;
        height: auto;
        padding: 16px;

        div:nth-child(n) {
          width: auto;
          text-align: end;
          height: 40px;
        }
        
        div:nth-child(1) {
          height: 40px;
        }

        div:nth-last-child(1) {
          height: 25px;
          display: block;
        }

        ${props => props.isGeneralQuantity ? tableHeaderPaddingTeam : tableHeaderPaddingTeamStyleSwitch}

        .add_player_header {
          display: block;
          order: -1;
          visibility: visible;

          button {
            background: transparent;
            border: none;
          }
        }
      }

      .tbody {
        display: flex;
        gap: 16px;
        width: auto;
        max-height: unset;
        overflow-y: auto;
      }

      .add_player {
        display: none;
      }
    }
  }
`

interface ITeam {
  isSelected: boolean;
  isGeneralQuantity?: boolean;
}

const WrappedTableRow = styled.div<ITeam>`
  display: flex;
  align-items: center;
  background: ${props => props.isSelected ? BG_GREEN : '#FFFFFF'};
  border-top: 1px solid ${BTN_BG_COLOR_DISABLED};
  width: 100%;
  gap: 32px;
  padding: 16px;
  color: ${props => props.isSelected ? 'white' : 'unset'};

  .screenshot {
    width: 100%;
  }

  .options {
    z-index: 300;
  }

  .table_text {
    font-size: 14px;
  }

  .remove {
    cursor: pointer;
  }

  @media all and (max-width: 1920px) {
    gap: 24px;
  }

  @media all and (max-width: 768px) {
    height: auto;
    width: 155px;
    border-radius: 16px;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    text-align: center;
    position: relative;

    > div:nth-child(4), > div:nth-child(5), > div:nth-child(8) {
      height: 40px;
    }

    .screenshot {
      width: 64px;
    }
  }
`

export const WrappedTableRowTeam = styled(WrappedTableRow)`
  height: 71px;
  ${columnTeamWidth}
  ${props => !props.isGeneralQuantity && columnTeamStyleSwitch}

  .garment_cell {
    display: flex;
    gap: 16px;

    &--desktop {
      display: block;
    }

    &--mobile {
      display: none;
    }
  }

  @media all and (max-width: 1920px) {
    height: 60px;

    .garment_cell {
      display: flex;
      gap: 8px;
    }
  }

  @media all and (max-width: 768px) {
    height: auto;

    .garment_cell {
      &--desktop {
        display: none;
      }

      &--mobile {
        display: block;
        width: 100%;
      }
    }

    > div:nth-last-child(1) {
      position: static;
      right: 0;
      width: 50%;
      height: 25px;
      order: -1;
    }

    > div:nth-child(1) {
      width: 128px;
    }

    > div:nth-child(5) {
      width: 136px;
    }
  }
`

interface ITableHeader {
  isOpen: boolean;
}

export const WrappedTableHeader = styled.div<ITableHeader>`
  .header {
    padding: 16px;
    background: ${props => props.isOpen ? TEXT_COLOR : BTN_TEXT_COLOR};
    border-radius: 16px 16px 0 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 16px;
    margin-bottom: ${props => props.isOpen ? 'unset' : '32px'};

    .left, .right {
      display: flex;
      gap: 32px;
      align-items: center;

      .garments {
        display: flex;
        gap: 16px;
      }

      .sport, .total_price {
        color: white;
        font-weight: 700;
        min-width: 68px;
      }

      .cell_wrap {
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }

      .copy_text {
        display: none;
      }
    }

    .right {
      gap: 16px;
    }
  }

  .desktop {
    display: flex;
  }

  .mobile {
    display: none;
  }

  @media all and (max-width: 1920px) {
    .header {
      padding:  12px 16px;
    }
  }

  @media all and (max-width: 768px) {
    .header {
      border-radius: unset;
      flex-direction: column;
      gap: 16px;
      padding: 16px;

      .first_row {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
          font-size: 14px;
        }

        .total_price {
          font-size: 18px;
        }

        .sport, .total_price {
          color: white;
          font-weight: 700;
          min-width: 68px;
        }

        .copy_text {
          display: block;
          color: white;
        }

        .cell_wrap {
          width: 40px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }
      }

      .garments {
        display: flex;
        width: 100%;
        gap: 16px;

        > div {
          flex-grow: 1;

          p {
            font-size: 14px;
          }
        }
      }

      .counter > div {
        width: 100%;

        p {
            font-size: 14px;
          }
      }
    }

    .desktop {
      display: none;
    }

    .mobile {
      display: flex;
    }
  }
`

const totalTextStyle = css`
  font-style: italic;
  font-weight: 400;
  font-size: 14px;

  span {
    color: ${BTN_TEXT_COLOR_PRESSED};
    font-weight: 700;
    font-size: 18px;
    font-style: normal;
    margin-left: 16px;
  }

  &--margin {
    margin-top: -11px;
  }
`

const discountTextStyle = css`
  .crossed {
    text-decoration-line: line-through;
    color: ${BTN_TEXT_COLOR};
    font-weight: 500;
  }
`

interface IProps {
  isDiscount: boolean;
}

export const WrappedOrderFormationPanelContent = styled.div<IProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 32px;

  .total_text {
    ${totalTextStyle}
    ${props => props.isDiscount && discountTextStyle}
  }

  .btn_text {
    font-size: 14px;
    color: white;
  }

  .btn_img {
    width: 20px;
  }

  @media all and (max-width: 1920px) {
    gap: 16px;
  }

  @media all and (max-width: 768px) {
    flex-direction: row;
    margin-top: 16px;

    .total_text {
      display: none;
    }

    > div {
      width: 100% !important;
    }
  }
`

export const WrappedOrderFormationPanelHead = styled.div<IProps>`
  width: 100%;
  text-align: center;

  .total_text {
    ${totalTextStyle};
    ${props => props.isDiscount && discountTextStyle}
    display: none;
  }

  @media all and (max-width: 768px) {
    .total_text {
      display: block;
    }
  }
`

export const WrappedData = styled.div`
  padding: 16px;
  margin: -150px 0 300px 0;
  background: white;

  .kit_wrap {
    margin-bottom: 20px;

    p {
      font-weight: 700;
      margin: 5px 0;
      font-size: .9em;
    }

    span {
      font-weight: 400;
      font-family: Montserrat, sans-serif;
      font-size: .9em;
    }

    h3 {
      margin: 16px 8px;
    }

    ul {
      p {
        margin-top: 14px;
      }
      li {
        font-family: Montserrat, sans-serif;
        font-size: .8em;
      }
    }

    .config_garment {
      margin: 8px 16px;
      font-size: 1em;
      text-decoration: underline;
    }

    .config_kit {
      margin-left: 32px;
    }

    hr {
      margin-top: 10px;
    }
  }
`
