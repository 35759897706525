import {ChangeEvent, FC, useCallback, useEffect, useState} from "react";
import {CheckboxStyle, Input} from "./Checkbox.style";
import {BTN_TEXT_COLOR, WHITE} from "../../../constants";

export interface CheckboxProps {
  id?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>, check: boolean) => void;
  checked: boolean;
  isActive?: boolean;
}

const Checkbox: FC<CheckboxProps> = (props) => {
  const {
    id = 'id',
    checked,
    isActive = true,
    onChange = () => undefined,
  } = props;

  const [isCheck, setIsCheck] = useState<boolean>(false);

  const changeCheckboxHandler = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    onChange(event, event.target.checked);
    setIsCheck(event.target.checked)
  }, [onChange]);

  useEffect(() => {
    setIsCheck(checked);
  }, [checked]);

  return (
      <CheckboxStyle
        isActive={isActive}
        htmlFor={id}
      >
        <Input
          id={id}
          type="checkbox"
          onChange={changeCheckboxHandler}
          data-cy="Checkbox"
        />
        {isCheck && <svg
          width="10"
          height="8"
          viewBox="0 0 10 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.5 4.34766L3.83333 6.84766L8.5 1.84766"
            stroke={isActive ? WHITE : BTN_TEXT_COLOR}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>}
      </CheckboxStyle>
  );
};

export default Checkbox;
