import React from 'react';
import {Box, styled} from "@mui/material";
import {ImageSizeInputCoord, ImageSizeInputField, ImageSizeInputNumber, ImageSizeInputText} from "./style";
import {IPicSize} from "../../store/types";
import {useTranslation} from "react-i18next";

const Wrapper = styled(Box)`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: 32px;

  @media all and (max-width: 768px) {
    margin-left: 0;
  }
`;

interface IProps {
  picSize: IPicSize;
}

const ImageSizeInput = ({ picSize }: IProps) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <ImageSizeInputText>
        { t('step_decoration.size_in_cm') }
      </ImageSizeInputText>
      <ImageSizeInputField>
        <ImageSizeInputNumber>{picSize.x}</ImageSizeInputNumber>
        <ImageSizeInputCoord>x</ImageSizeInputCoord>
      </ImageSizeInputField>
      <ImageSizeInputNumber>—</ImageSizeInputNumber>
      <ImageSizeInputField>
        <ImageSizeInputNumber>{picSize.y}</ImageSizeInputNumber>
        <ImageSizeInputCoord>y</ImageSizeInputCoord>
      </ImageSizeInputField>
    </Wrapper>
  );
};

export default ImageSizeInput;
