import { BTN_TEXT_COLOR_PRESSED } from './../../../constants';
import styled from 'styled-components';
import { BTN_BG_COLOR_DISABLED, BTN_BORDER_COLOR, BTN_TEXT_COLOR, BTN_TEXT_COLOR_DISABLED } from '../../../constants';

interface IProps {
  isRollsUp?: boolean;
  height: string;
  minWidth?: string;
  isDisable?: boolean;
  minOptionsWidth?: string;
  optionsWidth?: string;
  isFull?: boolean;
}

interface CustomFontsProps {
  fontFamily?: string;
}

export const WrappedMySelect = styled.div<IProps>`
  position: relative;

  .select {
    width: 100%;
    height: ${props => props.height};
    min-width: ${({minWidth}) => minWidth ? minWidth : 'unset'};
    position: relative;
    cursor: ${props => props.isDisable ? 'default' : 'pointer'};
    display: flex;
    align-items: center;
    padding: 8px 32px 8px 16px;
    background: ${props => props.isDisable ? BTN_BORDER_COLOR : '#FFFFFF'};
    border: 1px solid ${BTN_BORDER_COLOR};
    border-radius: 16px;
    color: ${BTN_TEXT_COLOR};
    font-weight: 700;
    font-size: 14px;

    img {
      position: absolute;
      right: 10px;
      top: 40%;
      width: 12px;
    }
  }

  .options {
    width: ${({optionsWidth}) => optionsWidth ? optionsWidth : '100%'};
    min-width: ${({minOptionsWidth}) => minOptionsWidth ? minOptionsWidth : '120px'};
    max-height: 180px;
    overflow-y: scroll;
    position: absolute;
    bottom: ${props => props.isRollsUp ? props.height : 'auto'};
    top: ${props => props.isRollsUp ? 'auto' : props.height};
    right: 0;
    z-index: 200;
    display: flex;
    background: #FFFFFF;
    box-shadow: 0 8px 33px -16px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    opacity: 0;
    transform: scale(.9);

    &__column {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 8px;
      padding: 8px;

      > div:nth-child(1), 
      > div:nth-child(2), 
      > div:nth-child(3), 
      > div:nth-child(4),
      > div:nth-child(5), 
      > div:nth-child(6), 
      > div:nth-child(7), 
      > div:nth-child(8),
      > div:nth-child(9) {
        // bugfix, do not delete
        width: 100%;
      }

      .column_heading {
        font-size: 14px;
        color: ${BTN_TEXT_COLOR};
        font-weight: 700;
        padding: 6px 0 10px 12px;
      }
    }
  }

  .background {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 199;
  }

  @media all and (max-width: 1920px) {
    .options {
      gap: 6px;

      &__column {
        gap: 4px;
      }
    }

    .select {
      padding: 6px 32px 6px 12px;

      img {
        width: 9px;
      }
    }
  }

  @media all and (max-width: 768px) {
    width: 100%;

    .options {
      max-height: ${props => props.isFull && 'auto'};
      height: ${props => props.isFull && 'auto'};
      max-width: ${props => props.isFull && '92vw'};
      width: ${props => props.isFull && '92vw'};
      right: auto;
      left: 0;
    }
  }
`

interface IOptionProps {
  isSelected: boolean;
}

export const WrappedMyOption = styled.div<IOptionProps>`
  cursor: pointer;
  color: ${props => props.isSelected ? BTN_TEXT_COLOR_DISABLED : BTN_TEXT_COLOR};
  font-weight: 400;
  font-size: 14px;
  background: ${props => props.isSelected ? BTN_BG_COLOR_DISABLED : 'white'};
  padding: 8px 16px;
  border-radius: 8px;

  &:hover {
    color: ${BTN_TEXT_COLOR_PRESSED};
  }

  @media all and (max-width: 1920px) {
    padding: 6px 12px;
  }
`

export const OptionCustomStyle = styled.div<CustomFontsProps>`
  font-family: ${props => props.fontFamily};
  font-size: 14px;
`
