import { observer } from 'mobx-react-lite';
import React, {useMemo} from 'react';
import InputText from '../../../components/UI/Input/InputText';
import InputRange from '../../../components/UI/Input/InputRange';
import MySelect from '../../../components/UI/Select/MySelect';
import {
  WrappedStepDecorationPanelContent,
  WrapperStepDecorationPosition,
} from './stepDecorationStyle';
import store from '../../../store/rootStore';
import ColorSlot from '../../../components/ColorPicker/ColorSlot';
import { EConfigGarment } from '../../../enums';
import { BTN_TEXT_COLOR, fileTypes, fonts, fontSizes, numberCategories, textCategories } from '../../../constants';
import { ColorHex } from '../../../db/types';
import SwitchButtons, { IButton } from '../../../components/UI/SwitchButtons/SwitchButtons';
import InputFile from '../../../components/UI/Input/InputFile';
import Button from '../../../components/UI/Buttons/Button';
import DialogConfirm from '../../../components/Popups/DialogConfirm';
import DialogWarning from '../../../components/Popups/DialogWarning';
import RemoveFile from '../../../components/UI/Buttons/RemoveFile';
import {getPositionOptions} from '../../../api/positionsApi';
import { useTranslation } from 'react-i18next';
import SizeSlider from "./SizeSlider";

interface IPositionsList {
  [key: string]: {
    [key: string]: IButton[];
  }
}

const StepDecorationPanelContent: React.FC = observer(() => {
  const { t } = useTranslation();
  const categoriesArr: IButton[] = t('step_decoration.categories', {returnObjects: true});
  const positionsList: IPositionsList = t('step_decoration.positions', {returnObjects: true});
  const {
    getDecorationJerseyValue,
    getDecorationShortsValue,
    stepCollection,
    stepDecoration,
    setDecorationJersey,
    setDecorationShorts,
    currentGarment,
    setIsColorPicker,
    setIsStrokeColor,
    setCurrentJerseyCategory,
    setCurrentShortsCategory,
    setDialogJSX,
    setOpenDialog,
    toggleTouchUI,
    isSkipNumber,
    isJerseySponsorIntersect,
    isShortsSponsorIntersect,
  } = store.app;

  // POSITION

  const getPositionList = (): IButton[] => {
    if (currentGarment === EConfigGarment.JERSEY) {
      return getPositionOptions(
        stepCollection.jersey,
        stepDecoration.currentJerseyChapter,
        positionsList.jersey
        );
    }
    let options: IButton[] = getPositionOptions(
      stepCollection.shorts,
      stepDecoration.currentShortsChapter,
      positionsList.shorts
      );
    if (stepDecoration.currentShortsChapter === 'number') {
      const skipNumber = isSkipNumber();
      if (skipNumber) {
        options = options.filter(item => item.name !== 'number');
      }
    }
    return options;
  }

  const setPosition = (value: string) => {
    const jersey = stepDecoration.currentJerseyChapter;
    const shorts = stepDecoration.currentShortsChapter;

    if (currentGarment === EConfigGarment.JERSEY) {
      setDecorationJersey(jersey, 'position', value);
      const isFile = getDecorationJerseyValue(jersey, 'imgFileName');
      const isIntersect = isJerseySponsorIntersect(jersey, value);
      if (isIntersect) {
        setDialogJSX(
          <DialogWarning
            mainText={t('ui.warning')}
            describeText={t('warnings.occupation')}
            handleWarning={() => {
              setOpenDialog(false);
            }}
          />
        );
        setOpenDialog(true);
      }

      // обновление модели
      if (jersey === 'lastName' || jersey === 'number' || isFile) {
        toggleTouchUI();
      }
    } else {
      setDecorationShorts(shorts, 'position', value);
      const isFile = getDecorationShortsValue(shorts, 'imgFileName');
      const isIntersect = isShortsSponsorIntersect(shorts, value);
      if (isIntersect) {
        setDialogJSX(
          <DialogWarning
            mainText={t('ui.warning')}
            describeText={t('warnings.occupation')}
            handleWarning={() => {
              setOpenDialog(false);
            }}
          />
        );
        setOpenDialog(true);
      }

      // обновление модели
      if (shorts === 'lastName' || shorts === 'number' || isFile) {
        toggleTouchUI();
      }
    }
  }

  const getPosition = (): string => {
    if (currentGarment === EConfigGarment.JERSEY) {
      return getDecorationJerseyValue(stepDecoration.currentJerseyChapter, 'position') as string;
    }
    return getDecorationShortsValue(stepDecoration.currentShortsChapter, 'position') as string;
  }

  // TEXT

  const setJerseyText = (value: string) => {
    setDecorationJersey(stepDecoration.currentJerseyChapter, 'text', value);
    toggleTouchUI();
  }

  const setShortsText = (value: string) => {
    setDecorationJersey(stepDecoration.currentShortsChapter, 'text', value);
    toggleTouchUI();
  }

  const getJerseyText = (): string => {
    return getDecorationJerseyValue(stepDecoration.currentJerseyChapter, 'text') as string;
  }

  const getShortsText = (): string => {
    return getDecorationJerseyValue(stepDecoration.currentShortsChapter, 'text') as string;
  }

  // FONT FAMILY

  const setFontFamily = (value: string) => {
    if (currentGarment === EConfigGarment.JERSEY) {
      setDecorationJersey(stepDecoration.currentJerseyChapter, 'fontFamily', value);
    } else {
      setDecorationJersey(stepDecoration.currentShortsChapter, 'fontFamily', value);
    }
    toggleTouchUI();
  }

  const getFontFamily = (): string => {
    if (currentGarment === EConfigGarment.JERSEY) {
      return getDecorationJerseyValue(stepDecoration.currentJerseyChapter, 'fontFamily') as string;
    }
    return getDecorationJerseyValue(stepDecoration.currentShortsChapter, 'fontFamily') as string;
  }

  const getFonts = (): IButton[] => {
    if (
      (currentGarment === EConfigGarment.JERSEY && stepDecoration.currentJerseyChapter === 'number') ||
      (currentGarment === EConfigGarment.SHORTS && stepDecoration.currentShortsChapter === 'number')
      ) {
        return fonts.filter(font => font.name !== 'masque');
      }
    return fonts;
  }

  // FONT SIZE

  const setFontSize = (value: string) => {
    if (currentGarment === EConfigGarment.JERSEY) {
      setDecorationJersey(stepDecoration.currentJerseyChapter, 'fontSize', value);
    } else {
      setDecorationShorts(stepDecoration.currentShortsChapter, 'fontSize', value);
    }
    toggleTouchUI();
  }

  const getFontSize = (): string => {
    if (currentGarment === EConfigGarment.JERSEY) {
      return getDecorationJerseyValue(stepDecoration.currentJerseyChapter, 'fontSize') as string;
    }
    return getDecorationShortsValue(stepDecoration.currentShortsChapter, 'fontSize') as string;
  }

  // COLOR

  const handleColorClick = (isStroke: boolean) => {
    setIsStrokeColor(isStroke);
    setIsColorPicker(true);
  }

  const getCurrentColor = (isStroke: boolean): ColorHex => {
    const field = isStroke ? 'strokeColor' : 'textColor';
    if (currentGarment === EConfigGarment.JERSEY) {
      return getDecorationJerseyValue(stepDecoration.currentJerseyChapter, field) as ColorHex;
    }
    return getDecorationShortsValue(stepDecoration.currentShortsChapter, field) as ColorHex;
  }

  // STROKE SIZE

  const setStrokeThickness = (value: string) => {
    if (currentGarment === EConfigGarment.JERSEY) {
      setDecorationJersey(stepDecoration.currentJerseyChapter, 'strokeThickness', value);
    } else {
      setDecorationShorts(stepDecoration.currentShortsChapter, 'strokeThickness', value);
    }
    toggleTouchUI();
  }

  const getStrokeThickness = (): number => {
    if (currentGarment === EConfigGarment.JERSEY) {
      return getDecorationJerseyValue(stepDecoration.currentJerseyChapter, 'strokeThickness') as number;
    }
    return getDecorationShortsValue(stepDecoration.currentShortsChapter, 'strokeThickness') as number;
  }

  // UPLOAD

  const handleUpload = (files: FileList | null) => {
    if (!files || !files.length || !fileTypes.includes(files[0].type)) {
      setDialogJSX(
        <DialogWarning
          mainText={t('ui.error')}
          describeText={t('warnings.invalid_format')}
          handleWarning={() => setOpenDialog(false)}
        />
      );
      setOpenDialog(true);
      return;
    }

    const img = new Image();
    const name = files[0].name;
    const reader = new FileReader();
    reader.onload = function (e) {
      if (e?.target?.result) {
        img.src = e.target.result as string;
      }
    };
    img.onload = () => {
      const jersey = stepDecoration.currentJerseyChapter;
      const shorts = stepDecoration.currentShortsChapter;
      if (currentGarment === EConfigGarment.JERSEY) {
        setDecorationJersey(jersey, 'imgPath', img.src);
        setDecorationJersey(jersey, 'imgFileName', name);
        setDecorationJersey(jersey, 'baseWidth', img.width);
        setDecorationJersey(jersey, 'baseHeight', img.height);

        // intersection warning
        const isIntersect = isJerseySponsorIntersect(jersey, getDecorationJerseyValue(jersey, 'position') as string | number);
        if (isIntersect) {
          setDialogJSX(
            <DialogWarning
              mainText={t('ui.warning')}
              describeText={t('warnings.occupation')}
              handleWarning={() => {
                setOpenDialog(false);
              }}
            />
          );
          setOpenDialog(true);
        }
      } else {
        if (shorts === 'teamLogo') {
          setDecorationJersey(shorts, 'imgPath', img.src);
          setDecorationJersey(shorts, 'imgFileName', name);
          setDecorationJersey(shorts, 'baseWidth', img.width);
          setDecorationJersey(shorts, 'baseHeight', img.height);
        } else {
          setDecorationShorts(shorts, 'imgPath', img.src);
          setDecorationShorts(shorts, 'imgFileName', name);
          setDecorationShorts(shorts, 'baseWidth', img.width);
          setDecorationShorts(shorts, 'baseHeight', img.height);

          // intersection warning
          const isIntersect = isShortsSponsorIntersect(shorts, getDecorationShortsValue(shorts, 'position') as string | number);
          if (isIntersect) {
            setDialogJSX(
              <DialogWarning
                mainText={t('ui.warning')}
                describeText={t('warnings.occupation')}
                handleWarning={() => {
                  setOpenDialog(false);
                }}
              />
            );
            setOpenDialog(true);
          }
        }
      }
    }
    reader.readAsDataURL(files[0]);
    toggleTouchUI();
  }

  const getFileName = (): string => {
    if (currentGarment === EConfigGarment.JERSEY) {
      return getDecorationJerseyValue(stepDecoration.currentJerseyChapter, 'imgFileName') as string;
    }
    if (stepDecoration.currentShortsChapter === 'teamLogo') {
      return getDecorationJerseyValue(stepDecoration.currentShortsChapter, 'imgFileName') as string;
    }
    return getDecorationShortsValue(stepDecoration.currentShortsChapter, 'imgFileName') as string;
  }

  const removeFile = () => {
    setDialogJSX(
      <DialogConfirm
        mainText={t('warnings.delete_layer')}
        confirmText={t('ui.yes')}
        declineText={t('ui.no')}
        handleConfirm={() => {
          setOpenDialog(false);
          const jersey = stepDecoration.currentJerseyChapter;
          const shorts = stepDecoration.currentShortsChapter;
          if (currentGarment === EConfigGarment.JERSEY) {
            setDecorationJersey(jersey, 'imgPath', null);
            setDecorationJersey(jersey, 'imgFileName', null);
          } else {
            if (shorts === 'teamLogo') {
              setDecorationJersey(shorts, 'imgPath', null);
              setDecorationJersey(shorts, 'imgFileName', null);
            } else {
              setDecorationShorts(shorts, 'imgPath', null);
              setDecorationShorts(shorts, 'imgFileName', null);
            }
          }

          toggleTouchUI();
        }}
        handleDecline={() => setOpenDialog(false)}
      />
    );
    setOpenDialog(true);
  }

  // CATEGORIES (mobile only)

  const isCategoriesVisible = (): boolean => {
    if (currentGarment === EConfigGarment.JERSEY) {
      if (stepDecoration.currentJerseyChapter === 'number' ||
      stepDecoration.currentJerseyChapter === 'lastName') {
        return true;
      }
    } else if (currentGarment === EConfigGarment.SHORTS && stepDecoration.currentShortsChapter === 'number') {
      return true;
    }
    return false;
  }

  const getCategories = (): IButton[] => {
    if (stepDecoration.currentJerseyChapter === 'lastName') {
      return categoriesArr.filter(item => textCategories.includes(item.name));
    }
    return categoriesArr.filter(item => numberCategories.includes(item.name));
  }

  const setCurrentCategory = (item: string) => {
    if (currentGarment === EConfigGarment.JERSEY) {
      setCurrentJerseyCategory(item);
    } else {
      setCurrentShortsCategory(item);
    }
  }

  // RENDER

  const renderPosition = (): JSX.Element => {
    return <MySelect
      options={getPositionList()}
      onOptionClick={setPosition}
      isRollsUp
      height={'40px'}
      minWidth={'150px'}
      currentProp={getPosition()}
    />
  }

  const renderJerseyText = (): JSX.Element => {
    const chapter = stepDecoration.currentJerseyChapter;
    return <InputText
      value={getJerseyText()}
      handleChange={setJerseyText}
      isNumberOnly={chapter === 'number'}
      width={chapter === 'number' ? '50px' : '120px'}
      maxLength={chapter === 'number' ? 3 : 20}
    />
  }

  const renderShortsText = (): JSX.Element => {
    return <InputText
      value={getShortsText()}
      handleChange={setShortsText}
      isNumberOnly
      width={'50px'}
      maxLength={3}
    />
  }

  const renderFontFamily = (): JSX.Element => {
    return <MySelect
      options={getFonts()}
      isFull
      onOptionClick={setFontFamily}
      isRollsUp
      height={'40px'}
      minWidth={'170px'}
      minOptionsWidth={'175px'}
      currentProp={getFontFamily()}
      isFontAsOption
    />
  }

  const renderFontSize = (): JSX.Element => {
    return <MySelect
      options={fontSizes}
      onOptionClick={setFontSize}
      isRollsUp
      height={'40px'}
      minWidth={'62px'}
      currentProp={getFontSize()}
      minOptionsWidth='36px'
    />
  }

  const renderTextColor = (): JSX.Element => {
    return <ColorSlot
      color={getCurrentColor(false)}
      isSelected={false}
      handleClick={() => handleColorClick(false)}
    />
  }

  const renderStrokeWidth = (width: string): JSX.Element => {
    return <InputRange
      handleChange={setStrokeThickness}
      width={width}
      value={getStrokeThickness()}
    />
  }

  const renderStrokeColor = (): JSX.Element => {
    return <ColorSlot
      color={getCurrentColor(true)}
      isSelected={false}
      handleClick={() => handleColorClick(true)}
    />
  }

  const renderUploadImage = (name: string): JSX.Element => {
    if (name) {
      return <Button
        background='transparent'
        color={BTN_TEXT_COLOR}
        style={{
          border: 'none',
          cursor: 'default',
          fontSize: '14px',
          wordBreak: 'break-all',
          width: '100%',
        }}
      >
        {name}
        <RemoveFile
          handleClick={removeFile}
        />
      </Button>
    }
    return <div className='upload_image'>
      <InputFile
        handleChange={handleUpload}
        height={'40px'}
      />
      <p className="supports">{t('step_decoration.supports')}:<br/>PNG,JPG,SVG</p>
    </div>
  }

  const renderSizeSlider = (width: string, name: string | null): JSX.Element | null => {
    if (!name) {
      return null;
    }

    return <SizeSlider
      width={width}
    />;
  }

  const renderMobileLogo = (name: string): JSX.Element => <>
    <WrapperStepDecorationPosition
      style={{ flexDirection: name ? 'row' : 'column'}}
    >
      {renderPosition()}
      {renderUploadImage(name)}
    </WrapperStepDecorationPosition>
    {renderSizeSlider('100%', name)}
  </>;

  // tools

  const isNumberOrName = useMemo((): boolean => {
    const jersey = stepDecoration.currentJerseyChapter;
    const shorts = stepDecoration.currentShortsChapter;
    if (currentGarment === EConfigGarment.JERSEY) {
      if (jersey === 'number' || jersey === 'lastName') {
        return true;
      }
    } else {
      if (shorts === 'number' || shorts === 'lastName') {
        return true;
      }
    }
    return false;
  }, [currentGarment, stepDecoration.currentJerseyChapter, stepDecoration.currentShortsChapter])

  const getCurrentCategory = useMemo(() => {
    if (currentGarment === EConfigGarment.JERSEY) {
      return stepDecoration.currentJerseyCategory;
    }
    return stepDecoration.currentShortsCategory;
  }, [currentGarment, stepDecoration.currentJerseyCategory, stepDecoration.currentShortsCategory])

  return (
    <WrappedStepDecorationPanelContent isNumberOrName={isNumberOrName}>
      <div className='desktop'>
        {renderPosition()}
        {
          isNumberOrName ?
          <>
            {
              currentGarment === EConfigGarment.JERSEY ?
              renderJerseyText() :
              renderShortsText()
            }
            {renderFontFamily()}
            {renderFontSize()}
            {renderTextColor()}
            {renderStrokeWidth('100px')}
            {renderStrokeColor()}
          </> :
            <>
              {renderUploadImage(getFileName())}
              {renderSizeSlider('269px', getFileName())}
            </>
        }
      </div>
      <div className='mobile'>
        {
          isCategoriesVisible() &&
          <div className='categories'>
            <SwitchButtons
              buttons={getCategories()}
              currentSelected={getCurrentCategory}
              handleClick={setCurrentCategory}
            />
          </div>
        }
        <div className='mobile__content'>
          {
            isNumberOrName ?
            <>
              {
                getCurrentCategory === 'position' && renderPosition()
              }
              {
                currentGarment === EConfigGarment.JERSEY ?
                ((getCurrentCategory === 'number' || getCurrentCategory === 'text') &&
                  renderJerseyText()) :
                (getCurrentCategory === 'number' &&
                  renderShortsText())
              }
              {
                getCurrentCategory === 'font' &&
                <>
                  {renderFontFamily()}
                  {renderFontSize()}
                  {renderTextColor()}
                </>
              }
              {
                getCurrentCategory === 'stroke' &&
                  <>
                    {renderStrokeWidth('150px')}
                    {renderStrokeColor()}
                  </>
              }
            </> :
              renderMobileLogo(getFileName())
          }
        </div>
      </div>
    </WrappedStepDecorationPanelContent>
  );
})

export default StepDecorationPanelContent;
