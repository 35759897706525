import {ColorHex, IColor, IColorsData} from "./types";

export const colors: IColor = {
  // black white

  white: '#FFFFFF',
  black: '#000000',
  grey: '#c9c7d5',
  darkGrey: '#505158',

  // red
  red: '#b71a32',
  crimson: '#7f2436',
  orange: '#f15f2d',
  peach: '#fc9a6c',
  brown: '#533232',

  // blue
  blue: '#134d8b',
  powderBlue: '#baddf3',
  airForceBlue: '#557EB3',
  blueLilac: '#553E6A',
  lilac: '#9c98e1',
  darkBlue: '#0d2a3f',
  blueTeal: '#55B4E8',
  blueWater: '#59bec9',

  // yellow
  yellow: '#f5d926',
  lightYellow: '#f2e663',
  melon: '#efa321',

  // pink
  lightPink: '#f4587b',
  pink: '#df4278',
  razzmatazz: '#f02d87',
  thistle: '#ecb1d6',
  violet: '#7a4183',

  // green
  darkGreen: '#076452',
  green: '#457029',
  lightGreen: '#61c748',
  seaGreen: '#8de2c6',
  grass: '#B5E663',
  swamp: '#00aa13',

  // beige
  lightBeige: '#cdc89f',
  lightBrown: '#ab6154',
  beige: '#ac9c68',
}

export const colorsPantone: IColor = {
  // black white

  white: 'White',
  grey: 'Gray 3C',
  darkGrey: 'Gray 11C',
  black: 'Black',

  // red

  red: '200C',
  crimson: '1955C',
  orange: '165C',
  peach: '163C',
  brown: '1815C',

  // blue

  powderBlue: '7457C',
  blueTeal: '2985C',
  airForceBlue: '7454C',
  blue: '7462C',
  darkBlue: '7463C',
  blueWater: '2227C',

  // yellow

  yellow: 'Yellow C',
  lightYellow: '7406C',
  melon: '130C',

  // pink

  thistle: '217C',
  lightPink: '1777C',
  pink: '205C',
  razzmatazz: '213C',

  // violet

  lilac: '2705C',
  blueLilac: '7447C',
  violet: '7662C',

  // green

  darkGreen: '336C',
  green: '371C',
  lightGreen: '7488C',
  seaGreen: '7478C',
  grass: '374C',
  swamp: '2423C',

  // beige

  lightBeige: '4515C',
  lightBrown: '7522C',
  beige: '4545C',
};

export const getPantoneByHex = (hex: ColorHex): ColorHex => {
  for (const color in colors) {
    if (colors[color] === hex) {
      return colorsPantone[color];
    }
  }
  return 'White';
};

export const getColorsData = (filter?: ColorHex[]): IColorsData => {
  let arr = Object.values(colors);
  if (filter) {
    arr = arr.filter((item) => filter.includes(item));
  }
  let arrFirstHalf = null;
  let arrSecondHalf = null;
  if (arr.length > 8) {
    arrFirstHalf = arr.slice(0, (Math.ceil(arr.length / 2)));
    arrSecondHalf = arr.slice(arrFirstHalf.length);
  }

  return {
    arr,
    arrFirstHalf,
    arrSecondHalf,
  }
}
