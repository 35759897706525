import React from 'react';
import { WrappedSwitch } from './switchButtonsStyle';
import imgLeft from '../../../assets/UI/switch_left.svg';
import imgRight from '../../../assets/UI/switch_right.svg';
import { IButton } from './SwitchButtons';

interface IProps {
  textDesktop?: string;
  textMobile?: string;
  option1: IButton;
  option2: IButton;
  currentSelected: string;
  handleClick: () => void;
}

const Switch: React.FC<IProps> = ({textDesktop, textMobile, option1, option2, currentSelected, handleClick}) => {

  return (
    <WrappedSwitch
      isOption1={currentSelected === option1.name}
    >
      <p className="green_text switch_desktop">{textDesktop}</p>
      <p className="green_text switch_mobile">{textMobile}</p>
      <div className='cont'
        onClick={handleClick}
      >
        <p className='option1'>
          {option1.value}
        </p>
        <img
          src={currentSelected === option1.name ? imgLeft : imgRight} 
          alt='switch'
        />
        <p className='option2'>
          {option2.value}
        </p>
      </div>
    </WrappedSwitch>
  );
}

export default Switch;