import React from 'react';
import { WrappedInputInfo } from './style';

interface IProps {
  background?: string;
  padding?: string;
  borderColor?: string;
  handleClick?: () => void;
  title?: string;
  width?: string;
  style?: React.CSSProperties;
}

const InputInfo: React.FC<IProps> = ({children, background, padding, borderColor, handleClick, title, width, style}) => {

  return (
    <WrappedInputInfo
      background={background}
      padding={padding}
      borderColor={borderColor}
      isClickable={Boolean(handleClick)}
      onClick={handleClick}
      title={title}
      width={width}
      style={{...style}}
    >
      {children}
    </WrappedInputInfo>
  );
}

export default InputInfo;