import React from 'react';
import { WrappedMyOption } from './selectStyle';

interface IProps {
  handleClick: () => void;
  isSelected: boolean;
}

const MyOption: React.FC<IProps> = ({children, handleClick, isSelected}) => {

  return (
    <WrappedMyOption onClick={handleClick} isSelected={isSelected}>
      {children}
    </WrappedMyOption>
  );
}

export default MyOption;