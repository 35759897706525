import React, { useEffect, useMemo, useState } from 'react';
import { SwiperSlide } from 'swiper/react';
import CardModel from '../../../components/Cards/CardModel';
import Carousel from '../../../components/UI/Carousel/Carousel';
import store from '../../../store/rootStore';
import { observer } from 'mobx-react-lite';
import { WrappedStepDesignPanel } from './stepDesignStyle';
import { getDesignsList, getModelPath, isAnyDesignSelected } from '../../../api/modelsApi';
import { IDesignParams } from '../../../db/types';
import { EConfigDesigns } from '../../../enums';
import { useTranslation } from 'react-i18next';

const StepDesignPanel: React.FC = observer(() => {
  const { t } = useTranslation();
  const {setCurrentDesign, stepCollection, currentGarment, stepDesignName, setShortsDefaultColors} = store.app;
  const [sliders, setSliders] = useState<number>(6);

  useEffect(() => {
    // if no one design selected, then select 'default' or first of them
    const designsList: IDesignParams[] = getDesignsList(stepCollection[currentGarment]);
    if (designsList.length === 0) {
      //console.log('ERROR: no designs found');
      return;
    }
    if (!isAnyDesignSelected(designsList, stepDesignName)) {
      //console.log('No designs selected. Select default design');
      setCurrentDesign(EConfigDesigns.DEFAULT);
    }
  }, [currentGarment, setCurrentDesign, stepCollection, stepDesignName])

  const designsList = useMemo((): IDesignParams[] => {
    const result = getDesignsList(stepCollection[currentGarment]);
    if (result.length < 6) {
      setSliders(result.length);
    } else {
      setSliders(6);
    }
    return result;
  }, [currentGarment, stepCollection])

  const getPreviewImagePath = (designName: string | undefined): string => {
    return `${getModelPath(stepCollection[currentGarment])}designs/${designName?.toLowerCase()}/preview.png`;
  }

  const getHeaderText = (text: string | undefined) => {
    if (text === 'Default') {
      return t('step_design.default');
    }
    return text || 'Design';
  }

  const setDesign = (name: EConfigDesigns) => {
    if (stepDesignName !== name) {
      setCurrentDesign(name);
      setShortsDefaultColors();
    }
  }

  return (
    <WrappedStepDesignPanel>

      <div className='desktop'>
        <Carousel
          slidesPerView={sliders}
          totalItems={designsList.length}
        >
          {
            designsList.map((item, index) => <SwiperSlide
              key={index}
              onClick={() => setDesign(item.name || EConfigDesigns.DEFAULT)}
            >
              <CardModel
                isSelected={item.name === stepDesignName}
                imgPath={getPreviewImagePath(item.name)}
                header={getHeaderText(item.name)}
                isStepDesign
              />
            </SwiperSlide>)
          }
        </Carousel>
      </div>

      <div className='mobile'>
        {
          designsList.map((item, index) => <CardModel
            key={index}
            handleClick={() => setDesign(item.name || EConfigDesigns.DEFAULT)}
            isSelected={item.name === stepDesignName}
            imgPath={getPreviewImagePath(item.name)}
            header={getHeaderText(item.name)}
            isStepDesign
          />)
        }
      </div>

    </WrappedStepDesignPanel>
  );
})

export default StepDesignPanel;
