import { IButton } from "../components/UI/SwitchButtons/SwitchButtons";
import { positions } from "../db/positions";
import {IPosition, IPositions, IPositionsJersey, IPositionsShorts, IPositionWithActualSize} from "../db/types";
import { EConfigGarment } from "../enums";
import {IPicSize} from "../store/types";
import {PIXELS_PER_CM} from "../constants";

interface IPositionsList {
  [key: string]: IButton[];
}

export const convertCmToPixels = (cm: number): number => {
  return Math.round(PIXELS_PER_CM * cm);
}

export const modifyPositionSize = (position: IPosition, picSize: IPicSize | undefined): IPositionWithActualSize => {
  return {
    x: position.x,
    y: position.y,
    width: convertCmToPixels(picSize?.x ?? 1),
    height: convertCmToPixels(picSize?.y ?? 1),
  }
}

export const getPositionOptions = (modelId: string, chapter: string, positionsList: IPositionsList): IButton[] => {
  const found: IPositions | undefined = positions.find(item => item.modelIds.includes(modelId));
  if (found && found.positions) {
    const pos = found.positions as (IPositionsJersey & IPositionsShorts);
    switch (chapter) {
      case 'lastName': {
        const result = Object.keys(pos.lastName);
        return positionsList.lastName.filter(item => result.includes(item.name));
      }
      case 'number': {
        const result = Object.keys(pos.number);
        return positionsList.number.filter(item => result.includes(item.name));
      }
      case 'teamLogo': {
        const result = Object.keys(pos.logo);
        return positionsList.logo.filter(item => result.includes(item.name));
      }
      default: {
        let result: string[];
        if (chapter === 'sponsorLogo3' && pos.sponsor3) {
          result = Object.keys(pos.sponsor3);
        } else {
          result = Object.keys(pos.sponsors);
        }
        return positionsList.sponsors.filter(item => result.includes(item.name));
      }
    }
  } else {
    //console.log('ERROR: positions not found, model id: ', modelId);
  }
  return [];
}

export const getPositionCoords = (modelId: string, chapter: string, position: string, garment: EConfigGarment, picSize?: IPicSize): IPositionWithActualSize | undefined => {
  const found: IPositions | undefined = positions.find(item => item.modelIds.includes(modelId));
  if (found && found.positions) {
    switch (chapter) {
      case 'lastName': {
        const pos = found.positions as IPositionsJersey;
        return pos.lastName[position];
      }
      case 'number': {
        return found.positions.number[position];
      }
      case 'teamLogo': {
        const logo = found.positions.logo as {[key: string]: IPosition};
        return modifyPositionSize(logo[position], picSize);
      }
      default: {
        if (garment === EConfigGarment.JERSEY) {
          const sponsors = found.positions.sponsors as {[key: string]: IPosition};
          return modifyPositionSize(sponsors[position], picSize);
        } else {
          const pos = found.positions as IPositionsShorts;
          let sponsors: {[key: string]: IPosition};
          if (chapter === 'sponsorLogo3' && pos.sponsor3) {
            sponsors = pos.sponsor3;
          } else {
            sponsors = pos.sponsors;
          }
          return modifyPositionSize(sponsors[position], picSize);
        }
      }
    }
  }
  return undefined;
}

export interface MinMaxReturn {
  minX: number;
  maxX: number;
  minY: number;
  maxY: number;
}

export const getPositionMinMax = (modelId: string, garment: EConfigGarment, chapter: string, position: string): MinMaxReturn | undefined => {
  const found: IPositions | undefined = positions.find(item => item.modelIds.includes(modelId));
  if (found && found.positions) {
    let result: IPosition;
    switch (chapter) {
      case 'teamLogo': {
        const logo = found.positions.logo as {[key: string]: IPosition};
        result = logo[position];
        break;
      }
      case 'sponsorLogo1':
      case 'sponsorLogo2':
      case 'sponsorLogo3': {
        if (garment === EConfigGarment.JERSEY) {
          const sponsors = found.positions.sponsors as {[key: string]: IPosition};
          result = sponsors[position];
        } else {
          const pos = found.positions as IPositionsShorts;
          let sponsors: {[key: string]: IPosition};
          if (chapter === 'sponsorLogo3' && pos.sponsor3) {
            sponsors = pos.sponsor3;
          } else {
            sponsors = pos.sponsors;
          }
          result = sponsors[position];
        }
      }
    }
    // @ts-ignore
    if (result) {
      return {
        minX: result.minMaxX ? result.minMaxX[0] : 0,
        maxX: result.minMaxX ? result.minMaxX[1] : 10,
        minY: result.minMaxY ? result.minMaxY[0] : 0,
        maxY: result.minMaxY ? result.minMaxY[1] : 10,
      }
    }
  } else {
    //console.log('ERROR: positions not found, model id: ', modelId);
  }

  return undefined;
}
