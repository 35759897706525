import { colors } from "../db/colors"
import { IBaseTexturesPath } from "./canvasStore"
import {IStepCollection, IStepColor, IStepDecoration} from "./types"
import {EConfigDesigns} from "../enums";
import {IColorScheme} from "../db/types";

export const stepCollectionDefaults: IStepCollection = {
  modelsList: [], // current model list in UI
  collectionId: '0', // current selected collection. If user switched the garment, this value is calculated
  jersey: '2', // model id's for every garment. If user has switched the collection, then the model list is updated
  shorts: '102',
  socks: '200',
  gender: {
    jersey: 'm', // calculating when the model is changing
    shorts: 'm', // at 'order formation', if one of models are Woman, then set kit gender as Woman and lock the switcher
    socks: 'm',
  }
}

export const stepDecorationDefaults: IStepDecoration = {
  currentJerseyChapter: 'number',
  currentShortsChapter: 'number',
  currentJerseyCategory: 'position',
  currentShortsCategory: 'position',
  isColorPicker: false,
  isStrokeColor: false,
  jersey: {
    number: {
      text: '11',
      position: 'number',
      fontSize:	'3',
      fontFamily: 'rubik',
      textColor: colors.white,
      strokeThickness: 0,
      strokeColor: colors.black,
    },
    lastName: {
      text: '',
      position: 'name_1',
      fontSize:	'3',
      fontFamily: 'masque',
      textColor: colors.white,
      strokeThickness: 0,
      strokeColor: colors.black,
    },
    teamLogo: {
      position: 'chest',
      imgPath: null,
      imgFileName: null,
      baseWidth: 0,
      baseHeight: 0,
      picSize: {
        x: 7.5,
        y: 7.5,
        unit: 'cm'
      }
    },
    sponsorLogo1: {
      position: 'back_c1',
      imgPath: null,
      imgFileName: null,
      baseWidth: 0,
      baseHeight: 0,
      picSize: {
        x: 9,
        y: 6,
        unit: 'cm'
      }
    },
    sponsorLogo2: {
      position: 'back_c2',
      imgPath: null,
      imgFileName: null,
      baseWidth: 0,
      baseHeight: 0,
      picSize: {
        x: 15,
        y: 10.5,
        unit: 'cm'
      }
    },
    sponsorLogo3: {
      position: 'chest_c1',
      imgPath: null,
      imgFileName: null,
      baseWidth: 0,
      baseHeight: 0,
      picSize: {
        x: 22,
        y: 15,
        unit: 'cm'
      }
    },
  },
  shorts: {
    teamLogo: {
      position: 'front',
      picSize: {
        x: 7.5,
        y: 7.5,
        unit: 'cm'
      }
    },
    number: {
      position: 'number',
      fontSize:	'3',
      textColor: colors.white,
      strokeThickness: 0,
      strokeColor: colors.black,
    },
    sponsorLogo1: {
      position: 'front_1',
      imgPath: null,
      imgFileName: null,
      baseWidth: 0,
      baseHeight: 0,
      picSize: {
        x: 7.5,
        y: 7.5,
        unit: 'cm'
      }
    },
    sponsorLogo2: {
      position: 'front_2',
      imgPath: null,
      imgFileName: null,
      baseWidth: 0,
      baseHeight: 0,
      picSize: {
        x: 7.5,
        y: 7.5,
        unit: 'cm'
      }
    },
    sponsorLogo3: {
      position: 'front_2',
      imgPath: null,
      imgFileName: null,
      baseWidth: 0,
      baseHeight: 0,
      picSize: {
        x: 12,
        y: 12,
        unit: 'cm'
      }
    },
  },
}

export const colorSchemeDefault: IColorScheme = {
  base: colors.blue,
  details: '#f7f7f7',
  logo: '#f7f7f7',
  design1: colors.darkBlue,
  design2: colors.powderBlue,
  design3: colors.airForceBlue,
}

export const colorSchemeDefault2 = {
  // Allen, Sebastien
  ...colorSchemeDefault,
  design2: colors.crimson,
}

export const colorSchemeDefaultMishel = {
  ...colorSchemeDefault,
  design1: colors.crimson,
}

export const colorSchemeDefaultRod = {
  ...colorSchemeDefault,
  design2: colors.white,
}

export const colorSchemeDefaultTony = {
  ...colorSchemeDefault,
  design1: colors.crimson,
  design2: colors.darkBlue,
}

export const stepColorDefaults: IStepColor = {
  jersey: [
    {
      design: EConfigDesigns.DEFAULT,
      scheme: colorSchemeDefault,
    }
  ],
  shorts: colorSchemeDefault,
  socks: {
    base: colors.blue,
    logo: colors.white,
  },
}

export const colorChaptersDefaults = {
  jersey: 'base',
  shorts: 'base',
  socks: 'base',
}

export const defaultDecoration = {
  numberText: null,
  lastNameText: null,
}

export const defaultBaseTextures: IBaseTexturesPath = {
  base: '',
  normal: '',
  roughness: '',
  ao: null,
}

export const pixiOptions = {
  width: 2048,
  height: 2048,
  autoStart: false,
  preserveDrawingBuffer: true,
}

export const defaultSprites = {jersey: {}, shorts: {}, socks: {}};
