import {Box, styled, Typography} from "@mui/material";
import {BTN_BORDER_COLOR, BTN_TEXT_COLOR, TEXT_COLOR} from "../../constants";

export const ImageSizeInputText = styled(Typography)`
  font-size: 14px;
  font-family: Montserrat, sans-serif;
  font-weight: 700;
  color: ${BTN_TEXT_COLOR};
  line-height: 1;
`;

export const ImageSizeInputField = styled(Box)`
  display: flex;
  height: 56px;
  padding: 0 16px;
  align-items: center;
  justify-content: space-between;
  width: 77px;
  border-radius: 16px;
  border: 1px solid ${BTN_BORDER_COLOR};
  background: white;

  @media all and (max-width: 1920px) {
    height: 40px;
  }

  @media all and (max-width: 768px) {
    height: 32px;
    padding: 0 8px;
  }
`;

export const ImageSizeInputNumber = styled(Typography)`
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  color: ${TEXT_COLOR};
`;

export const ImageSizeInputCoord = styled(Typography)`
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: 700;
  color: ${BTN_BORDER_COLOR};
`;
