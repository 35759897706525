import React from 'react';
import SwitchButtons, { IButton } from '../../../components/UI/SwitchButtons/SwitchButtons';
import { WrappedStepColorPanelHead } from './stepColorStyle';
import {observer} from 'mobx-react-lite';
import store from '../../../store/rootStore';

interface IProps {
  colorProps?: {
    options: IButton[];
    handleOptionClick: (option: string) => void;
  }
}

const StepColorPanelHead: React.FC<IProps> = observer(({colorProps}) => {
  const {currentColorChapter, currentGarment} = store.app;

  return (
    <WrappedStepColorPanelHead>
      <SwitchButtons
        buttons={colorProps?.options}
        currentSelected={currentColorChapter[currentGarment]}
        handleClick={colorProps?.handleOptionClick}
      />
    </WrappedStepColorPanelHead>
  );
})

export default StepColorPanelHead;
