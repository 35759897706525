import { TFunction } from 'react-i18next';
import {
  getModelsCollectionId,
  getTotalColors,
  getSizeValue,
} from '../../modelsApi';
import {
  getPrice,
  getCatalog,
} from '../../catalogApi';
import { getSportName } from '../../sportsApi';
import { getCollectionName } from '../../collectionsApi';
import {EConfigGarment} from '../../../enums';
import {
  IGarmentConfig,
  IIndividualConfig,
  IJerseyParams,
  IOrderFormation,
  IPicJersey,
  IPicShortsSponsor,
  IShortsParams,
  IOrderInfo,
  ISelectedGarments,
  IScreenshot
} from '../../../store/types';

import {
  IJerseyConfigToSend,
  IShortsConfigToSend,
  ISocksConfigToSend,
  IGarmentToSend,
  IPlayerConfigToSend,
  IOrderItemToSend,
  IUserDataToSend,
  ImageToSend,
  IPosition,
  IKitConfigToSend,
  IDynamicObj,
  IScreens,
  IPrepareScreenshot,
  IColorSchemeWithPantone,
} from './types';
import { fonts } from '../../../constants';
import { IColorScheme } from '../../../db/types';
import {getPantoneByHex} from "../../../db/colors";

const prepareColorScheme = (colorScheme: IColorScheme, totalColors: number): IColorSchemeWithPantone => {
  const preparedColorScheme: any = {};

  const arr = Object.keys(colorScheme);
  arr.length = totalColors;

  arr.forEach(item => {
    preparedColorScheme[item] = {
      pantone: getPantoneByHex(colorScheme[item as keyof IColorScheme] || 'White'),
      hex: colorScheme[item as keyof IColorScheme] || '#FFFFFF',
    }
  });

  return preparedColorScheme;
};

const getGarmentCount = (garments: IGarmentConfig[], garment: EConfigGarment): number => {
  const garmentObj = garments.find(el => el.garment === garment);
  return garmentObj?.quantityGarments || 0;
}

const prepareJerseyConfig = (kitConfig: IOrderFormation, t: TFunction<"translation", undefined>): IJerseyConfigToSend => {
  const { modelConfig, sportId, teamConfig } = kitConfig;
  const { designName, stepCollection, stepDecoration, colorSchemes } = modelConfig;
  const { jersey: modelId } = stepCollection;
  const { jersey: decoration } = stepDecoration;
  const { jersey: colorScheme } = colorSchemes;
  const {
    number,
    lastName,
    teamLogo,
    sponsorLogo1,
    sponsorLogo2,
    sponsorLogo3,
  } = decoration;
  const collectionId = getModelsCollectionId(modelId);
  const catalog = getCatalog(sportId, EConfigGarment.JERSEY, collectionId);
  const modelName = catalog && t(`info.${catalog.infoId}.modelName`);

  const numberPos: IPosition[] = t('step_decoration.positions.jersey.number', { returnObjects: true, lng: 'en' });
  const lastNamePos: IPosition[] = t('step_decoration.positions.jersey.lastName', { returnObjects: true, lng: 'en' });
  const teamLogoPos: IPosition[] = t('step_decoration.positions.jersey.logo', { returnObjects: true, lng: 'en' });
  const sponsorsPos: IPosition[] = t('step_decoration.positions.jersey.sponsors', { returnObjects: true, lng: 'en' });

  const totalColors = getTotalColors(modelId, designName);

  const jerseyQuantity = teamConfig.reduce((acc, { garments }) => acc += getGarmentCount(garments, EConfigGarment.JERSEY), 0);
  const price = getPrice(sportId, EConfigGarment.JERSEY, jerseyQuantity) || 0;
  const index = colorScheme.findIndex((k) => k.design === designName);
  const scheme = colorScheme[index].scheme;

  return {
    model: modelName ? modelName : `Model id: ${modelId}`,
    collection: getCollectionName(collectionId),
    price,
    design: designName,
    colors: prepareColorScheme(scheme, totalColors),
    decorations: {
      number: {
        textColor: {
          pantone: getPantoneByHex(number.textColor),
          hex: number.textColor,
        },
        fontFamily: fonts.find(item => item.name === number.fontFamily)?.value,
        fontSize: number.fontSize,
        position: numberPos.find(p => p.name === number.position)?.value,
        strokeThickness: number.strokeThickness,
        strokeColor: {
          pantone: getPantoneByHex(number.strokeColor),
          hex: number.strokeColor,
        },
      },
      lastName: {
        textColor: {
          pantone: getPantoneByHex(lastName.textColor),
          hex: lastName.textColor,
        },
        fontFamily: fonts.find(item => item.name === lastName.fontFamily)?.value,
        fontSize: lastName.fontSize,
        position: lastNamePos.find(p => p.name === lastName.position)?.value,
        strokeThickness: lastName.strokeThickness,
        strokeColor: {
          pantone: getPantoneByHex(lastName.strokeColor),
          hex: lastName.strokeColor,
        }
      },
      ...((teamLogo.imgFileName && {
        teamLogo: {
          fileName: teamLogo.imgFileName,
          position: teamLogoPos.find(p => p.name === teamLogo.position)?.value,
          size: JSON.stringify(teamLogo.picSize)
        }
      }) || {}),
      ...((sponsorLogo1.imgFileName && {
        sponsorLogo1: {
          fileName: sponsorLogo1.imgFileName,
          position: sponsorsPos.find(p => p.name === sponsorLogo1.position)?.value,
          size: JSON.stringify(sponsorLogo1.picSize)
        }
      }) || {}),
      ...((sponsorLogo2.imgFileName && {
        sponsorLogo2: {
          fileName: sponsorLogo2.imgFileName,
          position: sponsorsPos.find(p => p.name === sponsorLogo2.position)?.value,
          size: JSON.stringify(sponsorLogo2.picSize)
        }
      }) || {}),
      ...((sponsorLogo3.imgFileName && {
        sponsorLogo3: {
          fileName: sponsorLogo3.imgFileName,
          position: sponsorsPos.find(p => p.name === sponsorLogo3.position)?.value,
          size: JSON.stringify(sponsorLogo3.picSize)
        }
      }) || {})
    },
  };
};

const prepareShortsConfig = (kitConfig: IOrderFormation, t: TFunction<"translation", undefined>): IShortsConfigToSend => {
  const { modelConfig, sportId, teamConfig } = kitConfig;
  const { stepCollection, stepDecoration, colorSchemes, designName } = modelConfig;
  const { shorts: colorScheme } = colorSchemes;
  const { shorts: decoration } = stepDecoration;
  const { shorts: modelId } = stepCollection;
  const {
    number,
    teamLogo,
    sponsorLogo1,
    sponsorLogo2,
    sponsorLogo3,
  } = decoration;
  const collectionId = getModelsCollectionId(modelId);
  const catalog = getCatalog(sportId, EConfigGarment.SHORTS, collectionId);
  const modelName = catalog && t(`info.${catalog.infoId}.modelName`);

  const numberPos: IPosition[] = t('step_decoration.positions.shorts.number', { returnObjects: true, lng: 'en' });
  const teamLogoPos: IPosition[] = t('step_decoration.positions.shorts.logo', { returnObjects: true, lng: 'en' });
  const sponsorsPos: IPosition[] = t('step_decoration.positions.shorts.sponsors', { returnObjects: true, lng: 'en' });

  const totalColors = getTotalColors(modelId, designName);

  const shortsQuantity = teamConfig.reduce((acc, { garments }) => acc += getGarmentCount(garments, EConfigGarment.SHORTS), 0);
  const price = getPrice(sportId, EConfigGarment.SHORTS, shortsQuantity) || 0;

  return {
    model: modelName ? modelName : `Model id: ${modelId}`,
    price,
    collection: getCollectionName(collectionId),
    colors: prepareColorScheme(colorScheme, totalColors),
    decorations: {
      number: {
        textColor: {
          pantone: getPantoneByHex(number.textColor),
          hex: number.textColor,
        },
        position: numberPos.find(p => p.name === number.position)?.value,
        fontSize: number.fontSize,
        strokeThickness: number.strokeThickness,
        strokeColor: {
          pantone: getPantoneByHex(number.strokeColor),
          hex: number.strokeColor,
        }
      },
      teamLogo : {
        position: teamLogoPos.find(p => p.name === teamLogo.position)?.value,
        size: JSON.stringify(teamLogo.picSize)
      },
      ...((sponsorLogo1.imgFileName && {
        sponsorLogo1: {
          fileName: sponsorLogo1.imgFileName,
          position: sponsorsPos.find(p => p.name === sponsorLogo1.position)?.value,
          size: JSON.stringify(sponsorLogo1.picSize)
        }
      }) || {}),
      ...((sponsorLogo2.imgFileName && {
        sponsorLogo2: {
          fileName: sponsorLogo2.imgFileName,
          position: sponsorsPos.find(p => p.name === sponsorLogo2.position)?.value,
          size: JSON.stringify(sponsorLogo2.picSize)
        }
      }) || {}),
      ...((sponsorLogo3.imgFileName && {
        sponsorLogo3: {
          fileName: sponsorLogo3.imgFileName,
          position: sponsorsPos.find(p => p.name === sponsorLogo3.position)?.value,
          size: JSON.stringify(sponsorLogo3.picSize)
        }
      }) || {})
    }
  };
};

const prepareSocksConfig = (kitConfig: IOrderFormation, t: TFunction<"translation", undefined>): ISocksConfigToSend => {
  const { modelConfig, teamConfig, sportId } = kitConfig;
  const { stepCollection, colorSchemes, designName } = modelConfig;
  const { socks: colorScheme } = colorSchemes;
  const { socks: modelId } = stepCollection;
  const collectionId = getModelsCollectionId(modelId);

  const totalColors = getTotalColors(modelId, designName);

  const socksQuantity = teamConfig.reduce((acc, { garments }) => acc += getGarmentCount(garments, EConfigGarment.SOCKS), 0);
  const price = getPrice(sportId, EConfigGarment.SOCKS, socksQuantity) || 0;

  return {
    model: t('garments.socks'),
    price,
    collection: getCollectionName(collectionId),
    colors: prepareColorScheme(colorScheme, totalColors),
  };
};

const prepareGarment = (garment: IGarmentConfig): IGarmentToSend => {
  const { garment: garmentName, catalog, size, quantityGarments, price } = garment;

  return {
    garment: garmentName,
    catalogName: catalog,
    size: getSizeValue(size),
    quantity: quantityGarments,
    sum: (price * quantityGarments).toFixed(2),
  };
};

const preparePlayer = (player: IIndividualConfig): IPlayerConfigToSend => {
  const {
    number: playerNumber,
    lastName: playerLastName,
    quantityKits,
    garments,
    gender,
  } = player;

  const sum =  quantityKits * garments.reduce((acc, { price, quantityGarments, isInclude }) => {
    if (isInclude) {
      return acc + price * quantityGarments;
    }
    return acc;
  }, 0);

  return {
    number: playerNumber,
    lastName: playerLastName,
    quantityKits: quantityKits,
    sum: sum.toFixed(2),
    gender: gender === 'm' ? 'male' : 'female',
    garments: garments.filter(item => item.isInclude && item.quantityGarments > 0).map(item => prepareGarment(item)),
  };
};

const prepareKitConfig = (kitConfig: IOrderFormation, t: TFunction<"translation", undefined>): IKitConfigToSend =>{
  const result: IKitConfigToSend = {} as IKitConfigToSend;

  const { teamConfig } = kitConfig;
  const players = teamConfig.map(player => preparePlayer(player));
  const selectedGarments = getSelectedGarments(players);

  if(selectedGarments.jersey) result.jersey = prepareJerseyConfig(kitConfig, t);
  if(selectedGarments.shorts) result.shorts = prepareShortsConfig(kitConfig, t);
  if(selectedGarments.socks) result.socks = prepareSocksConfig(kitConfig, t);

  return result;
};

const prepareImages = (jersey: IJerseyParams, shorts: IShortsParams) => {
  const images: ImageToSend[] = [];

  const addImage = (image: IPicJersey | IPicShortsSponsor) => {
    if (image?.imgFileName) {
      images.push(image as ImageToSend);
    }
  };

  addImage(jersey.teamLogo);
  addImage(jersey.sponsorLogo1);
  addImage(jersey.sponsorLogo2);
  addImage(jersey.sponsorLogo3);
  addImage(shorts.sponsorLogo1);
  addImage(shorts.sponsorLogo2);
  addImage(shorts.sponsorLogo3);

  return images;
};

const prepareOrderDetailsToSend = (orderData: IOrderFormation[], t: TFunction<"translation", undefined>): IOrderItemToSend[] =>
  orderData.map(kitConfig => {
    const { name, sportId, teamConfig } = kitConfig;
    const sports: { [key: string]: string } = t('sports', { returnObjects: true, lng: 'en' });

    const preparedKitConfig = prepareKitConfig(kitConfig, t);
    const preparedTeamConfig = teamConfig.map(player=>{
      const garments = player.garments.map(garment => {
        return {...garment, price: preparedKitConfig[garment.garment]?.price || 0}
      })
      return {...player, garments};
    })
    const players = preparedTeamConfig.map(player => preparePlayer(player));

    const kitSum = players.reduce((acc, { sum })=> acc+= Number(sum), 0);

    return {
      kitName: name,
      kitSum: kitSum.toFixed(2),
      sportName: sports[getSportName(sportId)],
      kitConfig: preparedKitConfig,
      players
    };
  });

const getSelectedGarments = (players: IPlayerConfigToSend[]): ISelectedGarments =>{
  const getGarments = (
    garments: IGarmentToSend[],
    reqGarment: EConfigGarment
  ) => garments.find(garment => garment.garment === reqGarment);

  const hasJersey = !!players.find(player => getGarments(player.garments, EConfigGarment.JERSEY));
  const hasShorts = !!players.find(player => getGarments(player.garments, EConfigGarment.SHORTS));
  const hasSocks = !!players.find(player => getGarments(player.garments, EConfigGarment.SOCKS));

  return {
    [EConfigGarment.JERSEY]: hasJersey,
    [EConfigGarment.SHORTS]: hasShorts,
    [EConfigGarment.SOCKS]: hasSocks,
  }
}

const dataURLToBlob = (dataURL: string) => {
  const BASE64_MARKER = ';base64,';

  if (dataURL.indexOf(BASE64_MARKER) === -1) {
      const parts1 = dataURL.split(',');
      const contentType1 = parts1[0].split(':')[1];
      const raw1 = decodeURIComponent(parts1[1]);

      return new Blob([raw1], { type: contentType1 });
  }

  const parts2 = dataURL.split(BASE64_MARKER);
  const contentType2 = parts2[0].split(':')[1];
  const raw2 = window.atob(parts2[1]);
  const rawLength = raw2.length;

  const uInt8Array = new Uint8Array(rawLength);

  for (let i = 0; i < rawLength; ++i) {
    uInt8Array[i] = raw2.charCodeAt(i);
  }

  return new Blob([uInt8Array], { type: contentType2 });
};

const prepareScreenshot = (orders: IOrderFormation[]): IPrepareScreenshot => {
  const kitNames: IDynamicObj = {};
  const screens: IScreens[] = [];

  orders.forEach((order) => {
    const { modelConfig, name, teamConfig } = order;
    const { modelsScreenshot } = modelConfig;
    // kitNames
    // todo ESLint: Unexpected control character(s) in regular expression: \x0a.(no-control-regex)
    // eslint-disable-next-line no-control-regex
    const regExpr = new RegExp('[. \n]+', 'g');
    const parsedName = name.replaceAll(regExpr, '');
    kitNames[parsedName] = name;
    // screens
    const players = teamConfig.map(player => preparePlayer(player));
    const selectedGarments = getSelectedGarments(players);

    if(selectedGarments.jersey){
      const jerseyScreen = modelsScreenshot.jersey;
      if (jerseyScreen) {
        for(let side in jerseyScreen){
          if(jerseyScreen[side as keyof IScreenshot]){
            const data = {
              imageName: `jersey_${side}.png`,
              imageFile: dataURLToBlob(jerseyScreen[side as keyof IScreenshot]),
              kitName: parsedName
            }
            screens.push(data)
          }
        }
      }
    }

    if(selectedGarments.shorts){
      const shortsScreen = modelsScreenshot.shorts;
      if (shortsScreen) {
        for(let side in shortsScreen){
          if(shortsScreen[side as keyof IScreenshot]){
            const data = {
              imageName: `short_${side}.png`,
              imageFile: dataURLToBlob(shortsScreen[side as keyof IScreenshot]),
              kitName: parsedName
            }
            screens.push(data);
          }
        }
      }
    }
  })

  return { kitNames, screens };
}

const formOrderData = (userData: IOrderInfo, orderData: IOrderFormation[], t: TFunction<"translation", undefined>) => {
  const { firstName, lastName, email, phone, userMessage } = userData;

  const formData = new FormData();

  const userDataSend: IUserDataToSend = {
    firstName: firstName,
    lastName: lastName,
    email: email,
    phone: phone,
    message: userMessage,
  };
  const orderDataSend = prepareOrderDetailsToSend(orderData, t);

  formData.append('userData', JSON.stringify(userDataSend));
  formData.append('orderDetails', JSON.stringify(orderDataSend));

  const { kitNames, screens } = prepareScreenshot(orderData);

  formData.append('kitNames', JSON.stringify(kitNames));
  screens.forEach(screen=>{
    formData.append(
      `${screen.kitName}[]`,
      screen.imageFile,
      screen.imageName
    )
  })

  orderData.forEach((item) => {
    const { modelConfig } = item;
    const { stepDecoration } = modelConfig;
    const { jersey, shorts } = stepDecoration;
    const images: ImageToSend[] = prepareImages(jersey, shorts);

    images.forEach((img) => {
      formData.append(
        'images[]',
        dataURLToBlob(img.imgPath),
        img.imgFileName,
      );
    });
  });

  return formData;
};

export default formOrderData;
