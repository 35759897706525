import React from 'react';
import { WrappedPillSwitch } from './switchButtonsStyle';

type Option = {
  value: string;
  label: string;
  isDisabled?: boolean;
}

interface IProps {
  option1: Option;
  option2: Option;
  current: string;
  handleOptionClick: (value: string) => void;
}

const PillSwitch: React.FC<IProps> = ({option1, option2, handleOptionClick, current}) => {

  const handleClick = (value: string) => {
    if (option1.isDisabled || option2.isDisabled) {
      return;
    }
    handleOptionClick(value);
  }

  return (
    <WrappedPillSwitch
      isOption1={current === option1.value}
      isOption1Disabled={option1.isDisabled}
      isOption2Disabled={option2.isDisabled}
    >
      <div
        className='half halfLeft'
        onClick={() => handleClick(option1.value)}
      >
        <p>{option1.label}</p>
      </div>
      <div
        className='half halfRight'
        onClick={() => handleClick(option2.value)}
      >
        <p>{option2.label}</p>
      </div>
    </WrappedPillSwitch>
  );
}

export default PillSwitch;