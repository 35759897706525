import { observer } from 'mobx-react-lite';
import React from 'react';
import { WrappedOrderFormationPanelContent } from './orderStyle';
import store from '../../../store/rootStore';
import InputInfo from '../../../components/UI/Input/InputInfo';
import imgCard from '../../../assets/UI/card.svg';
import { TEXT_COLOR } from '../../../constants';
import OrderDetails from '../../../components/Popups/OrderDetails';
import { useTranslation } from 'react-i18next';
import {convertPrice} from "../../../utils";

const OrderFormationPanelContent: React.FC = observer(() => {
  const { t } = useTranslation();
  const {getAllKitsDiscountSum, setDialogJSX, setOpenDialog} = store.app;
  const sum = getAllKitsDiscountSum();

  const handleClick = () => {
    setDialogJSX(
      <OrderDetails/>
    );
    setOpenDialog(true);
  }

  return (
    <WrappedOrderFormationPanelContent isDiscount={false}>
      <p className="total_text">{t('order_formation.total_price')}
        <span className='crossed'>{convertPrice(sum)}</span>
      </p>
      <InputInfo
        style={{
          background: TEXT_COLOR,
          borderColor: TEXT_COLOR,
          width: 'max-content',
          height: '50px',
          justifyContent: 'center',
          gap: '10px',
        }}
        handleClick={handleClick}
      >
        <img src={imgCard} alt='card' className='btn_img'/>
        <p className='btn_text'>{t('order_formation.add_to_card')}</p>
      </InputInfo>
    </WrappedOrderFormationPanelContent>
  );
})

export default OrderFormationPanelContent;
