import {EDecorationChapters} from '../enums';

export const formatChapter = (chapter: EDecorationChapters): string => {
  const format = chapter.split(' ').map((word, index) => {
    if (index) {
      return word.charAt(0).toUpperCase() + word.substring(1);
    }
    return word.charAt(0).toLowerCase() + word.substring(1);
  });
  return format.join('');
}
