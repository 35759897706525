import React, { useCallback, useMemo } from 'react';
import LayoutMenuPanel from './LayoutMenuPanel';
import { WrappedMenuPanel } from './menuPanelStyle';
import store from '../../store/rootStore';
import { observer } from 'mobx-react-lite';
// import StepCollectionPanelHead from '../Steps/collection/StepCollectionPanelHead';
import StepCollectionPanelContent from '../Steps/collection/StepCollectionPanelContent';
import StepSportPanel from '../Steps/sport/StepSportPanel';
import StepDesignPanel from '../Steps/design/StepDesignPanel';
import StepColorPanelContent from '../Steps/color/StepColorPanelContent';
import StepColorPanelHead from '../Steps/color/StepColorPanelHead';
import { EConfigColorSchemes, EConfigGarment, EDecorationChapters } from '../../enums';
import { getColorScheme } from '../../api/colorApi';
import StepDecorationPanelHead from '../Steps/decoration/StepDecorationPanelHead';
import StepDecorationPanel from '../Steps/decoration/StepDecorationPanel';
import OrderFormationPanelContent from '../Steps/order/OrderFormationPanelContent';
import OrderFormationPanelHead from '../Steps/order/OrderFormationPanelHead';
import { useTranslation } from 'react-i18next';
import { formatChapter } from '../../api/decorationApi';
import { IButton } from '../../components/UI/SwitchButtons/SwitchButtons';

const MenuPanel: React.FC = observer(() => {
  const { t } = useTranslation();
  const schemesArr: IButton[] = t('step_color', {returnObjects: true});
  const chaptersJersey: IButton[] = t('step_decoration.jersey_chapters', {returnObjects: true});
  const chaptersShorts: IButton[] = t('step_decoration.shorts_chapters', {returnObjects: true});
  const {
    currentStep,
    currentGarment,
    stepCollection,
    stepDesignName,
    setColorChapter,
    setCurrentJerseyChapter,
    setCurrentShortsChapter
  } = store.app;

  const getHeadComponent = () => {
    switch (currentStep) {
      case 1: return null;
      case 3: return <StepColorPanelHead colorProps={getColorProps}/>
      case 4: return <StepDecorationPanelHead decorationProps={getChapterProps()}/>
      case 5: return <OrderFormationPanelHead/>
      default: return null;
    }
  }

  const getContentComponent = () => {
    switch (currentStep) {
      case 0: return <StepSportPanel/>
      case 1: return <StepCollectionPanelContent/>
      case 2: return <StepDesignPanel/>
      case 3: return <StepColorPanelContent/>
      case 4: return <StepDecorationPanel/>
      case 5: return <OrderFormationPanelContent/>
      default: return null;
    }
  }

  // step color

  const getSchemes = useMemo(() => {
    // list of colors, like 'Base', 'Details'... etc
    return getColorScheme({
      modelId: stepCollection[currentGarment],
      design: stepDesignName,
      garment: currentGarment,
      schemesArr,
    });
  }, [currentGarment, schemesArr, stepCollection, stepDesignName])

  const handleSetScheme = useCallback((item: string) => {
    setColorChapter(item as EConfigColorSchemes);
  }, [setColorChapter])

  const getColorProps = useMemo(() => {
    return {
      options: getSchemes,
      handleOptionClick: handleSetScheme,
    }
  }, [getSchemes, handleSetScheme])

  // step decoration

  const handleSetChapter = useCallback((item: string) => {
    const chapter: string = formatChapter(item as EDecorationChapters);
    if (currentGarment === EConfigGarment.JERSEY) {
      setCurrentJerseyChapter(chapter);
    } else {
      setCurrentShortsChapter(chapter);
    }
  }, [currentGarment, setCurrentJerseyChapter, setCurrentShortsChapter])

  const getChapterProps = () => {
    return {
      chapters: currentGarment === EConfigGarment.JERSEY ? chaptersJersey : chaptersShorts,
      handleChapterClick: handleSetChapter,
    }
  }

  return (
    <WrappedMenuPanel isOrderFormation={currentStep === 5}>
      <LayoutMenuPanel
        headComponent={getHeadComponent()}
        contentComponent={getContentComponent()}
        colorProps={getColorProps}
        decorationProps={getChapterProps()}
      />
    </WrappedMenuPanel>
  );
})

export default MenuPanel;
