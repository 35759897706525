import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import httpApi from 'i18next-http-backend';
import {LANGUAGE_DEFAULT} from "./constants";

i18n
.use(initReactI18next)
.use(httpApi)
.init({
    fallbackLng: LANGUAGE_DEFAULT,
    supportedLngs: ['en', 'fr'],
    detection: {
      order: ['path', 'subdomain', 'localStorage', 'cookie', 'htmlTag'],
      caches: ['localStorage'],
    },
    backend: {
      loadPath: '/locales/{{lng}}/translation.json',
    },
    react: {
      useSuspense: false,
    }
  });

export default i18n;
