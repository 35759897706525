import React, { useCallback, useEffect } from 'react';
import { WrappedOrderFormation } from './orderStyle';
import { observer } from 'mobx-react-lite';
import store from '../../../store/rootStore';
import Switch from '../../../components/UI/SwitchButtons/Switch';
import TableLayout from './TableLayout';
import Button from '../../../components/UI/Buttons/Button';
import imgPlus from '../../../assets/UI/plus.svg';
import { handleSetSport } from '../sport/handleSetSport';
import { EConfigGarment } from '../../../enums';
import { useTranslation } from 'react-i18next';
import DialogWarning from '../../../components/Popups/DialogWarning';
import {getKitGarmentsQuantity} from "../../../api/modelsApi";

const OrderFormation: React.FC = observer(() => {
  const { t } = useTranslation();
  const {
    isGeneralQuantity,
    toggleGeneralQuantity,
    result,
    getPlayer,
    stepSportId,
    isModelLoading,
    copyKit,
    putDataFromResultToState,
    setNeedModelRefresh,
    setOpenPanel,
    setModelList,
    setSportId,
    setCurrentGarment,
    setStep,
    setKitDone,
    setDialogJSX,
    setOpenDialog
  } = store.app;

  useEffect(() => {
    setKitDone();
  }, [setKitDone])

  const handleQuantityChoice = () => {
    toggleGeneralQuantity();
  }

  const addNewKit = () => {
    const max = handleSetSport(store.app, stepSportId || '0');
    if (max) {
      renderMaxDialog();
    }
  }

  const handleCopyKit = (kitId: string) => {
    const max = copyKit(kitId);
    if (max) {
      renderMaxDialog();
    }
  }

  const renderMaxDialog = useCallback(() => {
    setDialogJSX(
      <DialogWarning
        mainText={t('ui.warning')}
        describeText={t('warnings.max_kits')}
        handleWarning={() => {
          setOpenDialog(false);
        }}
      />
    );
    setOpenDialog(true);
  }, [setDialogJSX, setOpenDialog, t])

  const handleEditKit = useCallback((kitId: string, sportId: string, selectedPlayerId: string, isModelLoading: boolean) => {
    if (isModelLoading) return;
    //console.log('CLICK EDIT KIT ' + kitId + ', player: ' + selectedPlayerId);
    putDataFromResultToState(kitId, selectedPlayerId);
    setNeedModelRefresh(true);
    setOpenPanel(true);
    setModelList(sportId);
    setSportId(sportId);
    setCurrentGarment(EConfigGarment.JERSEY);
    setModelList(sportId);
    setStep(1);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <WrappedOrderFormation>

      <div className="controllers">
        <Switch
          textDesktop={t('order_formation.method')}
          textMobile={t('order_formation.quantity')}
          option1={{
            name: 'general',
            value: t('order_formation.general'),
          }}
          option2={{
            name: 'individual',
            value: t('order_formation.individual'),
          }}
          currentSelected={isGeneralQuantity ? 'general' : 'individual'}
          handleClick={handleQuantityChoice}
        />
      </div>

      {
        result.map(kit => {
          const player = getPlayer(kit.id, kit.selectedPlayerId);
          if (!player) {
            return null;
          }

          return <TableLayout
            key={kit.id}
            data={kit}
            kitsCount={result.length}
            playerData={player}
            allGarmentsQuantity={getKitGarmentsQuantity(kit.teamConfig)}
            handleCopyKit={() => handleCopyKit(kit.id)}
            handleEditKit={() => handleEditKit(kit.id, kit.sportId, kit.selectedPlayerId, isModelLoading)}
          />
        })
      }

      <div className='add_kit'>
        <Button
          handleClick={addNewKit}
          isActive={!isModelLoading}
        >
          <img src={imgPlus} alt='plus'/>
          <span>{t('order_formation.add_new_kit')}</span>
        </Button>
      </div>
    </WrappedOrderFormation>
  );
})

export default OrderFormation;
