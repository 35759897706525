import React from 'react';
import { WrappedColorSlot } from './colorStyle';

interface IProps {
  color: string;
  isSelected: boolean;
  handleClick?: () => void;
}

const ColorSlot: React.FC<IProps> = ({color, isSelected, handleClick}) => {
  return (
    <WrappedColorSlot 
      color={color} 
      isSelected={isSelected}
      onClick={handleClick}
    />
  );
}

export default ColorSlot;