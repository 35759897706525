import React from 'react';
import ColorPicker from '../../../components/ColorPicker/ColorPicker';
import {observer} from 'mobx-react-lite';
import store from '../../../store/rootStore';
import {ColorHex, IColorScheme} from '../../../db/types';
import {WrappedStepColorPanelContent} from './stepColorStyle';
import {getColorsData} from "../../../db/colors";
import {EConfigGarment} from "../../../enums";
import {SOCKS_COLORS_FILTER} from "../../../constants";

const StepColorPanelContent: React.FC = observer(() => {
  const {
    setColor,
    stepColor,
    currentGarment,
    currentColorChapter,
    stepDesignName,
  } = store.app;

  const {
    loadColors,
  } = store.canvas;

  const handleClick = (color: ColorHex) => {
    setColor(color);
    loadColors(stepColor, stepDesignName);
  }

  const getCurrentColor = () => {
    const chapter = currentColorChapter[currentGarment];
    if (currentGarment === EConfigGarment.JERSEY) {
      const index = stepColor.jersey.findIndex((k) => k.design === stepDesignName);
      return stepColor.jersey[index].scheme[chapter as keyof IColorScheme];
    } else {
      return stepColor[currentGarment][chapter as keyof IColorScheme];
    }
  }

  const colorsData = getColorsData(currentGarment === EConfigGarment.SOCKS ? SOCKS_COLORS_FILTER : undefined);

  return (
    <WrappedStepColorPanelContent>
      <div className="desktop">
        <ColorPicker
          handleClick={handleClick}
          currentColor={getCurrentColor()}
          data={colorsData}
          isCarousel
        />
      </div>

      <div className="mobile">
        <ColorPicker
          handleClick={handleClick}
          currentColor={getCurrentColor()}
          data={colorsData}
        />
      </div>
    </WrappedStepColorPanelContent>
  );
})

export default StepColorPanelContent;
