import React, { useEffect, useRef, useState } from 'react';
import {OptionCustomStyle, WrappedMySelect} from './selectStyle';
import arrowDown from '../../../assets/UI/chevron_down.svg';
import arrowUp from '../../../assets/UI/chevron_up.svg';
import MyOption from './MyOption';
import { IButton } from '../SwitchButtons/SwitchButtons';
import { gsap } from 'gsap';
import { AnimPopover } from '../../../constants';

export interface IOptionColumn {
  heading: string;
  data: IButton[];
}

interface IProps {
  options?: IButton[];
  isFull?: boolean;
  optionsColumns?: IOptionColumn[];
  onOptionClick?: (option: string, oldOption: string) => void;
  isRollsUp?: boolean;
  height?: string;
  minWidth?: string;
  currentProp?: string;
  dontStopPropagation?: boolean;
  minOptionsWidth?: string;
  optionsWidth?: string;
  style?: React.CSSProperties;
  isFontAsOption?: boolean;
}

const MySelect: React.FC<IProps> = ({
    options = [],
    onOptionClick,
    isRollsUp,
    height = '32px',
    minWidth,
    isFull = false,
    currentProp,
    optionsColumns,
    dontStopPropagation,
    minOptionsWidth,
    optionsWidth,
    style,
    isFontAsOption,
  }) => {
  const [isDisable, setIsDisable] = useState<boolean>(false);
  const [current, setCurrent] = useState<number>(0);
  const [columnsData, setColumnsData] = useState<IButton[]>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const optionsRef = useRef(null);

  useEffect(() => {
    const concat: IButton[] = [];
    optionsColumns?.forEach(item => concat.push(...item.data));
    setColumnsData(concat);

    if (currentProp) {
      let index: number = -1;
      if (concat.length) {
        index = concat.findIndex(item => item.name === currentProp);
      } else {
        index = options.findIndex(item => item.name === currentProp);
      }
      if (index > -1) {
        setIsDisable(false);
        setCurrent(index);
      } else {
        setIsDisable(true);
        setIsOpen(false);
      }
    }

    if (isOpen) {
      gsap.to(optionsRef.current, AnimPopover);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProp, isOpen])

  const handleOptionClick = (index: number) => {
    const oldIndex = current;
    setCurrent(index);
    setIsOpen(false);
    if (onOptionClick) {
      if (columnsData.length) {
        onOptionClick(columnsData[index].name, columnsData[oldIndex].name);
      } else {
        onOptionClick(options[index].name, options[oldIndex].name);
      }
    }
  }

  const handleSelectClick = (e: React.PointerEvent<HTMLDivElement>) => {
    if (!dontStopPropagation) {
      e.stopPropagation();
    }
    if (!isDisable) {
      setIsOpen(prev => !prev);
    }
  }

  return (
    <WrappedMySelect
      isFull={isFull}
      height={height}
      minWidth={minWidth}
      minOptionsWidth={minOptionsWidth}
      isRollsUp={isRollsUp}
      isDisable={isDisable}
      optionsWidth={optionsWidth}
      style={style}
    >
      {
        isOpen && <div
          className='background'
          onClick={handleSelectClick}
        />}
      <div
        className="select"
        onClick={handleSelectClick}
      >
        {optionsColumns ? columnsData[current]?.value : options[current]?.value}
        <img src={isOpen ? arrowUp : arrowDown} alt='^'/>
      </div>
      {
        isOpen && <div className="options" ref={optionsRef}>
        {
          optionsColumns ?
          optionsColumns.map((column, indexColumn) => <div className="options__column" key={indexColumn}>
              <div className="column_heading">{column.heading}</div>
              {
                column.data.map((item, rowIndex) => {
                const index = columnsData.findIndex(obj => obj.name === item.name);
                return <MyOption
                  key={rowIndex}
                  handleClick={() => handleOptionClick(index)}
                  isSelected={current === index}
                >
                {item.value}
                </MyOption>})
              }
            </div>) :

          <div className="options__column">
            {
              options.map((item, index) => <MyOption
                key={index}
                handleClick={() => handleOptionClick(index)}
                isSelected={current === index}
              >
                {
                  isFontAsOption
                  ? <OptionCustomStyle fontFamily={item.value}>
                      {item.value}
                    </OptionCustomStyle>
                    : item.value
                }
              </MyOption>)
            }
          </div>
        }
        </div>
      }
    </WrappedMySelect>
  );
}

export default MySelect;
