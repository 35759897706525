import React from 'react';
import { configSport } from '../../../constants';
import CardMenu from '../../../components/Cards/CardMenu';
import { WrappedStepSportPanel } from './sportStyle';
import store from '../../../store/rootStore';
import { handleSetSport } from './handleSetSport';
import { getSportId } from '../../../api/sportsApi';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import DialogWarning from '../../../components/Popups/DialogWarning';

const StepSportPanel: React.FC = observer(() => {
  const { t } = useTranslation();
  const text: {[key: string]: string} = t('sports', {returnObjects: true});
  const {isModelLoading, setDialogJSX, setStep, setOpenDialog} = store.app;

  const handleCardClick = (sport: string) => {
    if (isModelLoading) return;
    const max = handleSetSport(store.app, getSportId(sport));
    if (max) {
      setDialogJSX(
        <DialogWarning
          mainText={t('ui.warning')}
          describeText={t('warnings.max_kits')}
          handleWarning={() => {
            setStep(5);
            setOpenDialog(false);
          }}
        />
      );
      setOpenDialog(true);
    }
  }

  return (
    <WrappedStepSportPanel>
      {
        Object.keys(configSport).map((sport, index) => <CardMenu
          key={index}
          iconPath={configSport[sport].iconPath}
          iconSelectedPath={configSport[sport].iconSelectedPath}
          text={text[sport]}
          handleClick={() => handleCardClick(sport)}
          isActive={!isModelLoading}
        />)
      }
    </WrappedStepSportPanel>
  );
})

export default StepSportPanel;
