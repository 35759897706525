import {ICatalog, TGender} from "../db/types";
import {models} from "../db/models";
import {getKitGarmentsQuantity} from "./modelsApi";
import {EConfigGarment} from "../enums";
import {IGarmentConfig, IGarmentsQuantityResult, IIndividualConfig} from "../store/types";
import {catalog} from "../db/catalog";

export type TCatalogTypes = 'men' | 'women' | 'kids';

export const getCatalog = (
  sportId: string | null,
  garment: EConfigGarment,
  collectionId: string | null
): ICatalog | null => {
  const found = catalog.find(item =>
      item.sportId === sportId &&
      item.garment === garment &&
      (garment === EConfigGarment.SOCKS ? true : item.collectionId === collectionId)
    );
  if (found) {
    return found;
  }
  // console.log('getCatalog: catalog not found', {sportId, garment, collectionId});
  return null;
}

export const getModelCatalog = (catalog: ICatalog, type: TCatalogTypes): string | null => {
  return catalog.cat[type];
}

export const getModelGender = (modelId: string): TGender => {
  return models.find(item => item.id === modelId)?.gender || 'm';
}

export const getCatalogType = (gender: TGender, isKid: boolean): TCatalogTypes => {
  if (isKid) {
    return 'kids';
  }
  if (gender === 'w') {
    return 'women';
  }
  return 'men';
}

export const getPrice = (sportId: string | null, garment: EConfigGarment, totalQuantityOfGarments: number): number => {
  const found = catalog.find((i) => i.sportId === sportId && i.garment === garment)?.price || [];
  if (!found) {
    return 0;
  }

  for (let i = 0; i < found.length; i++) {
    if (totalQuantityOfGarments >= found[i].min && totalQuantityOfGarments <= found[i].max) {
      return found[i].price;
    }
  }

  return 0;
}

/**
 * Calculate the cost of one unit of goods, applying the calculation conditions
 * The discount is calculated using the getDiscountPrice method
 * If there is no discount, then returns the default price
 * If the counting conditions are not suitable, then returns 0
 * @param model
 * @param allQuantity
 * @param sportId
 */

export const getModelPriceDiscount = (
  model: IGarmentConfig,
  allQuantity: IGarmentsQuantityResult,
  sportId: string
): number => {
  let result: number = 0;

  if (model.isInclude) {
    let calculatedPrice = model.price;
    const { jerseyQuantity, shortsQuantity, socksQuantity } = allQuantity;

    // calculate discount

    if (!(model.garment === EConfigGarment.SOCKS && sportId !== '0' && sportId !== '4')) {
      // if garment === socks AND sport is NOT rugby or football, we do not calculate discount

      let totalQuantity;
      if (model.garment === EConfigGarment.JERSEY) {
        totalQuantity = jerseyQuantity;
      } else if (model.garment === EConfigGarment.SHORTS) {
        totalQuantity = shortsQuantity;
      } else {
        totalQuantity = socksQuantity;
      }
      const discount = getPrice(sportId, model.garment, totalQuantity);
      if (discount) {
        calculatedPrice = discount;
      }
    }

    result += calculatedPrice;
  }

  return result;
}

export const getKitSum = (teamConfig: IIndividualConfig[]): number => {
  let sum: number = 0;
  teamConfig.forEach(player => player.garments.forEach(model => {
    if (model.isInclude) {
      sum += model.price * model.quantityGarments * player.quantityKits;
    }
  }));
  return sum;
}

export const getKitSumDiscount = (teamConfig: IIndividualConfig[], sportId: string) => {
  const allQuantity = getKitGarmentsQuantity(teamConfig);
  let kitSum: number = 0;
  teamConfig.forEach(player => player.garments.forEach(model => {
    const calculatedPrice = getModelPriceDiscount(model, allQuantity, sportId);
    kitSum += calculatedPrice * model.quantityGarments * player.quantityKits;
  }));
  return kitSum;
}

export const getPlayerSum = (playerData: IIndividualConfig): number => {
  return playerData.garments.reduce((sum, acc) => {
    if (acc.isInclude) {
      return sum + acc.price * acc.quantityGarments;
    }
    return sum;
  }, 0) || 0
}

export const getPlayerSumDiscount = (
  playerData: IIndividualConfig,
  allQuantity: IGarmentsQuantityResult,
  sportId: string,
): number => {
  return playerData.garments.reduce((sum, acc) => {
    const calculatedPrice = getModelPriceDiscount(acc, allQuantity, sportId);
    return sum + calculatedPrice * acc.quantityGarments;
  }, 0) || 0
}
