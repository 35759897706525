import appStore from './appStore';
import canvasStore from './canvasStore';
import orderStore from './orderStore';

class RootStore {
  app: typeof appStore = appStore;
  canvas: typeof canvasStore = canvasStore;
  order: typeof orderStore = orderStore;
}

export default new RootStore();