import React from 'react';
import { WrappedCardModel } from './cardsStyle';
import {convertPrice} from "../../utils";

interface IProps {
  isSelected: boolean;
  imgPath: string;
  header: string;
  price?: number;
  handleClick?: () => void;
  isStepDesign?: boolean;
}

const CardModel: React.FC<IProps> = ({isSelected, imgPath, header, price, handleClick, isStepDesign}) => {

  return (
    <WrappedCardModel 
      isSelected={isSelected}
      onClick={handleClick}
      isStepDesign={isStepDesign}
    >
      <img src={imgPath} alt='cloth'/>
      <p>{header}</p>
      {
        price !== undefined ? <p className="price">{convertPrice(price)}</p> : null
      }
    </WrappedCardModel>
  );
}

export default CardModel;
