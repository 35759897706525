import React, { useState } from 'react';
import { WrappedInputCounter } from './style';
import imgPlusHover from '../../../assets/UI/plus_active.svg';
import imgPlus from '../../../assets/UI/plus.svg';
import imgMinusHover from '../../../assets/UI/minus_active.svg';
import imgMinus from '../../../assets/UI/minus.svg';
import imgConfirm from '../../../assets/UI/confirm_green.svg';
import imgDecline from '../../../assets/UI/decline.svg';
import InputText from './InputText';

interface IProps {
  current: number;
  changeValue: (value: number) => void;
}

const InputCounter: React.FC<IProps> = ({current, changeValue}) => {
  const [isInput, setIsInput] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<number>(current);
  const [isHover, setIsHover] = useState<boolean>(false);

  const handleClick = (isPlus: boolean) => {
    if (isPlus) {
      if (current > 98) {
        return;
      }
      changeValue(current + 1);
    } else {
      if (current < 1) {
        return;
      }
      changeValue(current - 1);
    }
  }

  const handleInput = (isConfirm: boolean) => {
    if (isConfirm) {
      changeValue(inputValue);
    }
    setIsInput(false);
  }

  const handleManualInput = (value: string) => {
    setInputValue(Number(value));
  }

  const handleParagraphClick = () => {
    setInputValue(current);
    setIsInput(true);
  }

  return (
    <WrappedInputCounter
      onMouseOver={() => setIsHover(true)}
      onMouseOut={() => setIsHover(false)}
    >
      <img 
        src={isInput ? imgDecline : (isHover ? imgMinusHover : imgMinus)} 
        alt='-'
        onClick={() => {
          if (isInput) {
            handleInput(false);
          } else {
            handleClick(false);
          }
        }}
      />
      {
        isInput ?
        <InputText 
          isNumberOnly 
          isSimpleStyle
          maxLength={2}
          value={String(inputValue)} 
          handleChange={handleManualInput}
        /> :
        <p
          onClick={handleParagraphClick}
        >{current}</p>
      }
      <img 
        src={isInput ? imgConfirm : (isHover ? imgPlusHover : imgPlus)} 
        alt='+'
        onClick={() => {
          if (isInput) {
            handleInput(true);
          } else {
            handleClick(true);
          }
        }}
      />
    </WrappedInputCounter>
  );
}

export default InputCounter;