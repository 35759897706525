import styled from 'styled-components';
import { BTN_BORDER_COLOR, BTN_TEXT_COLOR, BTN_TEXT_COLOR_PRESSED } from '../../../constants';
import { hoverStyle } from '../../Popups/popupStyle';

interface IButtonProps {
  pressed?: boolean;
  background?: string;
  color?: string;
  width?: string;
  height?: string;
  isActive: boolean;
}

export const WrappedButton = styled.button<IButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
  padding: 8px 16px;
  background-color: ${props => props.background ? props.background : 'white'};
  border: 1px solid ${props => props.pressed ? BTN_TEXT_COLOR_PRESSED : BTN_BORDER_COLOR};
  border-radius: 16px;
  cursor: ${props => props.isActive ? 'pointer' : 'default'};
  color: ${props => props.color? props.color : (props.pressed ? BTN_TEXT_COLOR_PRESSED : BTN_TEXT_COLOR)};
  font-weight: 700;
  font-size: 13px;
  width: ${props => props.width ? props.width : 'auto'};
  height: ${props => props.height ? props.height : 'auto'};

  img, p, span {
    opacity: ${props => props.isActive ? '1' : '0.7'};
    color: ${props => props.color? props.color : (props.pressed ? BTN_TEXT_COLOR_PRESSED : BTN_TEXT_COLOR)};
  }

  &:hover {
    ${({pressed, isActive, background, color}) => !pressed && isActive && !background && !color && hoverStyle}
  }

  @media all and (max-width: 1920px) {
    padding: 6px 12px;

    img {
      height: 16px;
    }
  }
`

export const WrappedRemoveFile = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  img {
    width: 10px;
  }
`