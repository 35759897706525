import {ISizes} from "../store/types";

const fixLogoAspectRatio = (height: number, width: number, sizes: ISizes): ISizes => {
  let newHeight = height;
  let newWidth = height / sizes.h * sizes.w;
  if (newWidth > width) {
    newHeight = width / newWidth * height;
    newWidth = width;
  }
  return {
    w: newWidth,
    h: newHeight,
  }
}

export default fixLogoAspectRatio;
