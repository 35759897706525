import styled from 'styled-components';

export const WrappedStepSport = styled.div`
  .cards {
    max-width: 1440px;
    width: 90%;
    height: 360px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    justify-content: space-between;
  }

  .cards__mobile {
    display: none;
  }

  @media all and (max-width: 1920px) {
    .cards {
      max-width: 1200px;
    }
  }

  @media all and (max-width: 768px) {
    .cards {
      display: none;
    }

    .cards__mobile {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 287px;
      max-width: 90%;
      gap: 16px;
      margin: auto;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      height: 250px;
    }
  }
`

export const WrappedStepSportPanel = styled.div`
  display: flex;
  gap: 32px;

  @media all and (max-width: 1920px) {
    gap: 16px;
  }

  @media all and (max-width: 768px) {
    display: none;
  }
`