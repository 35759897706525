import React, { useRef, useState } from 'react';
import { EButtonOpenerTypes } from '../../../enums';
import ButtonOpener from '../ButtonOpener/ButtonOpener';
import {FakeMenuSelectorButton, MenuSelectorPaperStyle} from './style';
import { observer } from 'mobx-react-lite';
import styled from "styled-components";
import {Popover} from "@mui/material";
import MenuSelectorContent from "./MenuSelectorContent";

const Wrapper = styled.div`
  position: relative;
`;

const MenuSelector = observer(() => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const fakeRef = useRef<HTMLDivElement>(null);

  const handleOpen = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = () => {
    fakeRef.current?.click();
  }

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover2' : undefined;

  return (
    <Wrapper>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        PaperProps={{
          sx: MenuSelectorPaperStyle,
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transitionDuration={150}
      >
        <MenuSelectorContent
          closeMenu={handleClose}
        />
        <ButtonOpener
          type={EButtonOpenerTypes.CLOSE}
          handleClick={handleClose}
          isMenu
          style={{
            position: 'absolute',
            right: '8px',
            bottom: '8px',
          }}
        />
      </Popover>
      <ButtonOpener
        type={EButtonOpenerTypes.MENU}
        handleClick={handleClick}
        isMenu
        style={{
          visibility: open ? 'hidden' : 'visible',
        }}
      />
      <FakeMenuSelectorButton
        ref={fakeRef}
        onClick={handleOpen}
      />
    </Wrapper>
  );
})

export default MenuSelector;
