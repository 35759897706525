import {modelCoords} from '../constants';
import {makeAutoObservable} from "mobx";
import * as THREE from "three";
import {GLTFLoader, OrbitControls, RGBELoader} from 'three-stdlib';
import * as PIXI from 'pixi.js';
import {IColorScheme, IDesignParams, IPosition, IPositionWithActualSize} from "../db/types";
import {EConfigDesigns, EConfigGarment} from "../enums";
import {IReturnAllModels} from "../api/canvasApi";
import {defaultBaseTextures, defaultDecoration, defaultSprites, pixiOptions} from './defaults';
import {IScreenshot, ISizes, IStepColor, IStyles, ITextJersey, ITextShorts} from './types';
import fixLogoAspectRatio from "../utils/fixLogoAspectRatio";

interface IDecoration {
  numberText: PIXI.Sprite | null;
  lastNameText: PIXI.Sprite | null;
}

interface IDecorations {
  jersey: IDecoration;
  shorts: IDecoration;
}

export interface IModelsPath {
  jersey: string;
  shorts: string;
  socks: string;
}

export interface IBaseTexturesPath {
  base: string;
  normal: string;
  roughness: string;
  ao: string | null;
}

export interface IModelsBaseTextures {
  jersey: IBaseTexturesPath;
  shorts: IBaseTexturesPath;
  socks: IBaseTexturesPath;
}

interface IDesignTexturesPath {
  jersey: string[];
  shorts: string[];
  socks: string[];
}

interface IDesign {
  jersey: HTMLCanvasElement | null;
  shorts: HTMLCanvasElement | null;
  socks: HTMLCanvasElement | null;
}

interface ISprite {
  [key: string]: PIXI.Sprite;
}

interface ISprites {
  jersey: ISprite;
  shorts: ISprite;
  socks: ISprite;
}

interface CurrentsLogoType {
  sprite: PIXI.Sprite;
  garment: EConfigGarment;
  chapter: string;
  initialSizes: ISizes;
}


interface IModelGroups {
  jersey: THREE.Group;
  shorts: THREE.Group;
  socks: THREE.Group;
}

interface IPixi {
  jersey: PIXI.Application;
  shorts: PIXI.Application;
  socks: PIXI.Application;
}

interface IMaterials {
  jersey: THREE.MeshStandardMaterial;
  shorts: THREE.MeshStandardMaterial;
  socks: THREE.MeshStandardMaterial;
}

const materialParams = {
  metalness: .2,
  roughness: 0.6,
}

class CanvasStore {
  readonly loader = new GLTFLoader();
  readonly RGBELoader = new RGBELoader();
  readonly renderer = new THREE.WebGLRenderer({alpha: true, antialias: true, preserveDrawingBuffer: true});
  readonly scene = new THREE.Scene();
  readonly camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
  readonly controls = new OrbitControls(this.camera, this.renderer.domElement);
  readonly materials: IMaterials = {
    jersey: new THREE.MeshStandardMaterial(materialParams),
    shorts: new THREE.MeshStandardMaterial(materialParams),
    socks: new THREE.MeshStandardMaterial(materialParams),
  }
  readonly textureLoader: THREE.TextureLoader = new THREE.TextureLoader();
  readonly pixiLoader: PIXI.Loader = new PIXI.Loader();

  viewportWidth: number = 0;
  viewportHeight: number = 0;

  overview: boolean = false;
  modelsPath: IModelsPath = {jersey: '', shorts: '', socks: ''};
  modelGroups: IModelGroups = {
    jersey: new THREE.Group(),
    shorts: new THREE.Group(),
    socks: new THREE.Group(),
  };
  modelsBaseTextures: IModelsBaseTextures = {
    jersey: defaultBaseTextures,
    shorts: defaultBaseTextures,
    socks: defaultBaseTextures,
  }
  designTexturesPath: IDesignTexturesPath = {
    jersey: [],
    shorts: [],
    socks: [],
  }
  design: IDesign = {jersey: null, shorts: null, socks: null};
  rafId: number = 0;
  pixi: IPixi = {
    jersey: new PIXI.Application(),
    shorts: new PIXI.Application(),
    socks: new PIXI.Application(),
  }
  currentLogo: CurrentsLogoType[] = [];

  sprites: ISprites = defaultSprites;
  decoration: IDecorations = {
    jersey: defaultDecoration,
    shorts: defaultDecoration,
  };

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setOverview = (value: boolean) => {
    this.overview = value;
    //console.log('overview: ', value);
  }

  init = (width: number = 800, height: number = 600) => {
    //console.log('init. width, height: ', {width, height});
    this.viewportWidth = width;
    this.viewportHeight = height;

    // light
    //const lightFront = new THREE.DirectionalLight(0xffffff, .4);
    //const lightBack = new THREE.DirectionalLight(0xffffff, .4);
    //const HemisphereLight = new THREE.HemisphereLight( 0xffffff, 0x080820, .2 );
    const ambientLight = new THREE.AmbientLight(0xffffff, .35);
    //lightFront.position.set(0, 8, 10);
    //lightBack.position.set(0, 6, -10);
    //this.scene.add(lightFront);
    //this.scene.add(lightBack);
    //HemisphereLight.position.set(0, 8, 10);
    //this.scene.add(HemisphereLight);
    this.scene.add(ambientLight);
    this.RGBELoader.load('/models/environment.hdr', (texture) => {
      //const hdrCubeRenderTarget = new PMREMGenerator(this.renderer).fromEquirectangular(texture);
      texture.mapping = THREE.EquirectangularRefractionMapping;
      this.scene.environment = texture;
    });

    // helpers

    // const boxObj = new THREE.Mesh(new THREE.SphereGeometry(), new THREE.MeshBasicMaterial());
    // const box = new THREE.BoxHelper(boxObj, 0xffff00);
    // this.scene.add(box);

    this.renderer.setSize(width, height);
    this.controls.enableDamping = true; // an animation loop is required when either damping or auto-rotation are enabled
    this.controls.dampingFactor = 0.05;
    this.controls.screenSpacePanning = false;
    this.controls.minDistance = 70;
    this.controls.maxDistance = 170;
    this.controls.minPolarAngle = Math.PI / 4;
    this.controls.maxPolarAngle = Math.PI / 1.65;
    this.controls.enablePan = false;
    this.camera.position.set(15,15,85);
    this.camera.aspect = width / height;
    this.camera.updateProjectionMatrix();
    this.materials.jersey.side = THREE.DoubleSide;
    this.materials.shorts.side = THREE.DoubleSide;
    this.materials.socks.side = THREE.DoubleSide;
    this.pixi.jersey = new PIXI.Application(pixiOptions);
    this.pixi.shorts = new PIXI.Application(pixiOptions);
    this.pixi.socks = new PIXI.Application(pixiOptions)
  }

  resizeViewport = (width: number = 800, height: number = 600) => {
    this.viewportWidth = width;
    this.viewportHeight = height;
    this.renderer.setSize(width, height);
    this.camera.aspect = width / height;
    this.camera.updateProjectionMatrix();
  }

  putCanvasToApp = (): boolean => {
    //console.log('putCanvasToApp');

    const root = document.querySelector('#render');
    if (root) {
      root.innerHTML = '';
      root.appendChild(this.renderer.domElement);
      return true;
    }
    return false;
  }

  setViewFull = (includes: {
    jersey: boolean;
    shorts: boolean;
    socks: boolean;
  }) => {
    this.controls.minDistance = 139;
    this.controls.minPolarAngle = Math.PI / 2.3;
    this.controls.maxPolarAngle = Math.PI / 2;
    this.camera.position.set(15,30,150);
    for (let cloth in this.modelGroups) {
      let isVisible = true;
      if (cloth === EConfigGarment.JERSEY) {
        isVisible = includes.jersey;
      } else if (cloth === EConfigGarment.SHORTS) {
        isVisible = includes.shorts;
      } else {
        isVisible = includes.socks;
      }
      this.modelGroups[cloth as keyof IModelGroups].position.y = -72; // vertical position of models. Increase value to get it higher
      this.modelGroups[cloth as keyof IModelGroups].position.z = 0;
      this.modelGroups[cloth as keyof IModelGroups].visible = isVisible;
    }
  }

  setViewSingle = (garment: EConfigGarment) => {
    this.controls.minDistance = 70;
    this.controls.minPolarAngle = Math.PI / 4;
    this.controls.maxPolarAngle = Math.PI / 1.65;
    for (let cloth in this.modelGroups) {
      if (cloth === garment) {
        this.modelGroups[cloth].position.y = modelCoords[cloth].y;
        this.modelGroups[cloth].position.z = modelCoords[cloth].z;
        this.modelGroups[cloth].visible = true;
      } else {
        this.modelGroups[cloth as keyof IModelGroups].visible = false;
      }
    }
  }

  setVisibility = (garment: EConfigGarment, value: boolean) => {
    if (!this.overview) {
      return;
    }
    this.modelGroups[garment as keyof IModelGroups].visible = value;
  }

  setDefaultCameraPosition = () => {
    this.camera.position.set(15,15,85);
  }

  refresh = () => {
    const render = () => requestAnimationFrame(() => {
      this.renderer.render(this.scene, this.camera);
      render();
      this.controls.update();
      const distance = this.controls.getDistance();
      if (this.overview) {
        if (distance < 140) {
          this.setOverview(false);
        }
      } else {
        if (distance > 149) {
          this.setOverview(true);
        }
      }
    })
    this.rafId = render();
  }

  setMaxCameraDistance = (value: number) => {
    this.controls.maxDistance = value;
  }

  resetOverview = () => {
    this.setDefaultCameraPosition();
    this.setMaxCameraDistance(149);
    this.setOverview(false);
  }

  prepareCameraForScreenshot = (garment: EConfigGarment) => {
    this.renderer.setSize(600, 600);
    this.camera.aspect = 1;
    this.camera.updateProjectionMatrix();
    this.controls.minDistance = 0;

    // z - расстояние до объекта
    let z = 57;
    if (garment === EConfigGarment.SHORTS) {
      z = 42;
    } else if (garment === EConfigGarment.SOCKS) {
      z = 37;
    }
    this.camera.position.set(15,15, z);

    for (let cloth in this.modelGroups) {
      if (cloth === garment) {
        this.modelGroups[cloth].position.y = modelCoords[cloth].y;
        this.modelGroups[cloth].position.z = modelCoords[cloth].z;
        this.modelGroups[cloth].visible = true;
      } else {
        this.modelGroups[cloth as keyof IModelGroups].visible = false;
      }
    }
  }

  getReportScreenshot = () => {
    let jerseyImage: IScreenshot = {
      front: '',
      back: '',
      left: '',
      right: '',
    };
    for ( let side in jerseyImage ){
      this.setViewSingle(EConfigGarment.JERSEY);
      this.initCameraForScreenshot(side);
      const screen = this.makeScreenshot();
      jerseyImage[side as keyof IScreenshot] = screen;
    }

    let shortsImage: IScreenshot = {
      front: '',
      back: '',
      left: '',
      right: '',
    };
    for ( let side in shortsImage ){
      this.setViewSingle(EConfigGarment.SHORTS);
      this.initCameraForScreenshot(side);
      const screen = this.makeScreenshot();
      shortsImage[side as keyof IScreenshot] = screen;
    }

    return { jersey: jerseyImage, shorts: shortsImage}
  }

  initCameraForScreenshot = (
    side: any
    ) => {
    this.renderer.setSize(600, 600);
    this.camera.aspect = 1;
    this.camera.updateProjectionMatrix();
    this.controls.minDistance = 0;

    switch (side){
      case 'front':
        this.camera.position.set(0, 0, 70);
        break;
      case 'back':
        this.camera.position.set(0, 0, -70);
        break;
      case 'left':
        this.camera.position.set(70, 0, 0);
        break;
      case 'right':
        this.camera.position.set(-70, 0, 0);
        break;
    }

    this.camera.lookAt(0,0,0);
  }

  makeScreenshot = (): string => {
    this.renderer.render(this.scene, this.camera);
    return this.renderer.domElement.toDataURL('image/png');
  }

  loadMesh = (skip?: boolean): Promise<void> => {
    // in first load materials!

    return new Promise(resolve => {
      if (skip) {
        //console.log('skip loadMesh');
        resolve();
        return;
      }
      //console.log('loadMesh');

      const jersey = this.loadSingleMesh(this.modelsPath.jersey, EConfigGarment.JERSEY);
      const shorts = this.loadSingleMesh(this.modelsPath.shorts, EConfigGarment.SHORTS);
      const socks = this.loadSingleMesh(this.modelsPath.socks, EConfigGarment.SOCKS);

      Promise.all([jersey, shorts, socks]).then(() => {
        this.scene.add(this.modelGroups.jersey);
        this.scene.add(this.modelGroups.shorts);
        this.scene.add(this.modelGroups.socks);
        resolve();
      });
    });
  }

  getActualModelParams = (): {
    jersey: string;
    shorts: string;
    socks: string;
    textures: IModelsBaseTextures;
    design: IDesign;
  } => {
    return {
      jersey: this.modelsPath.jersey,
      shorts: this.modelsPath.shorts,
      socks: this.modelsPath.socks,
      textures: this.modelsBaseTextures,
      design: this.design,
    }
  }

  loadSingleMesh = (modelPath: string, garment: EConfigGarment): Promise<void> => {
    this.modelGroups[garment].clear();
    return new Promise((resolve) => {
      this.loader.load(modelPath, glb => {
        this.modelGroups[garment].add(glb.scene);
        const obj = this.modelGroups[garment].getObjectByName('mesh_0') as THREE.Mesh;
        obj.material = this.materials[garment];
        obj.scale.set(100, 100, 100);
        this.modelGroups[garment].updateWorldMatrix(true, true);
        resolve();
      });
    });
  }


  loadMaterials = (): Promise<void> => {
    //console.log('loadMaterials');
    // if need design: in first, load design!

    return new Promise(res => {
      const jersey = this.loadSingleMaterial({
        garment: EConfigGarment.JERSEY,
        base: this.modelsBaseTextures.jersey.base,
        normal: this.modelsBaseTextures.jersey.normal,
        roughness: this.modelsBaseTextures.jersey.roughness,
        ao: this.modelsBaseTextures.jersey.ao,
        design: this.design.jersey
      });
      const shorts = this.loadSingleMaterial({
        garment: EConfigGarment.SHORTS,
        base: this.modelsBaseTextures.shorts.base,
        normal: this.modelsBaseTextures.shorts.normal,
        roughness: this.modelsBaseTextures.shorts.roughness,
        ao: this.modelsBaseTextures.shorts.ao,
        design: this.design.shorts
      });
      const socks = this.loadSingleMaterial({
        garment: EConfigGarment.SOCKS,
        base: this.modelsBaseTextures.socks.base,
        normal: this.modelsBaseTextures.socks.normal,
        roughness: this.modelsBaseTextures.socks.roughness,
        ao: this.modelsBaseTextures.socks.ao,
        design: this.design.socks
      });

      Promise.all([jersey, shorts, socks]).finally(() => res());
    });
  }

  loadSingleMaterial = async(params: {
    garment: EConfigGarment;
    base: string;
    normal: string;
    roughness: string;
    ao: string | null;
    design: HTMLCanvasElement | null;
  }): Promise<void> => {
    const {garment, base, normal, roughness, ao, design} = params;

    return this.loadThreeTexture(normal)
    .then(res => {
      this.materials[garment].normalMap = res as THREE.Texture;
    })
    .then(() => this.loadThreeTexture(roughness))
    .then(res => {
      this.materials[garment].roughnessMap = res as THREE.Texture;
    })
    .then(() => {
      if (design === null) {
        return this.loadThreeTexture(base)
        .then((res) => {
          const result = res as THREE.Texture;
          result.needsUpdate = true;
          this.materials[garment].map = result;
        })
      } else {
        const result = new THREE.CanvasTexture(design);
        result.flipY = false;
        result.needsUpdate = true;
        this.materials[garment].map = result;
      }
    })
    .then(() => {
      if (ao) {
        return this.loadThreeTexture(ao)
        .then((res) => {
          this.materials[garment].aoMap = res as THREE.Texture;
        })
      } else {
        this.materials[garment].aoMap?.dispose();
      }
    })
    .finally(() => {
      this.materials[garment].needsUpdate = true;
    })
  }

  loadThreeTexture = (url: string) => {
    return new Promise((resolve, reject) => {
      this.textureLoader.load(
        url,
        (result) => {
          result.flipY = false;
          resolve(result);
        },
        () => {},
        (e) => {
          reject(e);
        }
      );
    });
  }

  cleanDesign = () => {
    this.design.jersey = null;
    this.design.shorts = null;
    this.design.socks = null;
  }

  loadDesign = (isDevMode?: boolean, styles?: IStyles, isSkipNumber?: boolean): Promise<void> => {
    this.cleanDesignLoader();
    // console.log('loadingDesign');
    document.querySelectorAll('.test_canvas')?.forEach((item) => item.remove());
    return new Promise((resolve) => {
      this.loadSingleDesign(EConfigGarment.JERSEY, isDevMode || false)
        .then(() => {
          return this.loadSingleDesign(EConfigGarment.SHORTS, isDevMode || false)
        })
        .then(() => {
          return this.loadSingleDesign(EConfigGarment.SOCKS, isDevMode || false)
        })
        .then(() => {
          if (styles) {
            this.loadDecoration(EConfigGarment.JERSEY, styles, isSkipNumber);
            this.loadDecoration(EConfigGarment.SHORTS, styles, isSkipNumber);
          }
          this.pixi.jersey.render();
          this.design.jersey = this.pixi.jersey.view;
          this.pixi.shorts.render();
          this.design.shorts = this.pixi.shorts.view;
          this.pixi.socks.render();
          this.design.socks = this.pixi.socks.view;
          resolve();
        })
    })
  }

  // todo: тут чистка не теряй
  cleanDesignLoader = () => {
    this.pixiLoader.onComplete.detachAll();
    this.pixiLoader.destroy();
    for (let garment in this.sprites) {
      for (let sprite in this.sprites[garment as keyof ISprites]) {
        this.sprites[garment as keyof ISprites][sprite].destroy({ children: true, texture: true, baseTexture: true});
      }
    }
    this.sprites = defaultSprites;
  }

  addTextureChunkToStage = (name: string, garment: EConfigGarment, position?: IPosition) => {
    const resource = this.pixiLoader.resources[name];

    if (!resource || !resource.texture) return;

    const sprite = PIXI.Sprite.from(resource.texture as PIXI.Texture);
    sprite.x = position?.x || 0;
    sprite.y = position?.y || 0;
    const myName = name.split('_');
    this.sprites[garment][myName[1]] = sprite;
    if (!this.pixi[garment].stage) {
      //console.log('pixi.stage error');
      return;
    }
    this.pixi[garment].stage.addChild(sprite);
  }

  loadSingleDesign = (
    garment: EConfigGarment,
    isDevMode: boolean,
    isNeedRender?: boolean
  ): Promise<void> => {
    const paths = this.designTexturesPath[garment];

    /*
    paths[0] - base
    paths[1] - details
    paths[2] - logo
    paths[3] - design1
    paths[4] - design2
    paths[5] - design3
     */

    return new Promise((resolve) => {
      if (paths.length === 0) {
        resolve();
        return;
      }

      if (isNeedRender) {
        this.cleanDesignLoader();
      }

      const length = paths.length;
      this.pixiLoader.add(garment + '_base', paths[0]);
      if (paths[1]) {
        if (length < 3) {
          // !! if textures count less than 3, then the 'detail' will be not included, and logo will be 2nd
          this.pixiLoader.add(garment + '_logo', paths[1]);
        } else {
          this.pixiLoader.add(garment + '_details', paths[1]);
        }
      }
      if (length > 2 && paths[2]) {
        this.pixiLoader.add(garment + '_logo', paths[2]);
      }
      if (length > 3 && paths[3]) {
        this.pixiLoader.add(garment + '_design1', paths[3]);
      }
      if (length > 4 && paths[4]) {
        this.pixiLoader.add(garment + '_design2', paths[4]);
      }
      if (length > 5 && paths[5]) {
        this.pixiLoader.add(garment + '_design3', paths[5]);
      }

      this.pixiLoader.onComplete.once(() => {
        if (isDevMode) {
          let top = '0';
          if (garment === EConfigGarment.SHORTS) {
            top = '200';
          }
          if (garment === EConfigGarment.SOCKS) {
            top = '400';
          }
          document.body.appendChild(this.pixi[garment].renderer.view);
          this.pixi[garment].renderer.view.classList.add('test_canvas');
          this.pixi[garment].renderer.view.style.position = 'fixed';
          this.pixi[garment].renderer.view.style.zIndex = '1001';
          this.pixi[garment].renderer.view.style.top = top + 'px';
          this.pixi[garment].renderer.view.style.width = '200px';
        }

        this.addTextureChunkToStage(garment + '_base', garment);
        this.addTextureChunkToStage(garment + '_details', garment);
        this.addTextureChunkToStage(garment + '_design1', garment);
        this.addTextureChunkToStage(garment + '_design2', garment);
        this.addTextureChunkToStage(garment + '_design3', garment);
        this.addTextureChunkToStage(garment + '_logo', garment);

        if (isNeedRender) {
          this.pixi[garment].render();
          this.design[garment] = this.pixi[garment].view;
        }

        resolve();
      })

      this.pixiLoader.load();
    })
  }

  changeLogoSpriteSize = (width: number, height: number, garment: EConfigGarment, chapter: string) => {
    const spriteLogo = this.currentLogo.find((item) => item.garment === garment && item.chapter === chapter);
    if (spriteLogo) {
      const { w, h } = fixLogoAspectRatio(height / 2, width / 2, spriteLogo.initialSizes);
      spriteLogo.sprite.width = w;
      spriteLogo.sprite.height = h;
      this.renderSpriteLogoAfterChange();
    }
  }

  renderSpriteLogoAfterChange = () => {
    this.pixi.jersey?.renderer.render(this.pixi.jersey?.stage);
    this.pixi.shorts?.renderer.render(this.pixi.shorts?.stage);
    this.materials.jersey.needsUpdate = true;
    if (this.materials.jersey.map) {
      this.materials.jersey.map.needsUpdate = true;
    }
    if (this.materials.shorts.map) {
      this.materials.shorts.map.needsUpdate = true;
    }
    this.renderer.render(this.scene, this.camera);
    this.pixi.jersey?.render();
    this.pixi.shorts?.render();
  }

  addCustomImageToStage = (image: string, position: IPositionWithActualSize, sizes: ISizes, garment: EConfigGarment, chapter: string) => {
    const myPosition = {
      // fix position params after reducing of textures resolution from 4096 to 2048
      x: position.x / 2,
      y: position.y / 2,
      width: position.width ? (position.width / 2) : 0,
      height: position.height ? (position.height / 2) : 0,
    }
    const texture = PIXI.Texture.from(image);
    const sprite = new PIXI.Sprite(texture);
    sprite.x = myPosition.x;
    sprite.y = myPosition.y;
    if (myPosition.height && myPosition.width) {
      // fix image sizes
      const { w, h } = fixLogoAspectRatio(myPosition.height, myPosition.width, sizes);
      sprite.height = h;
      sprite.width = w;
    }
    sprite.anchor.set(0.5, 0.5);
    this.pixi[garment].stage.addChild(sprite);

    // add to cache

    const cached = {
      garment,
      chapter,
      sprite,
      initialSizes: sizes,
    };
    const index = this.currentLogo.findIndex((item) => item.garment === garment && item.chapter === chapter);
    if (index > -1) {
      this.currentLogo[index] = cached;
    } else {
      this.currentLogo.push(cached);
    }
  }

  loadDecoration = (garment: EConfigGarment, styles: IStyles, isSkipNumber?: boolean) => {
    // jersey number, last name

    if (garment === EConfigGarment.JERSEY) {
      if (styles.jersey.numberPosition) {
        this.generateText(
          garment,
          styles.jersey.number,
          styles.jersey.numberPosition,
          'numberText'
        );
      }
      if (styles.jersey.lastNamePosition) {
        this.generateText(
          garment,
          styles.jersey.lastName,
          styles.jersey.lastNamePosition,
          'lastNameText'
        );
      }
    }

    // shorts number

    if (garment === EConfigGarment.SHORTS && !isSkipNumber) {
      if (styles.shorts.numberPosition) {
        this.generateText(
          garment,
          styles.shorts.number,
          styles.shorts.numberPosition,
          'numberText',
          styles.jersey.number.text,
          styles.jersey.number.fontFamily
        );
      }
    }

    // jersey, shorts team logo and sponsors
    const obj = styles[garment as keyof IStyles];
    if (styles.jersey.layout.logo && obj.logoPosition) {
      this.addCustomImageToStage(
        styles.jersey.layout.logo,
        obj.logoPosition,
        styles.jersey.layout.logoSizes,
        garment,
        'teamLogo',
      );
    }
    if (obj.layout.sponsor1 && obj.sponsor1Position) {
      this.addCustomImageToStage(
        obj.layout.sponsor1,
        obj.sponsor1Position,
        obj.layout.sponsor1Sizes,
        garment,
        'sponsorLogo1',
      );
    }
    if (obj.layout.sponsor2 && obj.sponsor2Position) {
      this.addCustomImageToStage(
        obj.layout.sponsor2,
        obj.sponsor2Position,
        obj.layout.sponsor2Sizes,
        garment,
        'sponsorLogo2',
      );
    }
    if (obj.layout.sponsor3 && obj.sponsor3Position) {
      this.addCustomImageToStage(
        obj.layout.sponsor3,
        obj.sponsor3Position,
        obj.layout.sponsor3Sizes,
        garment,
        'sponsorLogo3',
      );
    }
  }

  loadColors = (schemes: IStepColor, designName: EConfigDesigns) => {
    // in first, load design!
    //console.log('load colors');
    const index = schemes.jersey.findIndex((k) => k.design === designName);
    this.loadSingleColor(EConfigGarment.JERSEY, schemes.jersey[index].scheme);
    this.loadSingleColor(EConfigGarment.SHORTS, schemes.shorts);
    this.loadSingleColor(EConfigGarment.SOCKS, schemes.socks);
  }

  loadSingleColor = (garment: EConfigGarment, scheme: IColorScheme) => {
    if (Object.keys(this.sprites[garment]).length === 0) {
      //console.log('load colors error: sprites not found')
      return;
    }

    for (let spriteName in this.sprites[garment]) {
      const color = scheme[spriteName as keyof IColorScheme] || '#fff';
      this.sprites[garment][spriteName].tint = PIXI.utils.string2hex(color);
    }

    this.pixi[garment].render();
    this.materials[garment].needsUpdate = true;
    if (this.materials[garment].map) {
      this.materials[garment].map!.needsUpdate = true;
    }
  }

  generateText = (garment: EConfigGarment, style: ITextJersey | ITextShorts, position: IPosition, field: keyof IDecoration, text?: string, fontFamily?: string) => {
    let myStyle;
    let myText;
    let myFontFamily;
    if (garment === EConfigGarment.JERSEY) {
      myStyle = style as ITextJersey;
      myText = myStyle.text;
      myFontFamily = myStyle.fontFamily;
    } else {
      myStyle = style as ITextShorts;
      myText = text;
      myFontFamily = fontFamily;
    }

    this.decoration[garment as keyof IDecorations][field]?.destroy({ children: true, texture: true, baseTexture: true});
    this.decoration[garment as keyof IDecorations][field] = null;

    if (!myText) {
      //console.log('Skip generateText: empty text');
      return;
    }
    const myPosition = {
      // fix position params after reducing of textures resolution from 4096 to 2048
      x: position.x / 2,
      y: position.y / 2,
    }
    //console.log('generateText: ' + myText, myFontFamily);

    const result = new PIXI.Text(myText || '');
    result.x = myPosition.x || 500;
    result.y = myPosition.y || 600;
    result.anchor.set(0.5);
    let fontSize = 10;
    if (myStyle.fontSize === '1') {
      fontSize = 8;
    }
    if (myStyle.fontSize === '3') {
      fontSize = 12;
    }
    let multiplyFontSize = 8;
    if (garment === EConfigGarment.JERSEY) {
      if (field === 'numberText') {
        multiplyFontSize = 32;
      }
    } else {
      multiplyFontSize = 16;
    }
    result.style = new PIXI.TextStyle({
      fill: PIXI.utils.string2hex(myStyle.textColor || 'white'),
      fontSize: fontSize * multiplyFontSize,
      fontFamily: myFontFamily || 'Times New Roman',
      stroke: myStyle.strokeColor || 'black',
      strokeThickness: (myStyle.strokeThickness || 0) * 2.5,
    });

    this.decoration[garment as keyof IDecorations][field] = result;
    this.pixi[garment].stage.addChild(result);
  }

  cleanMemory = () => {
    cancelAnimationFrame(this.rafId);
    for (let item in this.pixi) {
      this.pixi[item as keyof IPixi].destroy();
    }
    for (let garment in this.materials) {
      this.materials[garment as keyof IMaterials].map?.dispose();
      this.materials[garment as keyof IMaterials].normalMap?.dispose();
      this.materials[garment as keyof IMaterials].roughnessMap?.dispose();
      this.materials[garment as keyof IMaterials].aoMap?.dispose();
      this.materials[garment as keyof IMaterials].dispose();
    }

    this.scene.clear();
    this.cleanDesignLoader();
    this.renderer.dispose();
  }

  setModelsPath = (res: IReturnAllModels) => {
    for (let item in res) {
      this.modelsPath[item as keyof IModelsPath] = res[item as keyof IReturnAllModels].modelPath;
    }
  }

  setBaseTexturesPath = (res: IReturnAllModels) => {
    for (let item in res) {
      this.modelsBaseTextures[item as keyof IModelsBaseTextures].base = res[item as keyof IReturnAllModels].texturesPath.base;
      this.modelsBaseTextures[item as keyof IModelsBaseTextures].normal = res[item as keyof IReturnAllModels].texturesPath.normal;
      this.modelsBaseTextures[item as keyof IModelsBaseTextures].roughness = res[item as keyof IReturnAllModels].texturesPath.roughness;
      this.modelsBaseTextures[item as keyof IModelsBaseTextures].ao = res[item as keyof IReturnAllModels].texturesPath.ao;
    }
  }

  setDesignTexturesPath = (res: IReturnAllModels, design: EConfigDesigns) => {
    for (let item in res) {
      const cloth = res[item as keyof IReturnAllModels];
      this.setSingleDesignTexturePath(
        cloth.designsList,
        design,
        item as EConfigGarment,
        cloth.modelFolderPath
      );
    }
  }

  setSingleDesignTexturePath = (designsList: IDesignParams[], design: EConfigDesigns, garment: EConfigGarment, modelFolderPath: string) => {
    if (!designsList.length || !design) {
      this.designTexturesPath[garment] = [];
      return;
    }

    if (!designsList[0].name) {
      // simple 'design' folder without nested 'design name' directories
      const result = [];
      for (let i = 0; i < designsList[0].totalColors; i++) {
        result.push(`${modelFolderPath}designs/default/paint${i + 1}.png`);
      }
      this.designTexturesPath[garment] = result;
      return;
    }

    // complex 'design' folder with nested 'design name' directories

    const myDesign = designsList.find(item => item.name === design);
    if (myDesign) {
      const result = [];
      for (let i = 0; i < myDesign.totalColors; i++) {
        result.push(`${modelFolderPath}designs/${design!.toLowerCase()}/paint${i + 1}.png`);
      }
      this.designTexturesPath[garment] = result;
    }
  }

}

export default new CanvasStore();
