import {EConfigGarment} from "../enums";
import {IClothPadImages} from "../components/ClothSelect/ClothPad";
import clothFootballShirt from "../assets/icons/cloth_football_jersey.svg";
import clothFootballShirtSelected from "../assets/icons/cloth_football_jersey_selected.svg";
import clothFootballShirtDisabled from "../assets/icons/cloth_football_jersey_disabled.svg";
import clothFootballShorts from "../assets/icons/cloth_football_shorts.svg";
import clothFootballShortsSelected from "../assets/icons/cloth_football_shorts_selected.svg";
import clothFootballShortsDisabled from "../assets/icons/cloth_football_shorts_disabled.svg";
import clothFootballSocks from "../assets/icons/cloth_football_socks.svg";
import clothFootballSocksSelected from "../assets/icons/cloth_football_socks_selected.svg";
import clothFootballSocksDisabled from "../assets/icons/cloth_football_socks_disabled.svg";

const getClothPadImages = (item: EConfigGarment): IClothPadImages => {
  let cloth = '';
  let currentCloth = '';
  let disableCloth = '';

  switch (item) {
    case EConfigGarment.JERSEY: {
      cloth = clothFootballShirt;
      currentCloth = clothFootballShirtSelected;
      disableCloth = clothFootballShirtDisabled;
      break;
    }
    case EConfigGarment.SHORTS: {
      cloth = clothFootballShorts;
      currentCloth = clothFootballShortsSelected;
      disableCloth = clothFootballShortsDisabled;
      break;
    }
    case EConfigGarment.SOCKS: {
      cloth = clothFootballSocks;
      currentCloth = clothFootballSocksSelected;
      disableCloth = clothFootballSocksDisabled;
      break;
    }
  }

  return {
    cloth,
    currentCloth,
    disableCloth
  }
}

export default getClothPadImages;
