import React, {useState} from 'react';
import { EConfigGarment } from '../../enums';
import store from '../../store/rootStore';
import ClothPad from './ClothPad';
import { observer } from 'mobx-react-lite';
import { WrappedClothSelect } from './style';
import getClothPadImages from "../../utils/getClothPadImages";
import useClothPads from "../../hooks/useClothPads";

const ClothSelect: React.FC = observer(() => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
  const {
    currentGarment,
    setCurrentGarment,
    includeJersey,
    includeShorts,
    includeSocks,
    hideSocks,
  } = store.app;
  const {viewportHeight} = store.canvas;

  const { handleInclude, getInactive } = useClothPads({});

  const handleClick = (item: EConfigGarment) => {
    setCurrentGarment(item);
  }

  const handleCollapse = (e: React.MouseEvent<HTMLDivElement>, value: boolean) => {
    e.stopPropagation();
    setIsCollapsed(value);
  }

  const getCheckedStatus = (item: string): boolean => {
    let isPlus = false;
    if (item === EConfigGarment.SHORTS) {
      isPlus = includeShorts;
    }
    if (item === EConfigGarment.SOCKS ) {
      isPlus = includeSocks;
    }
    if (item === EConfigGarment.JERSEY) {
      isPlus = includeJersey;
    }
    return isPlus;
  }

  return (
    <WrappedClothSelect
      isCollapsed={isCollapsed}
      onClick={(e) => handleCollapse(e, true)}
      viewportHeight={viewportHeight}
    >
      <div className='wrapper'>
        <div
          className='collapsed'
          onClick={(e) => handleCollapse(e, false)}
        >
          <img src={getClothPadImages(currentGarment).cloth} alt='icon'/>
        </div>
        <div className="exploded">
          {
            Object.values(EConfigGarment).map((item, index) => {
              if (hideSocks && item === EConfigGarment.SOCKS) {
                return null;
              }
              let isPlus = getCheckedStatus(item);
              return <ClothPad
                item={item}
                isSelected={item === currentGarment}
                isInactive={getInactive(item)}
                handleClick={() => handleClick(item)}
                handleButton={() => handleInclude({item, isPlus, includeJersey, includeShorts, includeSocks})}
                isPlus={isPlus}
                images={getClothPadImages(item)}
                key={index}
              />
            })
          }
        </div>
      </div>
    </WrappedClothSelect>
  );
})

export default ClothSelect;
