import styled from 'styled-components';
import { BTN_BG_COLOR_DISABLED, BTN_BORDER_COLOR } from '../../../constants';

interface IProps {
  isMenu?: boolean;
}

export const WrappedButtonOpener = styled.button<IProps>`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  background-color: white;
  border: 1px solid ${BTN_BORDER_COLOR};
  border-radius: 16px;
  cursor: pointer;
  min-width: ${props => props.isMenu ? '85px' : '56px'};
  height: 56px;
  padding: 0 8px;

  .burger__img {
    width: 20px;
  }

  .arrow__img {
    width: 11px;
  }

  p {
    font-weight: 700;
  }

  &:hover {
    border: 1px solid ${BTN_BG_COLOR_DISABLED};
    background: ${BTN_BG_COLOR_DISABLED};
  }

  @media all and (max-width: 1920px) {
    min-width: ${props => props.isMenu ? '90px' : '42px'};
    height: 42px;
    border-radius: 12px;

    .burger__img {
      width: 16px;
    }

    .arrow__img {
      width: 9px;
    }
  }

  @media all and (max-width: 768px) {
    min-width: 32px;
    height: 32px;
    border-radius: 8px;

    .burger__img {
      width: 14px;
    }

    .arrow__img {
      width: 7px;
    }
    
    .title {
      display: none;
    }
  }
`
