import styled from 'styled-components';
import { BTN_BG_COLOR_DISABLED, BTN_TEXT_COLOR_PRESSED } from '../../../constants';
import {SxProps} from "@mui/material";

export const MenuSelectorPaperStyle: SxProps = {
  background: '#FFFFFF',
  borderRadius: '16px',
  padding: '16px 16px 88px 16px',
  boxShadow: '0px 8px 33px -16px rgba(0, 0, 0, 0.25)',
}

export const FakeMenuSelectorButton = styled('div')`
  pointer-events: none;
  position: absolute;
  right: -8px;
  bottom: -8px;
`;

interface IOptionProps {
  isSelected: boolean;
  isActive: boolean;
}

export const WrappedOption = styled.button<IOptionProps>`
  width: 154px;
  padding: 5px 16px;
  font-weight: 700;
  background: #FFFFFF;
  color: ${props => props.isSelected ? BTN_TEXT_COLOR_PRESSED : 'inherit'};
  border: ${props => props.isSelected ? '1px solid #5DB799' : '1px solid transparent'};
  border-radius: 8px;
  position: relative;
  cursor: ${props => props.isActive && !props.isSelected ? 'pointer' : 'default'};
  opacity: ${props => props.isActive ? '1' : '0.5'};
  transition: background .2s;

  &:hover {
    background: ${props => props.isActive && !props.isSelected ? BTN_BG_COLOR_DISABLED : 'unset'};
  }
`
