import React, {useEffect, useRef, useState} from 'react';
import {observer} from "mobx-react-lite";
import store from '../../store/rootStore';
import styled from "styled-components";
import {CalcExitButton, CalcMenuPaperStyle, FakeCalcMenuButton} from "./style";
import {EButtonOpenerTypes} from "../../enums";
import ButtonOpener from "../../components/UI/ButtonOpener/ButtonOpener";
import {Popover, useMediaQuery} from "@mui/material";
import CalculatorLayout from "./components/CalculatorLayout";

const Wrapper = styled.div`
  position: relative;
`;

const Calculator = observer(() => {
  const { currentStep } = store.app;

  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const fakeRef = useRef<HTMLDivElement>(null);
  const isMobile = useMediaQuery('(max-width: 768px)');

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    handleClose();
  }, [isMobile])

  if (currentStep === 0 || currentStep === 5) {
    return null;
  }

  const handleOpen = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClick = () => {
    fakeRef.current?.click();
  }

  const open = Boolean(anchorEl);

  const id = open ? 'simple-popover' : undefined;

  return (
    <Wrapper>
      <Popover
        id={id}
        style={isMobile ? {
          top: '-20px',
          right: '-20px',
        }: {}}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        PaperProps={{
          sx: CalcMenuPaperStyle,
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transitionDuration={150}
      >
        <CalculatorLayout />
        <CalcExitButton>
          <ButtonOpener
            type={EButtonOpenerTypes.CLOSE}
            handleClick={handleClose}
            isMenu
          />
        </CalcExitButton>
      </Popover>
      <ButtonOpener
        type={EButtonOpenerTypes.CALC}
        handleClick={handleClick}
        isMenu
        style={{
          visibility: open ? 'hidden' : 'visible',
        }}
      />
      <FakeCalcMenuButton
        ref={fakeRef}
        onClick={handleOpen}
      />
    </Wrapper>
  );
});

export default Calculator;
