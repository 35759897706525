import { EConfigGarment } from "../enums";
import {ICatalog} from "./types";
import {
  priceJersey,
  priceJerseyRugby,
  priceShorts,
  priceShortsRugbyRacket,
  priceSocksFootball, priceSocksRugby
} from "./priceAndDiscount";

/*
collections ID:
0 - Renaissance
1 - Fire
2 - Fire woman
3 - Socks
*/

export const catalog: ICatalog[] = [
  // FOOTBALL
  {
    infoId: 'football-jersey-fire', // ! id must be equal the 'info' block in locals/lng/translation.json
    sportId: '0',
    garment: EConfigGarment.JERSEY,
    collectionId: '1',
    price: priceJersey,
    cat: {
      men: '038',
      women: '039',
      kids: '040',
    },
  },

  {
    infoId: 'football-short-fire',
    sportId: '0',
    garment: EConfigGarment.SHORTS,
    collectionId: '1',
    price: priceShorts,
    cat: {
      men: '041',
      women: '042',
      kids: '043',
    },
  },

  {
    infoId: 'football-jersey-ren',
    sportId: '0',
    garment: EConfigGarment.JERSEY,
    collectionId: '0',
    price: priceJersey,
    cat: {
      men: '044',
      women: '045',
      kids: '046',
    },
  },

  {
    infoId: 'football-short-ren',
    sportId: '0',
    garment: EConfigGarment.SHORTS,
    collectionId: '0',
    price: priceShorts,
    cat: {
      men: '047',
      women: '048',
      kids: '049',
    },
  },

  // BASKETBALL

  {
    infoId: 'basket-jersey-ren',
    sportId: '1',
    garment: EConfigGarment.JERSEY,
    collectionId: '0',
    price: priceJersey,
    cat: {
      men: '051',
      women: '080',
      kids: '052',
    },
  },

  {
    infoId: 'basket-short-ren',
    sportId: '1',
    garment: EConfigGarment.SHORTS,
    collectionId: '0',
    price: priceShorts,
    cat: {
      men: '054',
      women: '092',
      kids: '055',
    },
  },

  {
    infoId: 'basket-jersey-fire',
    sportId: '1',
    garment: EConfigGarment.JERSEY,
    collectionId: '1',
    price: priceJersey,
    cat: {
      men: '059',
      women: '081',
      kids: '060',
    },
  },

  {
    infoId: 'basket-short-fire',
    sportId: '1',
    garment: EConfigGarment.SHORTS,
    collectionId: '1',
    price: priceShorts,
    cat: {
      men: '061',
      women: '094',
      kids: '062',
    },
  },

  // HANDBALL

  {
    infoId: 'handball-jersey-ren',
    sportId: '2',
    garment: EConfigGarment.JERSEY,
    collectionId: '0',
    price: priceJersey,
    cat: {
      men: '085',
      women: '105',
      kids: '086',
    },
  },

  {
    infoId: 'handball-jersey-fire',
    sportId: '2',
    garment: EConfigGarment.JERSEY,
    collectionId: '1',
    price: priceJersey,
    cat: {
      men: '125',
      women: '127',
      kids: '126',
    },
  },

  {
    infoId: 'handball-short-fire',
    sportId: '2',
    garment: EConfigGarment.SHORTS,
    collectionId: '1',
    price: priceShorts,
    cat: {
      men: '129',
      women: '131',
      kids: '130',
    },
  },

  {
    infoId: 'handball-short-ren',
    sportId: '2',
    garment: EConfigGarment.SHORTS,
    collectionId: '0',
    price: priceShorts,
    cat: {
      men: '132',
      women: '134',
      kids: '133',
    },
  },

  // RACKET

  {
    infoId: 'racket-jersey-ren',
    sportId: '3',
    garment: EConfigGarment.JERSEY,
    collectionId: '0',
    price: priceJersey,
    cat: {
      men: '135',
      women: '137',
      kids: '136',
    },
  },

  {
    infoId: 'racket-short-ren',
    sportId: '3',
    garment: EConfigGarment.SHORTS,
    collectionId: '0',
    price: priceShortsRugbyRacket,
    cat: {
      men: '139',
      women: '141',
      kids: '140',
    },
  },

  // RUGBY

  {
    infoId: 'rugby-short-fire',
    sportId: '4',
    garment: EConfigGarment.SHORTS,
    collectionId: '1',
    price: priceShortsRugbyRacket,
    cat: {
      men: '063',
      women: '101',
      kids: '064',
    },
  },

  {
    infoId: 'rugby-jersey-ren',
    sportId: '4',
    garment: EConfigGarment.JERSEY,
    collectionId: '0',
    price: priceJerseyRugby,
    cat: {
      men: '065',
      women: '102',
      kids: '066',
    },
  },

  {
    infoId: 'rugby-short-ren',
    sportId: '4',
    garment: EConfigGarment.SHORTS,
    collectionId: '0',
    price: priceShortsRugbyRacket,
    cat: {
      men: '067',
      women: '103',
      kids: '068',
    },
  },

  {
    infoId: 'rugby-jersey-fire',
    sportId: '4',
    garment: EConfigGarment.JERSEY,
    collectionId: '1',
    price: priceJerseyRugby,
    cat: {
      men: '069',
      women: '104',
      kids: '070',
    },
  },

  // VOLLEYBALL

  {
    infoId: 'volley-short-fire',
    sportId: '5',
    garment: EConfigGarment.SHORTS,
    collectionId: '1',
    price: priceShorts,
    cat: {
      men: '142',
      women: null,
      kids: '143',
    },
  },

  {
    infoId: 'volley-short-ren',
    sportId: '5',
    garment: EConfigGarment.SHORTS,
    collectionId: '0',
    price: priceShorts,
    cat: {
      men: '145',
      women: '147',
      kids: '146',
    },
  },

  {
    infoId: 'volley-jersey-fire-women',
    sportId: '5',
    garment: EConfigGarment.JERSEY,
    collectionId: '2',
    price: priceJersey,
    cat: {
      men: null,
      women: '148',
      kids: null,
    },
  },

  {
    infoId: 'volley-jersey-fire',
    sportId: '5',
    garment: EConfigGarment.JERSEY,
    collectionId: '1',
    price: priceJersey,
    cat: {
      men: '071',
      women: null,
      kids: '072',
    },
  },

  {
    infoId: 'volley-jersey-ren',
    sportId: '5',
    garment: EConfigGarment.JERSEY,
    collectionId: '0',
    price: priceJersey,
    cat: {
      men: '074',
      women: '149',
      kids: '075',
    },
  },

  // SOCKS

  {
    infoId: 'socks',
    sportId: '0',
    garment: EConfigGarment.SOCKS,
    collectionId: '0',
    price: priceSocksFootball,
    cat: {
      men: '003',
      women: '003',
      kids: '003',
    },
  },

  {
    infoId: 'socks',
    sportId: '4',
    garment: EConfigGarment.SOCKS,
    collectionId: '0',
    price: priceSocksRugby,
    cat: {
      men: '003',
      women: '003',
      kids: '003',
    },
  },
];
