import { IModel } from "./types";
import { EConfigDesigns, EConfigGarment } from "../enums";
const UPPER = '/models/upperbody/';
const LOWER = '/models/lowerbody/';
const FOOT = '/models/foot/';

/*
RULES OF ADDING THE DESIGN TEXTURES

paint1.png = base
paint2.png = details
paint3.png = logo
paint4.png = design1
paint5.png = design2
paint6.png = design3

if design has less than 3 textures:
paint1.png = base
paint2.png = logo
*/

const designSet1 = [ // rugby fire
  { name: EConfigDesigns.DEFAULT, totalColors: 3, info: 'DEFAULT design info' },
  { name: EConfigDesigns.ADA, totalColors: 4 },
  { name: EConfigDesigns.ALESSANDRO, totalColors: 4, info: 'ALESSANDRO design info' },
  { name: EConfigDesigns.ALLEN, totalColors: 5, info: 'ALLEN design info' },
  { name: EConfigDesigns.BJORN, totalColors: 4, info: 'BJORN design info' },
  { name: EConfigDesigns.GABIN, totalColors: 4, info: 'GABIN design info' },
  // { name: EConfigDesigns.GUGA, totalColors: 4, info: 'GUGA design info' },
  { name: EConfigDesigns.HENRIK, totalColors: 4, info: 'HENRIK design info' },
  { name: EConfigDesigns.JOHAN, totalColors: 4, info: 'JOHAN design info' },
  { name: EConfigDesigns.KARCH, totalColors: 4, info: 'KARCH design info' },
  { name: EConfigDesigns.LEWIS, totalColors: 4, info: 'LEWIS design info' },
  { name: EConfigDesigns.MARAT, totalColors: 4, info: 'MARAT design info' },
  { name: EConfigDesigns.MEGAN, totalColors: 6, info: 'MEGAN design info' },
  { name: EConfigDesigns.MICHEL, totalColors: 4, info: 'MICHEL design info' },
  { name: EConfigDesigns.NIKOLA, totalColors: 4, info: 'NIKOLA design info' },
  { name: EConfigDesigns.ROD, totalColors: 4, info: 'ROD design info' },
  { name: EConfigDesigns.SAFET, totalColors: 5, info: 'SAFET design info' },
  { name: EConfigDesigns.SEBASTIEN, totalColors: 5, info: 'SEBASTIEN design info' },
  { name: EConfigDesigns.SHELLY, totalColors: 4, info: 'SHELLY design info' },
  { name: EConfigDesigns.STEVEN, totalColors: 4, info: 'STEVEN design info' },
  { name: EConfigDesigns.TONY, totalColors: 5, info: 'TONY design info' },
];

const designSet2 = [ // standard
  { name: EConfigDesigns.DEFAULT, totalColors: 3, info: 'DEFAULT design info' },
  { name: EConfigDesigns.ADA, totalColors: 4},
  { name: EConfigDesigns.ALESSANDRO, totalColors: 4, info: 'ALESSANDRO design info' },
  { name: EConfigDesigns.ALLEN, totalColors: 5, info: 'ALLEN design info' },
  { name: EConfigDesigns.BJORN, totalColors: 4, info: 'BJORN design info' },
  { name: EConfigDesigns.GABIN, totalColors: 4, info: 'GABIN design info' },
  // { name: EConfigDesigns.GUGA, totalColors: 4, info: 'GUGA design info' },
  { name: EConfigDesigns.HENRIK, totalColors: 4, info: 'HENRIK design info' },
  { name: EConfigDesigns.JOHAN, totalColors: 4, info: 'JOHAN design info' },
  { name: EConfigDesigns.KARCH, totalColors: 4, info: 'KARCH design info' },
  { name: EConfigDesigns.LEWIS, totalColors: 4, info: 'LEWIS design info' },
  { name: EConfigDesigns.MARAT, totalColors: 4, info: 'MARAT design info' },
  { name: EConfigDesigns.MEGAN, totalColors: 6, info: 'MEGAN design info' },
  { name: EConfigDesigns.MICHEL, totalColors: 4, info: 'MICHEL design info' },
  { name: EConfigDesigns.NIKOLA, totalColors: 4, info: 'NIKOLA design info' },
  { name: EConfigDesigns.ROD, totalColors: 5, info: 'ROD design info' },
  { name: EConfigDesigns.SAFET, totalColors: 5, info: 'SAFET design info' },
  { name: EConfigDesigns.SEBASTIEN, totalColors: 5, info: 'SEBASTIEN design info' },
  { name: EConfigDesigns.SHELLY, totalColors: 4, info: 'SHELLY design info' },
  { name: EConfigDesigns.STEVEN, totalColors: 4, info: 'STEVEN design info' },
  { name: EConfigDesigns.TONY, totalColors: 5, info: 'TONY design info' },
];

/*
0 - Football
1 - Basketball
2 - Handball
3 - Racket
4 - Rugby
5 - Volleyball
*/

export const defaultModels = {
  football: {
    jersey: '2',
    shorts: '102',
    collectionJersey: '1',
  },
  basketball: {
    jersey: '0',
    shorts: '100',
    collectionJersey: '1',
  },
  handball: {
    jersey: '3',
    shorts: '102',
    collectionJersey: '0',
  },
  racket: {
    jersey: '5',
    shorts: '104',
    collectionJersey: '0',
  },
  rugby: {
    jersey: '6',
    shorts: '105',
    collectionJersey: '1',
  },
  volleyball: {
    jersey: '8',
    shorts: '102',
    collectionJersey: '1',
  },
}

export const models: IModel[] = [
  // upperbody
  {
    id: '0', 
    modelPath: UPPER + 'basketball_fire/',
    designsList: designSet2,
    collectionId: '1',
    garment: EConfigGarment.JERSEY,
    sportIds: ['1'],
    isAo: true,
    gender: 'm',
  },
  {
    id: '1', 
    modelPath: UPPER + 'basketball_renaissance/',
    designsList: designSet2,
    collectionId: '0',
    garment: EConfigGarment.JERSEY,
    sportIds: ['1'],
    isAo: false,
    gender: 'm',
  },
  {
    id: '2', 
    modelPath: UPPER + 'football_fire/',
    designsList: designSet2,
    collectionId: '1',
    garment: EConfigGarment.JERSEY,
    sportIds: ['0'],
    isAo: true,
    gender: 'm',
  },
  {
    id: '3', 
    modelPath: UPPER + 'football_volleyball_handball_renaissance/',
    designsList: designSet2,
    collectionId: '0',
    garment: EConfigGarment.JERSEY,
    sportIds: ['0', '2', '5'],
    isAo: false,
    gender: 'm',
  },
  {
    id: '5', 
    modelPath: UPPER + 'racket_renaissance/',
    designsList: designSet2,
    collectionId: '0',
    garment: EConfigGarment.JERSEY,
    sportIds: ['3'],
    isAo: false,
    gender: 'm',
  },
  {
    id: '6', 
    modelPath: UPPER + 'rugby_fire/',
    designsList: designSet1,
    collectionId: '1',
    garment: EConfigGarment.JERSEY,
    sportIds: ['4'],
    isAo: false,
    gender: 'm',
    
  },
  {
    id: '7', 
    modelPath: UPPER + 'rugby_renaissance/',
    designsList: designSet2,
    collectionId: '0',
    garment: EConfigGarment.JERSEY,
    sportIds: ['4'],
    isAo: false,
    gender: 'm',
  },
  {
    id: '8', 
    modelPath: UPPER + 'volleyball_fire/',
    designsList: designSet2,
    collectionId: '1',
    garment: EConfigGarment.JERSEY,
    sportIds: ['5'],
    isAo: true,
    gender: 'm',
  },
  // {
  //   id: '9', 
  //   modelPath: UPPER + 'volleyball_fire_woman/',
  //   designsList: designSet2,
  //   collectionId: '2',
  //   garment: EConfigGarment.JERSEY,
  //   sportIds: ['5'],
  //   isAo: true,
  //   gender: 'w',
  // },

  // lowerbody
  {
    id: '100', 
    modelPath: LOWER + 'basketball_fire/',
    designsList: [{totalColors: 2}],
    collectionId: '1',
    garment: EConfigGarment.SHORTS,
    sportIds: ['1'],
    isAo: true,
    gender: 'm',
  },
  {
    id: '101', 
    modelPath: LOWER + 'basketball_renaissance/',
    designsList: [{totalColors: 3}],
    collectionId: '0',
    garment: EConfigGarment.SHORTS,
    sportIds: ['1'],
    isAo: false,
    gender: 'm',
  },
  {
    id: '102', 
    modelPath: LOWER + 'football_handball_volleyball_fire/',
    designsList: [{totalColors: 3}],
    collectionId: '1',
    garment: EConfigGarment.SHORTS,
    sportIds: ['0', '2', '5'],
    isAo: true,
    gender: 'm',
  },
  {
    id: '103', 
    modelPath: LOWER + 'football_handball_volleyball_renaissance/',
    designsList: [{totalColors: 3}],
    collectionId: '0',
    garment: EConfigGarment.SHORTS,
    sportIds: ['0', '2', '5'],
    isAo: true,
    gender: 'm',
  },
  {
    id: '104', 
    modelPath: LOWER + 'racket_renaissance/',
    designsList: [{totalColors: 2}],
    collectionId: '0',
    garment: EConfigGarment.SHORTS,
    sportIds: ['3'],
    isAo: false,
    gender: 'm',
  },
  {
    id: '105', 
    modelPath: LOWER + 'rugby_fire/',
    designsList: [{totalColors: 2}],
    collectionId: '1',
    garment: EConfigGarment.SHORTS,
    sportIds: ['4'],
    isAo: true,
    gender: 'm',
  },
  {
    id: '106', 
    modelPath: LOWER + 'rugby_renaissance/',
    designsList: [{totalColors: 3}],
    collectionId: '0',
    garment: EConfigGarment.SHORTS,
    sportIds: ['4'],
    isAo: true,
    gender: 'm',
  },
  {
    id: '107', 
    modelPath: LOWER + 'volleyball_woman/',
    designsList: [{totalColors: 2}],
    collectionId: '0',
    garment: EConfigGarment.SHORTS,
    sportIds: ['5'],
    isAo: true,
    gender: 'w',
  },

  // foot

  {
    id: '200', 
    modelPath: FOOT + 'socks/',
    designsList: [{totalColors: 2}],
    collectionId: '3',
    garment: EConfigGarment.SOCKS,
    sportIds: ['0', '1', '2', '3', '4', '5'],
    isAo: true,
    gender: 'm',
  },
]
