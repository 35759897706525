import styled, {css} from 'styled-components';
import { BTN_BG_COLOR_DISABLED, BTN_BORDER_COLOR, scrollBarCss } from '../../constants';

const modalCSS = css`
  background-color: rgba(34, 34, 34, 0.5);
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 2000;
  display: flex;
  align-items: center;
  justify-content: center;
`

const mobileOpenedCSS = css`
  align-items: flex-start;
  padding-top: 80px;
`

const mobileClosedCSS = css`
  top: 80px;
  right: 16px;
`

export const hoverStyle = css`
  background: ${BTN_BG_COLOR_DISABLED};
  border: 1px solid ${BTN_BG_COLOR_DISABLED};
`

interface IInfo {
  isOpen: boolean;
}

export const WrappedInfo = styled.div<IInfo>`
  width: 100%;
  top: 172px;
  right: 80px;
  position: absolute;
  z-index: 999;

  .info {
    padding: 16px;
    background: #FFFFFF;
    border: 1px solid ${BTN_BORDER_COLOR};
    border-radius: 16px;
    width: ${props => props.isOpen ? '400px' : '56px'};
    height: ${props => props.isOpen ? 'auto' : '56px'};
    min-height: 48px;
    position: absolute;
    right: 0;
    transition: width .2s ease;

    img {
      width: 24px;
      position: absolute;
      top: 16px;
      right: 16px;
      cursor: pointer;
    }

    p {
      font-style: italic;
      user-select: text;
      white-space: pre-wrap;
    }
    
    h2 {
      padding-right: 24px;
    }

    .heading {
      margin-top: 10px;
      margin-bottom: 10px;
      font-weight: 700;
    }

    &:hover {
      ${props => !props.isOpen && hoverStyle}
    }
  }
  

  @media all and (max-width: 1920px) {
    top: 120px;

    .info {
      width: ${props => props.isOpen ? '400px' : '48px'};
      height: ${props => props.isOpen ? 'auto' : '48px'};
      padding: 12px;
      
      img {
        width: 21px;
        top: 12px;
        right: 12px;
      }
    }
  }

  @media all and (max-width: 768px) {
    ${props => props.isOpen ? modalCSS : mobileClosedCSS}
    ${props => props.isOpen && mobileOpenedCSS}

    .info {
      width: ${props => props.isOpen ? '90%' : '32px'};
      height: ${props => props.isOpen ? 'auto' : '32px'};
      min-height: 32px;
      padding: 8px;
      border-radius: 8px;
      margin: ${props => props.isOpen ? '0 auto' : 'unset'};
      position: ${props => props.isOpen ? 'relative' : 'absolute'};
      right: ${props => props.isOpen ? 'unset' : '16px'};

      img {
        width: 14px;
        top: 8px;
        right: 8px;
      }
    }
  }
`

export const WrappedModal = styled.div`
  ${modalCSS}
`

interface IDialogCommonProps {
  confirmWidth?: string;
  declineWidth?: string;
}

export const WrappedDialogCommon = styled.div<IDialogCommonProps>`
  display: flex;
  flex-direction: column;
  width: 400px;
  padding: 32px;
  background: #FFFFFF;
  border-radius: 16px;
  transform: translateX(-40px);

  .text {
    text-align: center;
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 32px;
  }

  .text__describe {
    font-weight: 400;
    font-size: 16px;
  }

  .buttons {
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 32px;

    button {
      padding: 15px;
      height: 50px;
      font-size: 14px;

      img {
        width: 20px;
        height: 20px;
      }

      &:nth-child(1) {
        width: ${props => props.confirmWidth ? props.confirmWidth : '152px'};
      }

      &:nth-child(2) {
        width: ${props => props.declineWidth ? props.declineWidth : '152px'};
      }
    }
  }

  @media all and (max-width: 768px) {
    width: 90%;
    min-width: 270px;
  }
`

export const WrappedDialogConfirm = styled(WrappedDialogCommon)`
  opacity: 0;
`

export const WrappedDialogWarning = styled(WrappedDialogCommon)`
  .buttons {
    justify-content: center;
  }
`

export const WrappedDialogNotice = styled(WrappedDialogCommon)`
  opacity: 0;

  .buttons {
    justify-content: center;
  }

  .main_text {
    margin-bottom: 16px;
  }

  .secondaryText {
    font-size: 14px;
    font-weight: 400;
  }

  @media all and (max-width: 768px) {
    .secondaryText {
      font-size: 12px;
    }
  }
`

export const WrappedOrderDetails = styled.div`
  background: white;
  border-radius: 16px;
  width: 760px;
  position: relative;
  transform: translateX(-40px);
  opacity: 0;

  .orderHeading {
    padding: 0 40px;
    padding-top: 24px;
    border-radius: 16px 16px 0 0;

    h2 {
      font-style: normal;
    }

    .hint {
      margin-top: 15px;
    }

    .btn_close {
      position: absolute;
      top: 24px;
      right: 40px;
      cursor: pointer;
    }
  }
  
  form {
    padding: 24px 40px;
    padding-top: 0;
    width: 100%;
    
    .wrap {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 36px;

      .wrap_cell {
        width: 300px;
        height: 100px;
      }
    }

    .wrap_submit {
      width: 100%;
      display: flex;
      justify-content: center;

      > button {
        width: 150px;
        height: 50px;

        p {
          font-size: 14px;
        }
      }
    }

    .wrap_message {
      textarea {
        height: 150px;
      }
    }

    input {
      margin: 8px 0 5px 0;
    }

    .error, .req {
      color: red;
      font-size: 13px;
      font-weight: 400;
    }

    .error__message {
      height: 1em;
      margin-bottom: 32px;
    }

    label {
      font-weight: 700;
    }
  }

  @media all and (max-width: 768px) {
    width: 90%;
    min-width: 300px;
    height: 90%;
    padding-top: 0;

    overflow-y: scroll;
    ${scrollBarCss}

    .orderHeading {
      top: 0;
      padding-top: 24px;
      padding-bottom: 7px;
      background: white;
      position: sticky;
      box-shadow: 0px 7px 5px -5px rgba(34, 60, 80, 0.3);

      .btn_close {
        top: 24px;
      }
    }

    form {
      padding-top: 0;

      .wrap {
        flex-wrap: nowrap;
        flex-direction: column;

        .wrap_cell {
          width: 100%;
        }
      }

      .wrap_submit {
        > button {
          width: 120px;
          height: 40px;
        }
      }

      .wrap_message {
        textarea {
          height: 100px;
        }
      }
    }
  }
`
