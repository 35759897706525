import React from 'react';
import { SwiperSlide } from 'swiper/react';
import {ColorHex, IColorsData} from '../../db/types';
import Carousel from '../UI/Carousel/Carousel';
import ColorSlot from './ColorSlot';
import { WrappedColorPicker } from './colorStyle';
import {IS_COLOR_PICKER} from "../../constants";

interface IProps {
  handleClick: (color: ColorHex) => void;
  currentColor: ColorHex | undefined;
  isCarousel?: boolean;
  data: IColorsData;
}

const ColorPicker: React.FC<IProps> = ({
  handleClick,
  currentColor,
  isCarousel,
  data,
}) => {
  const handleCopy = () => {
    navigator.clipboard.writeText(currentColor || '');
  }

  const WrapComponent = (): JSX.Element => {
    if (data.arrFirstHalf && data.arrSecondHalf) {
      return <div className="wrap">
        <div className="wrap__slider">
          {
            data.arrFirstHalf.map((color, index) => <ColorSlot
                key={index}
                handleClick={() => handleClick(color)}
                isSelected={currentColor === color}
                color={color}
            />)
          }
        </div>
        <div className="wrap__slider">
          {
            data.arrSecondHalf.map((color, index) => <ColorSlot
                key={index}
                handleClick={() => handleClick(color)}
                isSelected={currentColor === color}
                color={color}
            />)
          }
        </div>
      </div>
    }

    return <div className="wrap">
      <div className="wrap__slider">
        {
          data.arr.map((color, index) => <ColorSlot
              key={index}
              handleClick={() => handleClick(color)}
              isSelected={currentColor === color}
              color={color}
          />)
        }
      </div>
    </div>
  }

  return (
    <WrappedColorPicker isCarousel={isCarousel}>
      {
        IS_COLOR_PICKER
        && <div className='color-picker'>
          <input
            type='color'
            onChange={(e) => handleClick(e.target.value)}
            value={currentColor}
          />
          <button onClick={handleCopy}>Copy HEX</button>
        </div>
      }
      {
        isCarousel ? 

          <Carousel
            slidesPerView={12}
            wrappedHeight={80}
            totalItems={data.arr.length}
          >
            {
              data.arr.map((color, index) => <SwiperSlide
                  key={index}
                  onClick={() => handleClick(color)}
                >
                  <ColorSlot 
                    isSelected={currentColor === color} 
                    color={color}
                  />
                </SwiperSlide>)
            }
          </Carousel> :

          <WrapComponent />

      }
    </WrappedColorPicker>
  );
}

export default ColorPicker;
