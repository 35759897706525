import {useCallback, useState} from "react";

interface LocalStorageReturn {
  onWrite: (slot: string, value: unknown) => void;
  onRead: (slot: string) => unknown;
  onRemove: (slot: string) => void;
}

const useLocalStorage = (): LocalStorageReturn => {
  const [canWrite, setCanWrite] = useState<boolean>(true);

  const onWrite = useCallback((slot: string, value: unknown) => {
    if (!canWrite) {
      return;
    }
    const val = JSON.stringify(value);

    try {
      localStorage.setItem(slot, val);
    } catch (e) {
      console.log(e);
      setCanWrite(false);
    }
  }, [canWrite]);

  const onRead = useCallback((slot: string): unknown => {
    const save = localStorage.getItem(slot);
    if (save) {
      return JSON.parse(save);
    }
  }, []);

  const onRemove = useCallback((slot: string) => {
    localStorage.removeItem(slot);
  }, []);

  return {
    onWrite,
    onRead,
    onRemove,
  }
};

export default useLocalStorage;
