import React from 'react';
import { WrappedModal } from './popupStyle';
import {observer} from "mobx-react-lite";
import store from '../../store/rootStore';

const Modal: React.FC = observer(({children}) => {
  const { setOpenDialog } = store.app;

  const handleClick = () => {
    setOpenDialog(false);
  }

  return (
    <WrappedModal
      onClick={handleClick}
    >
      {children}
    </WrappedModal>
  );
});

export default Modal;
