import { TextNotFound } from '../constants';
import { models } from '../db/models';
import { IDesignParams } from '../db/types';
import { IBaseTexturesPath } from '../store/canvasStore';

export interface IReturnModel {
  modelPath: string;
  modelFolderPath: string;
  texturesPath: IBaseTexturesPath;
  designsList: IDesignParams[];
}

export const getModelResources = (modelId: string): IReturnModel | string => {
  const model = models.find(item => item.id === modelId);
  if (!model) {
    return TextNotFound;
  }
  const designsList = model.designsList;
  const modelFolderPath = model.modelPath;
  const modelPath = `${model.modelPath}model.glb`;
  const texturesPath: IBaseTexturesPath = {
    base: `${model.modelPath}textures/base.png`, 
    normal: `${model.modelPath}textures/normal.jpg`, 
    roughness: `${model.modelPath}textures/roughness.jpg`, 
    ao: null,
  }
  if (model.isAo) {
    texturesPath.ao = `${model.modelPath}textures/ao.jpg`;
  }

  return {
    modelFolderPath,
    modelPath,
    texturesPath,
    designsList
  }
}

export interface IReturnAllModels {
  jersey: IReturnModel;
  shorts: IReturnModel;
  socks: IReturnModel;
}

export const getAllModelsResources = (jerseyModelId: string, shortsModelId: string, socksModelId: string): IReturnAllModels | string => {
  const jersey = getModelResources(jerseyModelId);
  if (typeof jersey === 'string') return TextNotFound;
  const shorts = getModelResources(shortsModelId);
  if (typeof shorts === 'string') return TextNotFound;
  const socks = getModelResources(socksModelId);
  if (typeof socks === 'string') return TextNotFound;
  const result: IReturnAllModels = {
    jersey,
    shorts,
    socks,
  }
  //console.log('found model res: ', result)
  return result;
}