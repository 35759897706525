import React from 'react';
import { WrappedButton } from './buttonStyle';

interface IProps {
  handleClick?: () => void;
  isPressed?: boolean;
  background?: string;
  color?: string;
  width?: string;
  height?: string;
  style?: React.CSSProperties;
  isActive?: boolean;
  isSubmit?: boolean;
}

const Button: React.FC<IProps> = ({ children, handleClick, isPressed, background, color, width, height, style, isActive, isSubmit}) => {

  const click = () => {
    if (isActive === false) {
      return;
    }
    handleClick && handleClick();
  }

  return (
    <WrappedButton
      type={isSubmit ? 'submit' : 'button'}
      onClick={click}
      pressed={isPressed}
      background={background}
      color={color}
      width={width}
      height={height}
      style={{...style}}
      isActive={isActive === undefined ? true : isActive}
    >
      { children }
    </WrappedButton>
  );
}

export default Button;