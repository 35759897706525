import { models } from "../db/models";
import {IDesignParams, IModel} from "../db/types";
import { EConfigDesigns, EConfigGarment } from "../enums";
import {IGarmentsQuantityResult, IIndividualConfig} from "../store/types";
import { sizes } from "../constants";

export const getModels = (sportId: string, garment: EConfigGarment, collectionId: string | null): IModel[] => {
  return models.filter(item =>
      item.sportIds.includes(sportId) &&
      item.garment === garment &&
      (collectionId ? item.collectionId === collectionId : true)
    );
}

export const getModelsCollectionId = (modelId: string): string | null => {
  return models.find(item => item.id === modelId)?.collectionId || null;
}

export const getDesignsList = (modelId: string): IDesignParams[] => {
  return models.find(item => item.id === modelId)?.designsList || [];
}

export const getModelPath = (modelId: string): string => {
  return models.find(item => item.id === modelId)?.modelPath || '';
}

export const isAnyDesignSelected = (designsList: IDesignParams[], design: EConfigDesigns): boolean => {
  if (!design) {
    return false;
  }
  return designsList.some(item => item.name === design);
}

export const getTotalColors = (modelId: string, design: EConfigDesigns): number => {
  return getDesignsList(modelId).find(item => {
    if (item.name) {
      return item.name === design;
    }
    return item;
  })?.totalColors || 0;
}

export const getKitGarmentsQuantity = (teamConfig: IIndividualConfig[]): IGarmentsQuantityResult => {
  // used in Order table header Team tab, and to calculate a discount price
  let jerseyQuantity: number = 0;
  let shortsQuantity: number = 0;
  let socksQuantity: number = 0;

  teamConfig.forEach(item => {
    jerseyQuantity += item.garments[0].isInclude ? item.garments[0].quantityGarments * item.quantityKits : 0;
    shortsQuantity += item.garments[1].isInclude ? item.garments[1].quantityGarments * item.quantityKits : 0;
    socksQuantity += item.garments[2].isInclude ? item.garments[2].quantityGarments * item.quantityKits : 0;
  });

  return {
    jerseyQuantity,
    shortsQuantity,
    socksQuantity
  }
}

export const getSizeValue = (name: string): string => {
  for (let type in sizes) {
    const found = sizes[type as keyof typeof sizes].find(item => item.name === name);
    if (found) {
      return found.value;
    }
  }
  return '';
}
