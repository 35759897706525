import styled from 'styled-components';

interface IProps {
  wrappedHeight?: number;
}

export const WrappedCarousel = styled.div<IProps>`
  max-width: 590px;
  position: relative;

  .arrow {
    position: absolute;
    top: 0;
    z-index: 10;
    cursor: pointer;
    outline: none;
    border: none;
    height: 100%;
    width: 27px;
    background: none;
  }

  .arrow--right {
    right: -45px;
  }

  .arrow--left {
    left: -45px;
  }

  .swiper-slide {
    height: ${props => props.wrappedHeight ? props.wrappedHeight + 'px' : '100%'};
    display: ${props => props.wrappedHeight ? 'flex' : 'block'};
    align-items: center;
  }
`
