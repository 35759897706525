import React, { useRef, useCallback } from 'react';
import { WrappedCarousel } from './carouselStyle';
import arrowLeft from '../../../assets/UI/chevron_left.svg';
import arrowRight from '../../../assets/UI/chevron_right.svg';
import { Navigation, Swiper as SwiperClass } from 'swiper';
import { Swiper } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

interface IProps {
  spaceBetween?: number;
  wrappedHeight?: number;
  slidesPerView: number;
  totalItems: number;
}

const Carousel: React.FC<IProps> = ({
  children,
  spaceBetween = 16,
  slidesPerView,
  wrappedHeight,
  totalItems,
}) => {
  const swiperRef = useRef<SwiperClass>();
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  const arrowBackHandler = useCallback(() => {swiperRef.current?.slidePrev()}, [swiperRef]);
  const arrowNextHandler = useCallback(() => {swiperRef.current?.slideNext()}, [swiperRef]);

  return (
    <WrappedCarousel wrappedHeight={wrappedHeight}>
      <Swiper
        modules={[Navigation]}
        spaceBetween={spaceBetween}
        slidesPerView={slidesPerView}
        onSwiper={(swiper: SwiperClass) => {
          swiperRef.current = swiper;
        }}
      >
        {/* !!! each element of the list have to be wrapped in SwiperSlide component from swiper/react */}
        {children}
      </Swiper>

      {
        totalItems > 8 ?
          <>
            <button
              className='arrow arrow--left'
              ref={prevRef}
              onClick={arrowBackHandler}
            >
              <img src={arrowLeft} alt='left'/>
            </button>

            <button
              className='arrow arrow--right'
              ref={nextRef}
              onClick={arrowNextHandler}
            >
              <img src={arrowRight} alt='right'/>
            </button>
          </> : null
      }
    </WrappedCarousel>
  );
}

export default Carousel;