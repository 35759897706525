import styled from "styled-components";
import {TEXT_COLOR} from "../../../constants";

export const WrapperPreviewGarments = styled.div`
  opacity: 0;
  transform: translateX(-40px);
  width: 790px;
  height: 629px;
  background: #FFFFFF;
  border-radius: 16px;
  position: relative;

  .btn_close {
    position: absolute;
    top: 24px;
    right: 24px;
    cursor: pointer;
  }

  @media all and (max-width: 768px) {
    width: 95%;
    max-height: 453px;
  }
`;

export const PreviewBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;
  margin: 0 auto;
  height: 100%;

  .preview_canvas {
    width: 440px;
    height: 440px;
  }
  
  p {
    font-style: italic;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: ${TEXT_COLOR};
  }

  @media all and (max-width: 768px) {
    .preview_canvas {
      width: 296px;
      height: 296px;
    }
  }
`;

export const PreviewArrowBox = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;

  @media all and (max-width: 768px) {
    margin-top: 16px;
  }
`;

interface DisabledProps {
  disabled?: boolean;
  hide?: boolean;
  type: 'left' | 'right';
}

export const PreviewArrowContent = styled.div<DisabledProps>`
  display: flex;
  width: 100%;
  justify-content: ${props => props.type === 'left' ? 'flex-start' : 'flex-end'};
  gap: 14px;
  margin: ${props => props.type === 'left' ? '0 0 0 18px' : '0 18px 0 0'};
  cursor: ${props => props.disabled || props.hide ? 'default' : 'pointer'};
  padding: 10px 0;
  opacity: ${props => props.disabled ? 0.2 : 1};
  transition: .15s;

  @media all and (max-width: 768px) {
    margin: ${props => props.type === 'left' ? '0 0 0 20%' : '0 20% 0 0'};
  }
`;

export const TextPreviewTitle = styled.p`
  font-style: italic;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: ${TEXT_COLOR};
`;
