import React from 'react';
import imgRemove from '../../../assets/UI/close.svg';
import { WrappedRemoveFile } from './buttonStyle';

interface IProps {
  handleClick: () => void;
}

const RemoveFile: React.FC<IProps> = ({handleClick}) => {

  return (
    <WrappedRemoveFile
      onClick={handleClick}
    >
      <img 
        src={imgRemove} 
        alt='delete'
      />
    </WrappedRemoveFile>
  );
}

export default RemoveFile;