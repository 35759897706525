import React, {useEffect, useRef, useState} from 'react';
import gsap from "gsap";
import {AnimPopup} from "../../../constants";
import {EConfigGarment} from "../../../enums";
import imgClose from "../../../assets/UI/close.svg";
import {observer} from "mobx-react-lite";
import store from '../../../store/rootStore';
import {PreviewArrowBox, PreviewBlock, WrapperPreviewGarments} from './style';
import PreviewArrow from "./PreviewArrow";
import {IScrResult} from "../../../store/types";
import {useTranslation} from "react-i18next";

interface IProps {
  initialGarment: EConfigGarment;
  modelImagesPath: IScrResult;
}

const PreviewGarments = observer(({ initialGarment, modelImagesPath }: IProps) => {
  const { t } = useTranslation();

  const [currentGarmentPreview, setCurrentGarmentPreview] = useState<string>('');
  const [garmentsList, setGarmentsList] = useState<EConfigGarment[]>([]);
  const [index, setIndex] = useState<number>(0);

  const myRef = useRef(null);
  const { setOpenDialog, includeSocks, includeJersey, includeShorts, hideSocks } = store.app;

  useEffect(() => {
    gsap.to(myRef.current, AnimPopup);
  }, []);

  useEffect(() => {
    const list = Object.values(EConfigGarment).filter((i) => {
      if (i === EConfigGarment.JERSEY && includeJersey) {
        return true;
      }
      if (i === EConfigGarment.SHORTS && includeShorts) {
        return true;
      }
      if (i === EConfigGarment.SOCKS && includeSocks && !hideSocks) {
        return true;
      }
      return false;
    }) as EConfigGarment[];

    setGarmentsList(list);
    const myIndex = list.findIndex((i) => i === initialGarment);
    setIndex(myIndex);
  }, [
    includeSocks,
    includeJersey,
    includeShorts,
    hideSocks,
    initialGarment,
  ]);

  useEffect(() => {
    if (garmentsList.length > 0) {
      if (garmentsList[index] === EConfigGarment.JERSEY && modelImagesPath.jersey) {
        setCurrentGarmentPreview(modelImagesPath.jersey);
      }
      if (garmentsList[index] === EConfigGarment.SHORTS && modelImagesPath.shorts) {
        setCurrentGarmentPreview(modelImagesPath.shorts);
      }
      if (garmentsList[index] === EConfigGarment.SOCKS && modelImagesPath.socks) {
        setCurrentGarmentPreview(modelImagesPath.socks);
      }
    }
  }, [
    garmentsList,
    index,
    modelImagesPath.jersey,
    modelImagesPath.shorts,
    modelImagesPath.socks,
  ]);

  const handleClose = () => {
    setOpenDialog(false);
  }

  const handleLeft = () => {
    if (index === 0) {
      return;
    }
    setIndex((prev) => prev - 1);
  }

  const handleRight = () => {
    if (index === garmentsList.length - 1) {
      return;
    }
    setIndex((prev) => prev + 1);
  }

  const getTranslation = (garment: EConfigGarment, isShort?: boolean): string => {
    const result = t(`garments.${garment}`);
    if (isShort && result === 'Chaussettes') {
      return 'Chaus.';
    }
    return result;
  };

  return (
    <WrapperPreviewGarments
      ref={myRef}
      onClick={(e) => e.stopPropagation()}
    >
      <img
        src={imgClose}
        alt='X'
        onClick={handleClose}
        className='btn_close'
      />
      <PreviewBlock>
        <img className='preview_canvas' src={currentGarmentPreview} alt=""/>
        <p>{getTranslation(garmentsList[index])}</p>
      </PreviewBlock>
      <PreviewArrowBox>
        <PreviewArrow
          type='left'
          hide={index <= 0}
          title={getTranslation(garmentsList[index - 1] ? garmentsList[index - 1] : garmentsList[index], true)}
          onClick={handleLeft}
        />
        <PreviewArrow
          type='right'
          hide={index >= garmentsList.length - 1}
          title={getTranslation(garmentsList[index + 1] ? garmentsList[index + 1] : garmentsList[0], true)}
          onClick={handleRight}
        />
      </PreviewArrowBox>
    </WrapperPreviewGarments>
  );
});

export default PreviewGarments;
