import React, { useCallback, useEffect, useRef } from 'react';
import { AnimPopup, BTN_TEXT_COLOR_PRESSED } from '../../constants';
import Button from '../UI/Buttons/Button';
import { WrappedDialogConfirm } from './popupStyle';
import confirmImg from '../../assets/UI/confirm.svg';
import declineImg from '../../assets/UI/popup_close.svg';
import gsap from 'gsap';

interface IProps {
  mainText: string;
  confirmText?: string;
  declineText?: string;
  handleConfirm: () => void;
  handleDecline: () => void;
  confirmWidth?: string;
  declineWidth?: string;
}

const DialogConfirm: React.FC<IProps> = ({
  mainText, 
  confirmText, 
  declineText, 
  handleConfirm, 
  handleDecline,
  confirmWidth,
  declineWidth
}) => {
  const myRef = useRef(null);
  const hotKeys = useCallback((e) => {
    //console.log(e.key)
    if (e.key === 'Enter') {
      handleConfirm();
    }
    if (e.key === 'Escape') {
      handleDecline();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    window.addEventListener('keydown', hotKeys);
    return () => {
      window.removeEventListener('keydown', hotKeys);
    }
  }, [hotKeys])

  useEffect(() => {
    gsap.to(myRef.current, AnimPopup)
  }, [])

  return (
    <WrappedDialogConfirm 
      ref={myRef} 
      onClick={(e) => e.stopPropagation()}
      declineWidth={declineWidth}
      confirmWidth={confirmWidth}
    >
      <p className='text'>{mainText}</p>
      <div className='buttons'>
        <Button
          background={BTN_TEXT_COLOR_PRESSED}
          color={'white'}
          handleClick={handleConfirm}
        >
          <img src={confirmImg} alt='confirm'/>
          {confirmText}
        </Button>
        <Button
          handleClick={handleDecline}
        >
          <img src={declineImg} alt='decline'/>
          {declineText}
        </Button>
      </div>
    </WrappedDialogConfirm>
  );
}

export default DialogConfirm;