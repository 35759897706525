import React, {CSSProperties} from 'react';
import {EButtonOpenerTypes} from '../../../enums';
import {WrappedButtonOpener} from './buttonOpenerStyle';
import arrowUp from '../../../assets/UI/chevron_up.svg';
import arrowDown from '../../../assets/UI/chevron_down.svg';
import burger from '../../../assets/UI/burger.svg';
import close from '../../../assets/UI/close.svg';
import calculator from '../../../assets/UI/calculator.svg';
import {useTranslation} from 'react-i18next';

interface IProps {
  handleClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  type: EButtonOpenerTypes;
  isMenu?: boolean;
  style?: CSSProperties;
}

const ButtonOpener: React.FC<IProps> = ({ handleClick, type, isMenu, style }) => {
  const { t } = useTranslation();

  const renderType = () => {
    switch (type) {
      case EButtonOpenerTypes.ARROW_DOWN: {
        return <img src={arrowDown} alt='ar' className='arrow__img'/>
      }
      case EButtonOpenerTypes.ARROW_UP: {
        return <img src={arrowUp} alt='ar' className='arrow__img'/>
      }
      case EButtonOpenerTypes.CLOSE: {
        return <>
          <img src={close} alt='X' className='burger__img'/>
          <p>{t('ui.close')}</p>
        </>
      }
      case EButtonOpenerTypes.CALC: {
        return <>
          <img src={calculator} alt='calc' className='burger__img'/>
          <p className='title'>{t('calculator.calculator')}</p>
        </>
      }
      case EButtonOpenerTypes.MENU: {
        return <>
          <img src={burger} alt='menu' className='burger__img'/>
          <p className='title'>{t('ui.menu')}</p>
        </>
      }
    }
  }

  return (
    <WrappedButtonOpener
      onClick={handleClick}
      isMenu={isMenu}
      style={style}
    >
      {renderType()}
    </WrappedButtonOpener>
  );
}

export default ButtonOpener;
