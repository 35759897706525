import styled from 'styled-components';

interface IMenuPanelProps {
  isOrderFormation: boolean;
}

export const WrappedMenuPanel = styled.div<IMenuPanelProps>`
  position: fixed;
  bottom: 0;
  background: ${props => props.isOrderFormation ? 'white' : 'transparent'};
  width: 100%;
  z-index: 1000;
`

interface ILayoutMenuPanelProps {
  isOpen: boolean;
  isStepSport: boolean;
  isStepOrder: boolean;
  isColorPicker: boolean;
}

const getHeight = (props: ILayoutMenuPanelProps): string => {
  if (props.isStepSport) {
    return '64px';
  }
  if (props.isOpen) {
    if (props.isStepOrder) {
      return '178px';
    } else {
      return '232px';
    }
  }
  return '50px';
}

export const WrappedLayoutMenuPanel = styled.div<ILayoutMenuPanelProps>`
  height: ${props => props.isOpen ? '300px' : '88px'};
  transition: .2s ease-out;
  width: 100%;
  padding: 16px 16px 0 16px;
  display: flex;
  flex-direction: column;

  .animation {
    opacity: 0;
    width: 100%;
    display: flex;
    justify-content: center;

    &--content {
      overflow-x: ${props => props.isColorPicker ? 'scroll' : 'none'};
    }
  }

  .head {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .opener {
      margin-right: 45px;
    }

    .desktop {
      display: block;
    }
    
    .menu {
      display: flex;
      gap: 16px;
    }
  }

  .paginator {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    height: 88px;
    width: 240px;
    display: flex;
    align-items: center;
  }

  .heading__mobile {
    display: none;
  }

  @media all and (max-width: 1920px) {
    height: ${props => props.isOpen ? '232px' : '80px'};

    .head {
      .opener {
        margin-right: 40px;
      }
    }

    .paginator {
      height: 80px;
    }
  }

  @media all and (max-width: 768px) {
    height: ${props => getHeight(props)};
    padding: 8px 16px 0 16px;
    position: relative;

    .animation {
      justify-content: ${props => props.isColorPicker ? 'flex-start' : 'center'};
      display: block;
    }

    .head {
      justify-content: flex-start;
      gap: 16px;

      .opener {
        margin-right: 0;
        display: ${props => props.isStepSport ? 'none' : 'block'};
      }

      .buttons {
        overflow-x: scroll;
      }

      .menu {
        display: none;
      }

      .desktop {
        display: none;
      }
    }

    .paginator {
      height: 50px;
    }

    .heading__mobile {
      display: block;
      width: 100%;

      .heading__back {
        height: 32px;
        display: flex;
        justify-content: flex-end;
      }
    }

    .fade {
        display: block;
        width: 32px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 40px;
        background: linear-gradient(90deg, rgba(255,255,255, 0), rgb(252,215,224) 50%);
        pointer-events: none;
      }
  }
`

interface IPaginatorProps {
  isPreviousVisible: boolean;
  isNextVisible: boolean;
  isActive: boolean;
}

export const WrappedPaginator = styled.div<IPaginatorProps>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  opacity: ${props => props.isActive ? '1' : '.5'};

  .btn_wrap {
    width: 36px;
    height: 24px;
    cursor: ${props => props.isActive ? 'pointer' : 'default'};
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .previous {
    visibility: ${props => props.isPreviousVisible ? 'visible' : 'hidden'};
  }

  .next {
    visibility: ${props => props.isNextVisible ? 'visible' : 'hidden'};
  }

  p {
    font-style: italic;
    font-weight: 700;
  }

  @media all and (max-width: 768px) {
    margin-bottom: 9px;
  }
`

interface ISliderProps {
  isStepSport: boolean;
}

export const WrappedMenuPanelSlider = styled.div<ISliderProps>`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 16px 0 0 0;

  @media all and (max-width: 1920px) {
    padding: 0;
  }

  @media all and (max-width: 768px) {
    justify-content: flex-start;
    overflow-x: ${props => props.isStepSport ? 'none' : 'scroll'};
    padding: 16px 0 0 0;
  }
`
