import styled from 'styled-components';

export const WrappedStepDesignPanel = styled.div`
  .mobile {
    display: none;
  }

  .desktop {
    display: block;
  }

  @media all and (max-width: 768px) {
    .mobile {
      width: 100%;
      display: flex;
      gap: 16px;
    }

    .desktop {
      display: none;
    }
  }
`
